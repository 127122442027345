import initialState, {defaultGlobalSearch} from "./initialState";
import {bottomCenter} from "../components/common/customToastr";
import Utility from "../api/utilLanguage";
import convertService from "../api/convertService";
import CaseUtil from "../components/common/caseUtil";
import OrderUtil, {TypeOfAttendedFuneralService, YesNoOptionsOnly} from "../components/common/orderUtil";
import AppUtil, {ArrayUtil, NOT_FOUND} from "../components/common/appUtil";
import {cloneDeep} from "lodash";
import store from "../store"
import {
    getFenixUserId,
    removeFenixUser,
    setApiToken,
    setCaseStages,
    setExpiryDate,
    setFenixUser,
    setIdaTheme,
    setLangStatus,
    setOrderStages,
    setPageVisibilityForCardId,
    setProfileImageUrl,
    setRoles,
    setTenantBasedOrderTypes,
    setTenantCurrency,
    setTenantId,
    setTenantLanguage,
    setTenantTimeZone
} from "../components/common/localStorageUtil";
import DocumentUtil from "../components/case/documents/documentUtil/documentUtil";
import GuestUtil from "../components/case/caseGuestsSubComponents/guestUtil";
import RelativeUtil from "../components/case/relative/relativeUtil";
import {
    ADD_MORE_EVENT_CATEGORY_FIELD,
    ADD_NEW_EVENT_CATEGORY,
    ADD_PRODUCTS_TO_EXISTING_EVENT_PRODUCT_CATEGORY,
    CHANGE_ATTRIBUTE_CONFIRM_STATUS,
    CHANGE_BILLING_STATUS_FOR_CASE_ORDER,
    CHANGE_BILLING_STATUS_FOR_ORDER_INFO,
    CHANGE_CEREMONY_INSTRUCTION_FOR_ORDER_INFO,
    CHANGE_CUSTOM_PRODUCT_NAME,
    CHANGE_EVENT_CATEGORY_CONFIRM_STATUS,
    CHANGE_PRODUCT_ITEM_GREETING_TEXT,
    CHANGE_STAGE_FOR_ORDER_INFO,
    FETCHED_ALL_CATEGORY_FOR_ANONYMOUS,
    GET_PRODUCT_TOTAL_PRICE,
    GET_REMAINING_PRODUCT_CATEGORIES_FOR_AN_EVENT,
    ON_ORDER_EVENT_CATEGORY_CHILD_PRODUCT_QUANTITY_AND_PRICE_CHANGE,
    ON_ORDER_EVENT_CATEGORY_DISBURSEMENT_PAYMENT_SELECT,
    ON_ORDER_EVENT_CATEGORY_PRODUCT_QUANTITY_AND_PRICE_CHANGE,
    ON_ORDER_EVENT_CATEGORY_PRODUCT_SELECT,
    REMOVE_EVENT,
    REMOVE_FULL_ORDER_EVENT_CATEGORY,
    REMOVE_ORDER,
    REMOVE_SINGLE_CHILD_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY,
    REMOVE_SINGLE_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY,
    SET_CASE_ID_FOR_ORDER_TAB_ONLY,
    SET_CHILD_PRODUCT_CONFIRM_STATUS,
    SET_CUSTOM_TIME_PERIOD_FOR_ORDER_INVOICE,
    SET_ORDER_BILLING_STATUS,
    SET_TIME_PERIOD_FOR_ORDER_INVOICE,
    UPDATE_EVENT_ATTRIBUTE_DATA,
    UPDATE_OPTIONAL_EVENT
} from "../components/common/orderActionTypes";
import SearchUtil from "../components/common/searchPanel/searchUtil";
import ErrorUtil from "../components/common/errorUtil";
import DateUtilExt, {TimestampFormat} from "../components/common/dateUtil/dateUtilExt";
import OrderFieldRules from "../components/case/caseTenantBasedOrder/OrderFieldRules";
import {NoteUtil} from "../components/common/sidePanel/util/noteUtil";
import WebSocketUtil from "../components/common/webSocket/webSocketUtil";
import {
    CLEAR_WS_CURRENT_EDITOR_AND_TAKEOVER_BANNER,
    CLEAR_WS_OUTDATED_BANNER,
    CLEAR_WS_RELOAD_TO_SEE_LATEST_BANNER,
    CLEAR_WS_SEMITRANSPARENT_CARD,
    UPDATE_ACTIVE_CALL_LIST,
    UPDATE_CASE_CARDS_ACTIVE_CALL,
    UPDATED_ITEM
} from "../actions/wsAction";
import ProductUtil, {
    createProductName,
    initialUpdateToEmptyProductCategory,
    ProductTypes,
    setProductNames,
    updateProductsCategoryWithLocalParamsOnSource,
    updateRequestProductsCategoryWithLocalParams
} from "../components/common/productUtil";
import {
    CLEAR_LOCK_CASE_ACCESS_USER_LIST,
    HIDE_CASE_TAKE_OVER_POPUP,
    SHOW_CASE_TAKE_OVER_POPUP,
    UPDATE_LOCK_CASE_EDITOR
} from "../components/case/lockCase/redux/types";
import LockCaseUtil, {UserAccessType} from "../components/case/lockCase/util/lockCaseUtil";
import {
    ADD_CASE_COUNTY_TO_STATE,
    ADD_COLLABORATOR_USER_TO_STATE,
    CASE_DELETED_SUCCESSFULLY,
    CHANGE_DECEASED_PROPERTY,
    CLEAR_CASE_RELATIVES_WITH_EMAIL_ID,
    CLEAR_RELATED_CASES,
    CLEAR_UK_PAYMENT_DETAILS,
    CREATE_NOTES_CONTENT,
    CREATED_CASE_INFO,
    DISABLE_CASE_SAVE_BUTTON,
    FETCHED_ORDER_JSON,
    FETCHED_RELATED_CASES,
    FILTER_APPLIED_TO_COLUMN,
    MERGED_CASE_DATA,
    ON_ERROR_UNDER_CASE,
    REMOVE_FILTER_APPLIED,
    REVOKE_ACCESS_LEVEL,
    SET_COUNTERS,
    SET_MANUALLY_SELECTED_CHILD_COMPONENT,
    SET_REL_COMPANY_NAME,
    SET_TRANSPORTER_PICKUP,
    UPDATE_CASE_GENERIC_PROPERTY,
    UPDATE_CASE_INFO_INTO_EXISTING_CASE,
    UPDATE_CASE_ORDER_PROPERTY,
    UPDATE_CASE_VERSION,
    UPDATE_DEADLINE_DETAILS,
    UPDATE_DEADLINE_EDIT_BUTTON,
    UPDATE_DEADLINE_RESET_BUTTON,
    UPDATE_DECEASED_DATE_OF_BODY_PICKUP,
    UPDATE_DECEASED_DATE_OF_PASSING,
    UPDATE_DECEASED_PROPERTY,
    UPDATE_VALIDATION_ERRORS_INFO,
    UPDATE_WORK_LOG_PDF_DETAILS,
    UPDATED_CASE_STAGE
} from "../actions/caseAction";
import {
    CHANGE_USER_DETAILS_FROM_SETTINGS,
    CHANGE_USER_INFO,
    EDIT_PRODUCT_CATEGORY,
    GET_USER_DATA_BY_ID,
    NOTIFICATION_CASE_ID,
    SHOULD_SYNCHRONIZE_GLOBAL_VARIABLES,
    SHOULD_SYNCHRONIZE_LOCAL_STORAGE,
    UPDATE_HAS_USER_CHANGED,
    UPDATE_VENDORS
} from "../actions/dashboardAction";
import {clearMemorial} from "../components/case/memorialPage/redux/memorialAction";
import {
    clearColumnPageData,
    clearColumnRuleMatrix,
    clearGlobalColumnLoadingTracker
} from "../components/common/masterDetailView/column/redux/columnAction";
import {
    ADDED_CONTACT_SUCCESSFULLY,
    DELETED_CONTACT_SUCCESSFULLY,
    UPDATED_CONTACT_SUCCESSFULLY
} from "../actions/contactAction";
import {DELETED_SUCCESSFULLY} from "../actions/productCategoryAction";
import {
    ORDER_DELETED_SUCCESSFULLY,
    SET_COLLABORATOR,
    UPDATE_ORDER_DETAILS,
    UPDATE_ORDER_DETAILS_ON_SOURCE
} from "../actions/orderAction";
import {ADDED_LOCATION, DELETED_LOCATION_SUCCESSFULLY, UPDATED_LOCATION_SUCCESSFULLY} from "../actions/locationAction";
import {CALL_DELETED_SUCCESSFULLY, UPDATED_CALL_DATA} from "../actions/callAction";
import {CRUDOperation} from "../components/common/masterDetailView/column/redux/types";
import {CallUtil} from "../components/common/callColumnUtil";
import {
    ASK_BEFORE_UPGRADE,
    CLEAR_CASE_RELATED_DATA,
    CLEAR_INITIAL_VALUES,
    CLEAR_USELESS_VALUES,
    ON_HEADER_MENU_ITEM_CLICKED,
    SHOW_OR_HIDE_REVOKE_ACCESS_LEVEL_POPUP,
    SHOW_OR_HIDE_UNSAVED_POPUP_LAZILY,
    SHOW_UNSAVED_POPUP,
    SUB_HEADER_ITEM_CLICKED_DASHBOARD,
    UPDATE_COMMON_DATA_DETAILS,
    UPDATE_GENERIC_POPUP
} from "../actions/uiAction";
import {SET_CARD_SELECTED_FROM_GLOBAL_SEARCH} from "../actions/searchAction";
import {
    ADDED_PRODUCT,
    CHANGE_DATA_IN_UI_FOR_VARIANTS,
    CHANGE_ENABLE_DISBURSEMENT_VALUE_FOR_PRODUCT,
    DELETED_PRODUCT_SUCCESSFULLY,
    UPDATE_PRODUCT_WITH_VARIANT_DATA,
    UPDATED_PRODUCT_SUCCESSFULLY
} from "../actions/productAction";
import {
    CLEAR_BASE64_GENERATED_CASE_DOCUMENT,
    GENERIC_DOCUMENT_DELETED_SUCCESSFULLY,
    UPDATE_BASE64_GENERATED_DOCUMENT,
    UPDATE_BASE64_GENERATED_DOCUMENT_NEW_VERSION,
    UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER,
    UPDATE_CASE_DOCUMENTS,
    UPDATE_GENERIC_DOCUMENT_TYPES,
    UPDATE_GENERIC_DOCUMENTS_ON_SAVE,
    UPDATE_UPLOADED_CASE_DOCUMENT
} from "../actions/documentAction";
import {clearProbate} from "../components/case/probates/redux/probateAction";
import {UPDATE_CASE_CONNECTION_INFO, UPDATE_CASE_PROBATE_PROPERTY} from "../components/case/probates/redux/types";
import DeadlineDateElement from "../components/case/caseStatus/deadline/deadlineDateElement";
import {EventAttribute} from "../components/case/caseTenantBasedOrder/ukTenantOrderAttributes";
import {commissionCoefficient} from "../components/common/ValidationAndVisibilityRule";
import {PAUSE_INVOICE_TWO_DAYS} from "../components/case/caseOrderSubComponents/pauseInvoiceContainer";

export default function applicationReducers(state = initialState, action) {

    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case 'HIDE_UI': {
            newState.hideVisibility = true;
            return newState;
        }
        case 'SHOW_UI': {
            newState.hideVisibility = false;
            return newState;
        }
        case ON_HEADER_MENU_ITEM_CLICKED: {
            newState.displayNothing = false;
            clearColumnRuleMatrix()

            const {data} = action;
            const newUrl = data.split("/");
            newState.headerMenuItemClicked = "/" + newUrl[1];
            console.log("ON_HEADER_MENU_ITEM_CLICKED", newState.headerMenuItemClicked);
            return newState;
        }
        case 'SET_UNREAD_NOTIFICATION_COUNT': {
            newState.unreadNotificationCount = action.data;
            return newState;
        }
        case SHOW_OR_HIDE_UNSAVED_POPUP_LAZILY: {
            newState.hasCardInfoChanged = action.data;
            return newState;
        }
        case SHOW_UNSAVED_POPUP: {
            newState.showUnsavedPopup = true;
            newState.unsavedPopupInfo = action.data;
            return newState;
        }
        case ASK_BEFORE_UPGRADE: {
            newState.startUpgradePopup = true;
            return newState;
        }
        case 'CLOSE_UPGRADE_POPUP': {
            newState.startUpgradePopup = false;
            return newState;
        }
        case 'HAS_UNSAVED_DATA': {
            newState.hasUnsavedData = action.data;
            return newState;
        }
        case 'ESC_CLICKED': {
            newState.escClicked = action.data;
            newState.selectedOrderData = {};
            return newState;
        }
        case 'CLEAR_ROUTE_CONDITION': {
            newState.showUnsavedPopup = false;
            newState.startUpgradePopup = false;
            newState.selectedCaseData = {};
            newState.tempNote = '';
            newState.hasCardInfoChanged = false;
            return newState;
        }
        case 'CLOSE_POPUP_WITH_SAME_ROUTE': {
            newState.showUnsavedPopup = false;
            newState.escClicked = false;
            newState.startUpgradePopup = false;
            newState.unsavedPopupInfo = {};
            return newState;
        }
        case 'SET_PAGE_COLUMN_CONFIGURATION': {
            console.log("newState.showEscapeKey = ", newState.showEscapeKey);
            newState.excutedRefreshToken = undefined
            if (newState.showEscapeKey === true) {
                let copyOfConfig = cloneDeep(convertService.getConvertPageColumnsConfiguration());
                console.log("copyOfConfig = ", copyOfConfig);
                let newConf = copyOfConfig;
                let newArr = [];
                let arrayOfColumns = newConf.columns;
                newArr.push(arrayOfColumns[0]);
                newConf.columns = newArr;
                newState.currentColumnView = newConf;
            } else {
                newState.currentColumnView = action.data;
            }
            return newState;
        }
        case 'SHOW_SEARCH_PANEL': {
            newState.showSearchPanel = newState.showSearchPanel === false;
            newState.globalSearchPanel.show = newState.globalSearchPanel.show === false;
            newState.highLightIconSearch = action.data;
            return newState;
        }
        case SET_MANUALLY_SELECTED_CHILD_COMPONENT : {
            newState.manuallySelectedChildComponent = action.data
            return newState;
        }
        case 'SHOW_NOTIFICATION_PANEL': {
            newState.showNotificationPanel = !newState.showNotificationPanel;
            newState.highLightIconNotification = action.data;
            return newState;
        }
        case 'SUB_HEADER_ITEM_CLICKED_MYACCOUNT': {
            newState.subheaderMenuInMyAccount = action.data;
            if ((newState.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.public)
                || newState.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.publicTips
                || newState.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.publicQuestions) {
                newState.headerMenuItemClicked = AppUtil.linkPaths.myAccount.pathToRoute;
            } else if (newState.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.security) {
                newState.headerMenuItemClicked = AppUtil.linkPaths.myAccount.pathToRoute;
            } else if (newState.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.notices) {
                newState.headerMenuItemClicked = AppUtil.linkPaths.myAccount.pathToRoute;
            } else if (newState.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.settings) {
                newState.headerMenuItemClicked = AppUtil.linkPaths.myAccount.pathToRoute;
            } else {
                newState.headerMenuItemClicked = action.data;
            }
            return newState;
        }
        case SUB_HEADER_ITEM_CLICKED_DASHBOARD: {
            const {payload} = action;
            newState.subheaderMenuInMyAccount = payload;
            switch (newState.subheaderMenuInMyAccount) {
                case AppUtil.linkPaths.dashboard.pathToRoute:
                case AppUtil.linkPaths.dashboard.worklogs:
                    newState.headerMenuItemClicked = AppUtil.linkPaths.dashboard.pathToRoute;
                    break;
                default:
                    newState.headerMenuItemClicked = payload;
                    break;

            }
            return newState;
        }
        case 'SUB_HEADER_ITEM_CLICKED_SETTING': {
            let urlValue = action.data.split('/');
            let headerValue = urlValue[2];
            if (headerValue === 'users') {
                newState.subheaderMenuInSetting = AppUtil.linkPaths.settings.users;
            } else if (headerValue === 'product-categories') {
                newState.subheaderMenuInSetting = AppUtil.linkPaths.settings.productCategories;
                newState.filteredProductData = [];
            } else if (headerValue === 'export') {
                newState.subheaderMenuInSetting = AppUtil.linkPaths.settings.export;
            } else if (headerValue === 'token') {
                newState.subheaderMenuInSetting = AppUtil.linkPaths.settings.token;
            } else if (headerValue === 'userForm') {
                newState.subheaderMenuInSetting = AppUtil.linkPaths.settings.userDetailOrAdd;
            } else if (headerValue === 'userDetail') {
                newState.subheaderMenuInSetting = AppUtil.linkPaths.settings.userDetailOrAdd;
            } else if (urlValue[1] === 'settings') {
                newState.subheaderMenuInSetting = AppUtil.linkPaths.settings.pathToRoute;
            }
            newState.headerMenuItemClicked = AppUtil.linkPaths.settings.pathToRoute;
            return newState;
        }
        case 'SHOW_POPUP': {
            newState.open = true;
            return newState;
        }
        case 'SHOW_PACKAGE_FUNERAL_POPUP': {
            newState.openFuneralPackagePopup = true;
            return newState;
        }
        case 'SHOW_POPUP_ON_DRAG': {
            newState.openOnDrag = true;
            return newState;
        }
        case UPDATE_GENERIC_POPUP: {
            const {key, payload} = action;
            newState[key] = payload;
            return newState;
        }
        case 'SHOW_POPUP_ON_ORDER_DRAG': {
            newState.openOnOrderDrag = true;
            return newState;
        }
        case 'SHOW_POPUP_ON_SEND': {
            if (action.source !== undefined && action.source === 'case') {
                newState.openEmailPopupOnSend = true;
            } else {
                newState.openOnSend = true;
            }
            if (AppUtil.isAvailable(action.data)) {
                newState.orderIdForSendingmail = action.data.id;
                newState.orderDataForSendingMail = action.data;
            } else {
                newState.orderIdForSendingmail = null;
                newState.orderDataForSendingMail = null;
            }
            return newState;
        }
        case 'SHOW_POPUP_FOR_GUEST_EMAIL_ACTION': {
            newState.openForGuestEmailAction = true;
            return newState;
        }
        case 'SHOW_POPUP_FOR_GUEST_ADDING_RESTRICTION': {
            newState.openForGuestAddingRestriction = true;
            newState.guestAdditionRestrictionPopupTitle = action.headerTitle;
            return newState;
        }
        case 'USER_RESTRICTION_FOR_GUEST_ADDING_RESTRICTION_POPUP': {
            newState.selectedCaseData.isGuestAddingRestrictionPopupUserRestricted = true;
            return newState;
        }
        case 'SHOW_POPUP_FOR_DELETING_GUEST': {
            newState.guestPopup.index = action.index;
            newState.guestPopup.showOnDelete = true;
            return newState;
        }
        case SHOW_CASE_TAKE_OVER_POPUP:
            newState.openOnTakeOver = action.payload;
            return newState;
        case HIDE_CASE_TAKE_OVER_POPUP:
            newState.openOnTakeOver = action.payload;
            return newState;
        case 'HIDE_POPUP_FOR_GUEST_EMAIL_ACTION': {
            newState.openForGuestEmailAction = false;
            return newState;
        }
        case 'HIDE_POPUP_FOR_GUEST_ADDING_RESTRICTION': {
            newState.openForGuestAddingRestriction = false;
            return newState;
        }
        case 'HIDE_POPUP_FOR_DELETING_GUEST': {
            newState.guestPopup.index = -1;
            newState.guestPopup.showOnDelete = false;
            return newState;
        }
        case 'HIDE_POPUP_SECTION': {
            newState.open = false;
            newState.openEmailPopupOnSend = false;
            newState.openForGuestEmailAction = false;
            newState.openForGuestAddingRestriction = false;
            newState.guestPopup.index = -1;
            newState.guestPopup.showOnDelete = false;
            newState.deleteOrderPopup = false;
            newState.openPopupForRelativeOnly = false;
            newState.openFuneralPopup = false;
            newState.openFuneralPackagePopup = false;
            newState.openFuneralWithoutPopup = false;
            newState.openLegalOrderPopup = false;
            newState.openTombstoneOrderPopup = false;
            newState.openTransportOrderPopup = false;
            newState.openCleaningOrderPopup = false;
            newState.openFlowerOrderPopup = false;
            newState.openEstateOrderPopup = false;
            newState.openCustomOrderPopup = false;
            newState.openOnDrag = false;
            newState.showStageChangePopup = false;
            newState.openOnOrderDrag = false;
            newState.openOnSend = false;
            newState.openOnSaveCase = false;
            newState.orderIdForSendingmail = null;
            newState.orderDataForSendingMail = null;
            console.log("HIDE_POPUP_SECTION = ", newState.open);
            return newState;
        }
        case 'SHOW_POPUP_ON_DOCUMENT_DELETE': {
            newState.openOnDocumentDelete = true;
            return newState;
        }
        case SHOW_OR_HIDE_REVOKE_ACCESS_LEVEL_POPUP: {
            newState.customerWebAppOrderAccessLevelPopup = action.payload;
            return newState;
        }
        case REVOKE_ACCESS_LEVEL: {
            const currentOrder = newState.selectedCaseData.orders.find((order) => order.id === action.orderId);
            const initialOrder = newState.selectedDataById.orders.find((order) => order.id === action.orderId);
            if ((currentOrder !== undefined) && (initialOrder !== undefined)) {
                const currentRelative = currentOrder.case.relatives.find((caseRelative) => caseRelative.id === action.caseRelativeId);
                const initialRelative = initialOrder.case.relatives.find((caseRelative) => caseRelative.id === action.caseRelativeId);
                if ((currentRelative !== undefined) && (initialRelative !== undefined)) {
                    initialRelative.accessLevel = 0;
                    currentRelative.accessLevel = 0;
                }
            } else {
                console.log("[INFO]: REVOKE_ACCESS_LEVEL :: failure to update accessLevel", currentOrder, initialOrder);
            }
            return newState;
        }
        case 'HIDE_POPUP_ON_DOCUMENT_DELETE': {
            newState.openOnDocumentDelete = false;
            return newState;
        }
        case 'LOGIN_INFO': {
            console.log("LOGIN_INFO = ", action.data);
            if (action.data.challengeName === "NEW_PASSWORD_REQUIRED") {
                newState.user = action.data;
                delete action.data.challengeParam.userAttributes.email_verified;
                delete action.data.challengeParam.userAttributes.phone_number_verified;
                newState.userAttributes = action.data.challengeParam.userAttributes;
                newState.hideVisibility = false;
            } else {
                newState.user = action.data;
                newState.hideVisibility = false;
                newState.isUserLoggedIn = true;
                let idToken = action.data.signInUserSession.idToken;
                setTenantId(idToken.payload.tenantId);
                setTenantTimeZone(idToken.payload.tenantTimeZone);
                setTenantLanguage(idToken.payload.tenantLanguage);
                setTenantCurrency(idToken.payload.tenantCurrency);
                setExpiryDate(idToken.payload.exp);
                setApiToken(idToken.jwtToken);
                setLangStatus(idToken.payload.culture);
                setIdaTheme(idToken.payload.idaTheme);
                setRoles(idToken.payload.roles);
                setFenixUser(AppUtil.fenixUser(idToken.payload.fenixUserId, idToken.payload.fenixUserName, idToken.payload.email));
                NoteUtil.setDefaultIsSidePanelShown();
            }
            newState.loginErrorMessage = null;
            newState.resetLoginErrorMessage = null;
            return newState;
        }
        case 'LOGIN_INFO_FROM_AFTER_CONFIRM': {
            newState.isUserLoggedIn = true;
            let idToken = action.data.idToken;
            setTenantId(idToken.payload.tenantId);
            setTenantTimeZone(idToken.payload.tenantTimeZone);
            setTenantLanguage(idToken.payload.tenantLanguage);
            setTenantCurrency(idToken.payload.tenantCurrency);
            setExpiryDate(idToken.payload.exp);
            setApiToken(idToken.jwtToken);
            setLangStatus(idToken.payload.culture);
            setRoles(idToken.payload.roles);
            setFenixUser(AppUtil.fenixUser(idToken.payload.fenixUserId, idToken.payload.fenixUserName));
            NoteUtil.setDefaultIsSidePanelShown();
            return newState;
        }
        case 'AFTER_REFRESH_TOKEN': {
            newState.isUserLoggedIn = true;
            let idToken = action.data.idToken;
            setApiToken(idToken.jwtToken);
            setExpiryDate(idToken.payload.exp);
            return newState;
        }
        case 'GET_API_TOKEN_FROM_COOKIE': {
            newState.isUserLoggedIn = true;
            return newState;
        }
        case 'AUTH_USER_DETAIL': {
            newState.selectedAuthUser = action.data.object;
            setProfileImageUrl(action.data.object.profileimageurl);
            newState.backUpAuthUser = action.data.object;
            return newState;
        }
        case CHANGE_USER_INFO: {
            if (action.fieldName === 'userName') {
                newState.selectedAuthUser.name = action.data;
            } else if (action.fieldName === 'initials') {
                newState.selectedAuthUser.initials = action.data;
            } else if (action.fieldName === 'culture') {
                newState.selectedAuthUser.culture = action.data;
            } else if (action.fieldName === 'externalEmail') {
                newState.selectedAuthUser.externalEmail = action.data === "" ? null : action.data;
            } else {
                newState.selectedAuthUser.phonenumber = action.data;
            }
            return newState;
        }
        case UPDATE_HAS_USER_CHANGED: {
            newState.hasUserChanged = action.payload;
            return newState;
        }
        case 'FETCHED_ALL_ROLE': {
            newState.allRoleData = action.data.object;
            newState.allRoleDataWithSelectedUserInfo = newState.allRoleData
            return newState;
        }
        case 'SAVE_USER': {
            if (!action.data.success) {
                bottomCenter().error(action.data.message);
            } else {
                bottomCenter().success(Utility.getLang().successMessages.success);
            }

            return newState;
        }
        case 'UPDATE_USER_WITH_FENIX': {
            if (!action.data.success) {
                bottomCenter().error(action.data.message);
            } else {
                newState.selectedUser = {};
                newState.initialSelectedUser = {};
                newState.hasCardInfoChanged = false;
                newState.allRoleDataWithSelectedUserInfo = [];
                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
            }
            return newState;
        }
        case 'UPDATED_SELF_USER': {
            if (!action.data.success) {
                bottomCenter().error(action.data.message);
            } else {
                newState.selectedAuthUser = action.data.object;
                newState.backUpAuthUser = action.data.object;
                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
            }
            return newState;
        }
        case 'NOTIFICATION_RESPONSE': {
            if (action.data.success === true) {
                bottomCenter().success(action.data.message);
            } else {
                bottomCenter().error(action.data.message);
            }
            return newState;
        }
        case 'UPDATED_IMAGE_USER': {
            newState.selectedAuthUser.profileimageurl = action.data;
            newState.selectedUser.profileimageurl = action.data;
            setProfileImageUrl(action.data);
            return newState;
        }

        case GET_USER_DATA_BY_ID: {
            newState.selectedUser = action.data.object;
            newState.initialSelectedUser = action.data.object;
            for (let i = 0; i < newState.allRoleDataWithSelectedUserInfo.length; i++) {
                for (let k = 0; k < newState.selectedUser.roles.length; k++) {
                    if (newState.allRoleDataWithSelectedUserInfo[i].id === newState.selectedUser.roles[k]) {
                        newState.allRoleDataWithSelectedUserInfo[i].checked = true;
                        applyRoleRestrictions(newState.allRoleDataWithSelectedUserInfo, newState.allRoleDataWithSelectedUserInfo[i].id, true);
                    }
                }
            }

            return newState;
        }
        case UPDATE_VENDORS: {
            const {payload} = action;
            newState.vendors = payload;
            return newState;
        }
        case CHANGE_USER_DETAILS_FROM_SETTINGS: {
            const {property, payload} = action;
            newState.selectedUser[property] = payload;
            return newState;
        }
        case 'CHANGE_CHECKBOX_VALUE': {
            newState.checkBoxId = action.data;

            for (let i = 0; i < newState.allRoleDataWithSelectedUserInfo.length; i++) {
                if (newState.allRoleDataWithSelectedUserInfo[i].id === newState.checkBoxId) {
                    newState.allRoleDataWithSelectedUserInfo[i].checked = newState.allRoleDataWithSelectedUserInfo[i].checked !== true;
                }
            }

            applyRoleRestrictions(newState.allRoleDataWithSelectedUserInfo, newState.checkBoxId, false);

            let selectedRolesOnForm = [];
            for (let a = 0; a < newState.allRoleDataWithSelectedUserInfo.length; a++) {
                if (newState.allRoleDataWithSelectedUserInfo[a].checked) {
                    selectedRolesOnForm.push(newState.allRoleDataWithSelectedUserInfo[a].id);
                }
            }

            console.log(selectedRolesOnForm);
            if (newState.selectedUser.roles !== undefined) {
                newState.selectedUser.roles = selectedRolesOnForm;
            } else {
                newState.selectedUser.roles = [];
                newState.selectedUser.roles.push(newState.checkBoxId)
            }

            return newState;
        }
        case 'UPDATE_USER_ACTIVE_STATUS': {
            newState.userStatus = action.data;
            return newState;
        }
        case 'FETCH_ALL_USER_DATA': {
            newState.selectedUser = {};
            if (action.data.success === true) {
                newState.allUserData = action.data.object.users;
                newState.allUserData.forEach(function (user) {
                    let roleName = '';
                    user.roles.forEach(function (role) {
                        newState.allRoleData.forEach(function (mainRole) {
                            if (role === mainRole.id) {
                                if (roleName === '') {
                                    roleName = mainRole.name
                                } else {
                                    roleName = roleName + ',' + mainRole.name
                                }
                            }
                        })
                    });
                    user.roleName = roleName
                })
            }
            return newState;
        }
        case 'REMOVE_PRODUCT_AFTER_DELETE': {
            newState.deletedProduct = {};
            newState.deletedProduct = action.data;
            return newState;
        }
        case DELETED_SUCCESSFULLY: {
            if (action.data.message === "Success" && newState.deletedProduct !== undefined && newState.deletedProduct.id !== undefined) {
                newState.deletedProductSuccessfully = {};
                newState.deletedProductSuccessfully = newState.deletedProduct;
            }
            newState.loadingView = AppUtil.loadingStatus.hasDeleted;
            action.data.message === "Success" ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(action.data.message);
            return newState;
        }
        case DELETED_PRODUCT_SUCCESSFULLY: {
            if (action.data.message === "Success" && action.deleteddata.id !== undefined) {
                newState.deletedProductSuccessfully = {};
                newState.deletedProductSuccessfully = action.deleteddata;
                if (action.deleteddata.parentIds.length > 0) {
                    newState.deletedProductParentId = action.deleteddata.parentIds[0];
                }
            }
            action.data.message === "Success" ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(action.data.message);
            return newState;
        }
        case 'CODE_RESPONSE': {
            newState.codeResponse = action.data;
            if (newState.codeResponse.CodeDeliveryDetails.Destination !== undefined) {
                newState.showResetPassword = true;
            }
            return newState;
        }
        case 'CHANGED_PASSWORD_RESPONSE': {
            newState.showResetPassword = false;
            bottomCenter().success(Utility.getLang().successMessages.passwordUpdated);
            return newState;
        }
        case 'RESET_PASSWORD_DONE': {
            newState.loadingView = AppUtil.loadingStatus.hasUpdated;
            bottomCenter().success(Utility.getLang().successMessages.passwordUpdated);
            return newState;
        }
        case 'SIGN_OUT_FROM_COGNITO': {
            for (let b = 0; b < newState.parentSearchResponse.length; b++) {
                newState.parentSearchResponse[b].data = [];
                newState.parentSearchResponse[b].nextNavigation = "";
                newState.parentSearchResponse[b].toggleClick = "";
                newState.parentSearchResponse[b].count = 0;
            }
            newState.inputItem = '';
            newState.globalSearchPanel = defaultGlobalSearch();
            newState.isUserLoggedIn = false;
            newState.headerMenuItemClicked = '';
            newState.userAttributes = {};
            newState.currentColumnView = {};
            newState.selectedAuthUser = {};
            newState.unsavedPopupInfo = {};
            newState.hasCardInfoChanged = false;
            newState.showNotificationPanel = false;
            newState.showSearchPanel = false;
            newState.showUnsavedPopup = false;
            newState.startUpgradePopup = false;
            newState.user = {};
            removeFenixUser();
            newState.hasUnsavedData = false;

            //clear
            newState.counties = [];
            newState.countries = [];
            return newState;
        }
        case 'ON_ERROR': {
            const error = action.data;
            const statusCode = error.status;
            if (ErrorUtil.actualError(statusCode)) {
                bottomCenter().error(error.message);
            }
            return newState;
        }
        case 'ON_LOGIN_ERROR': {
            newState.loginErrorMessage = action.data;
            return newState;
        }
        case 'ON_RESET_LOGIN_ERROR': {
            newState.resetLoginErrorMessage = action.data.message;
            return newState;
        }
        case 'REMOVE_LOGIN_ERROR': {
            newState.loginErrorMessage = null;
            newState.resetLoginErrorMessage = null;
            newState.showResetPassword = false;
            return newState;
        }
        case 'FETCHED_ALL_PRODUCT_CATEGORY': {
            console.log("FETCHED_ALL_PRODUCT_CATEGORY", action.data);
            newState.productCategoryData = action.data.object;
            newState.particularProductCategoryData = {};
            return newState;
        }
        case 'STORE_COMMON_LIST': {
            console.log("STORE_COMMON_LIST", action.data);
            switch (action.typeOfData) {
                case CaseUtil.multipleFilterWithApiTypes.labels: {
                    for (let i = 0; i < action.data.object.labels.length; i++) {
                        action.data.object.labels[i]['name'] = action.data.object.labels[i].title;
                    }
                    newState.commonListForFilter[action.typeOfData] = action.data.object.labels;
                    break;
                }
                case CaseUtil.multipleFilterWithApiTypes.users: {
                    newState.commonListForFilter[action.typeOfData] = action.data.object.users;
                    break;
                }
                default: {
                    newState.commonListForFilter[action.typeOfData] = action.data.object;
                }
            }
            return newState;
        }
        case 'FETCHED_EVENTTYPES_PRODUCT_CATEGORY': {
            newState.eventTypeData = action.data.object;
            return newState;
        }
        case 'CLEAR_DATA_FOR_CATEGORY': {
            newState.sectionData = [];
            newState.sectionData.push({id: 1, name: 'sectionFuneral'}, {id: 2, name: 'sectionLegal'}, {
                id: 4,
                name: 'sectionGuest'
            });
            newState.selectedProductCategory = {};
            return newState;
        }
        case 'GOT_PARTICULAR_PRODUCT_CATEGORY': {
            newState.particularProductCategoryData = action.data.object;
            return newState;
        }
        case 'CHANGE_CATEGORY_DETAILS': {
            if (action.fieldName === 'name') {
                newState.selectedProductCategory.name = action.data
            } else if (action.fieldName === 'description') {
                newState.selectedProductCategory.description = action.data
            } else if (action.fieldName === 'slug') {
                newState.selectedProductCategory.slug = action.data
            } else if (action.fieldName === 'isActive') {
                newState.selectedProductCategory.isActive = action.data
            } else if (action.fieldName === 'parentId') {
                newState.selectedProductCategory.parentId = action.data
            } else if (action.fieldName === 'showInFunnel') {
                newState.selectedProductCategory.showInFunnel = action.data
            } else if (action.fieldName === 'grossMargin') {
                newState.selectedProductCategory.grossMargin = action.data
            } else if (action.fieldName === 'commission') {
                newState.selectedProductCategory.commission = action.data
            } else if (action.fieldName === 'isDefaultMargin') {
                newState.selectedProductCategory.grossMargin = action.data
            } else if (action.fieldName === 'isDefaultCommission') {
                newState.selectedProductCategory.commission = action.data
            }
            return newState;
        }
        case 'CLEAR_CATEGORY_DATA': {
            newState.sectionData = [];
            newState.selectedProductCategory = {};
            newState.copyselectedProductCategory = {};
            return newState;
        }
        case EDIT_PRODUCT_CATEGORY: {
            newState.sectionData = [];
            newState.selectedProductCategory = {};
            newState.sectionData.push({id: 1, name: 'sectionFuneral'}, {id: 2, name: 'sectionLegal'}, {
                id: 4,
                name: 'sectionGuest'
            });

            newState.selectedProductCategory = action.data.object;
            newState.copyselectedProductCategory = action.data.object;
            newState.userStatus = action.data.isActive;
            for (let i = 0; i < newState.sectionData.length; i++) {
                for (let k = 0; k < newState.selectedProductCategory.sections.length; k++) {
                    if (newState.sectionData[i].id === newState.selectedProductCategory.sections[k]) {
                        newState.sectionData[i].checked = true;
                    }
                }
            }

            return newState;
        }
        case 'CHANGE_SECTION_VALUE': {
            newState.sectionBoxId = action.data;
            for (let i = 0; i < newState.sectionData.length; i++) {
                if (newState.sectionData[i].id === newState.sectionBoxId) {
                    newState.sectionData[i].checked = newState.sectionData[i].checked !== true;
                }
            }
            let match = false;
            if (newState.selectedProductCategory.sections !== undefined) {
                for (let b = 0; b < newState.selectedProductCategory.sections.length; b++) {
                    if (newState.selectedProductCategory.sections[b] === newState.sectionBoxId) {
                        match = true;
                        newState.selectedProductCategory.sections.splice(b, 1);
                        break;
                    }
                }
                if (match === false) {
                    newState.selectedProductCategory.sections.push(newState.sectionBoxId)
                }
            } else {
                newState.selectedProductCategory.sections = [];
                newState.selectedProductCategory.sections.push(newState.sectionBoxId)
            }

            return newState;
        }
        case 'CHANGE_EVENT_VALUE': {
            newState.eventBoxId = action.data;
            for (let i = 0; i < newState.eventTypeData.length; i++) {
                if (newState.eventTypeData[i].id === newState.eventBoxId) {
                    newState.eventTypeData[i].checked = newState.eventTypeData[i].checked !== true;
                }
            }
            let match = false;
            if (newState.selectedProductCategory.eventTypes !== undefined) {
                for (let b = 0; b < newState.selectedProductCategory.eventTypes.length; b++) {
                    if (newState.selectedProductCategory.eventTypes[b] === newState.eventBoxId) {
                        match = true;
                        newState.selectedProductCategory.eventTypes.splice(b, 1);
                        break;
                    }
                }
                if (match === false) {
                    newState.selectedProductCategory.eventTypes.push(newState.eventBoxId)
                }
            } else {
                newState.selectedProductCategory.eventTypes = [];
                newState.selectedProductCategory.eventTypes.push(newState.eventBoxId)
            }
            return newState;
        }

        case 'VIEW_PRODUCT_DETAILS': {
            newState.filteredProductData = [];
            for (let j = 0; j < newState.productData.length; j++) {
                if (action.data.id === newState.productData[j].category.id) {
                    newState.filteredProductData.push(newState.productData[j]);
                }
            }
            return newState;
        }
        case 'SORT_DATA_ACC_TO_CATEGORY': {
            newState.filteredProductData = [];
            for (let j = 0; j < newState.productData.length; j++) {
                if (parseInt(action.data, 10) === newState.productData[j].category.id) {
                    console.log("action.data", action.data, newState.productData[j].category.id);
                    newState.filteredProductData.push(newState.productData[j]);
                }
            }
            console.log("newState.filteredProductData", newState.filteredProductData);
            return newState;
        }
        case 'EDIT_PRODUCT': {
            newState.selectedProduct = action.data;
            return newState;
        }
        case 'GO_TO_ADD_FORM_FROM_PRODUCT_VIEW': {
            newState.productViewToAddForm = true;
            return newState;
        }
        case 'GO_TO_ADD_CHILD_PRODUCT_FROM_PRODUCT_VIEW': {
            newState.productViewToAddFormForChild = true;
            newState.allParentData = [];
            for (let d = 0; d < newState.productData.length; d++) {
                if (newState.productData[d].type === 8) {
                    newState.allParentData.push(newState.productData[d]);
                }
            }
            for (let i = 0; i < newState.allParentData.length; i++) {
                if (newState.particularProductData.id === newState.allParentData[i].id) {
                    newState.allParentData[i].checked = true;
                }
            }
            return newState;
        }
        case 'CHANGE_CHECKBOX_FOR_PARENT_VALUE': {
            newState.checkBoxId = action.data;
            for (let i = 0; i < newState.allParentData.length; i++) {
                if (newState.allParentData[i].id === newState.checkBoxId) {
                    newState.allParentData[i].checked = newState.allParentData[i].checked !== true;
                }
            }
            console.log(" newState.allParentData = ", newState.allParentData);
            return newState;
        }
        case ADDED_PRODUCT: {
            newState.productViewToAddFormForChild = false;
            newState.productViewToAddForm = false;
            newState.updatedSelectedCardItem = action.data.object;
            console.log("newState.updatedSelectedCardItem ADDED_PRODUCT", newState.updatedSelectedCardItem);
            return newState;
        }
        /*case 'GOT_PARTICULAR_LOCATION_INFO': {
            newState.particularLocationData = action.data.object;
            newState.locationAttrubute = action.data.object.attributes;
            newState.contactAddress = action.data.object.contactAddress;
            return newState;
        }*/
        case 'FETCHED_ALL_PRODUCT_TYPES': {
            newState.productTypes = action.data.object;
            return newState;
        }
        case 'FETCHED_PERSON_INFO': {
            newState.personInfo = action.data.object;
            newState.allPersonDir = [];
            for (let n = 0; n < newState.personInfo.length; n++) {
                newState.allPersonDir.push({value: newState.personInfo[n].id, label: newState.personInfo[n].name})
            }
            return newState;
        }
        case UPDATE_COMMON_DATA_DETAILS: {
            newState.selectedDataById = {};
            newState.selectedProductData = {};
            newState.categoryItemsinfo = [];
            newState.counter.caseRelatives = 0;
            newState.counter.caseOrders = 0;
            newState.counter.caseNotes = 0;
            newState.counter.caseGuests = 0;
            newState.counter.caseCalls = 0;
            newState.counter.orderNotes = 0;
            newState.counter.caseLogs = 0;
            newState.counter.documents = 0;
            newState.counter.reportedMemoriesCount = 0;
            newState.counter.orderLogs = 0;
            newState.counter.callNotes = 0;
            newState.counter.callLogs = 0;

            if (action.data.success === true && AppUtil.isAvailable(action.data.object)) {
                const resp = cloneDeep(action.data.object);

                if (resp.labels !== undefined && resp.labels.length > 0) {
                    for (let h = 0; h < resp.labels.length; h++) {
                        resp.labels[h].value = resp.labels[h].id.toString();
                        resp.labels[h].label = resp.labels[h].title;
                    }
                }
                if (newState.headerMenuItemClicked === AppUtil.linkPaths.case.pathToRoute || newState.headerMenuItemClicked === AppUtil.linkPaths.convert.pathToRoute || newState.headerMenuItemClicked === AppUtil.linkPaths.organise.pathToRoute) {
                    if (resp.orders !== undefined) {
                        setProductNames(resp, 'case');
                        // todo with original data we have to add isGuest key according to guestId in relative object
                        for (let i = 0; i < resp.relatives.length; i++) {
                            if (resp.relatives[i].isDeletable === undefined) {
                                resp.relatives[i].isDeletable = true;
                            }
                            if (resp.relatives[i].guestId === undefined) {
                                resp.relatives[i].guestId = 0;
                            }
                            if (resp.relatives[i].guestId > 0) {
                                resp.relatives[i].isGuest = true;
                            } else {
                                resp.relatives[i].isGuest = false;
                            }
                        }
                        //Setting 'isManual' after ws response
                        if (resp.deadline.isManual === undefined) {
                            resp.deadline.isManual = DeadlineDateElement.defaultIsManualDeadline(resp.deadline);
                        }
                        newState.selectedDataById = resp;
                        newState.selectedCaseData = resp;
                        newState.counter.caseRelatives = resp.relatives.length;
                        newState.counter.caseOrders = resp.orders.length;
                        newState.counter.caseNotes = resp.notes.length;
                        newState.counter.caseGuests = resp.guests.length;
                        newState.counter.caseCalls = resp.calls.length;
                    }

                    newState.caseRelativesWithEmailId = RelativeUtil.getRelativesWithEmail(resp.relatives);

                    // TODO: currently IDA is handling this, it should be sent from server
                    resp.pageVisibility.isMessagePageEnabled = resp.isMessagePageEnabled;
                    //Saved page visibility only for case/convert/organise path
                    setPageVisibilityForCardId(resp.id, resp.pageVisibility);
                }
                if (newState.headerMenuItemClicked === AppUtil.linkPaths.order.pathToRoute || newState.headerMenuItemClicked === AppUtil.linkPaths.bill.pathToRoute) {
                    if (resp.events !== undefined) {
                        setProductNames(resp, 'order');
                        newState.selectedDataById = resp;
                        newState.selectedOrderData = resp;
                        newState.counter.orderNotes = resp.notes.length;
                        newState.orderUpdated = false;
                    }
                }

                if (newState.headerMenuItemClicked === AppUtil.linkPaths.product.pathToRoute) {
                    handlingVariantProductDropdownData(resp);
                    initialUpdateToEmptyProductCategory(resp);
                    newState.selectedDataById = resp;
                    newState.selectedProductData = resp;
                }
                if (newState.headerMenuItemClicked === '/calls') {
                    newState.counter.callNotes = resp.notes.length;
                }
                newState.selectedDataById = resp;
                newState.selectedLocationData = resp;
                newState.selectedCallData = resp;
                newState.selectedContactData = resp;
                //accessUserList from case data
                newState.wsLockCaseData.assigned = resp.assigned;
                newState.wsLockCaseData.accessUserListWithOwnerDict[resp.id] = LockCaseUtil.getAccessUserListWithOwner(cloneDeep(resp.accessUserList), resp.assigned);
            } else {
                bottomCenter().error(action.data.message);
            }

            if (newState.selectedCaseData !== undefined && newState.selectedCaseData !== null && newState.selectedCaseData.deceased !== undefined) {
                newState.isMemberSwedishChurch = newState.selectedCaseData.deceased.isMemberSwedishChurch === true;
            }

            newState.caseUpdated = false;
            newState.orderUpdated = false;
            newState.callUpdated = false;
            newState.productUpdated = false;
            newState.locationUpdated = false;
            newState.contactUpdated = false;
            newState.componentValidationErrorsInfo = {};

            return newState;
        }
        case UPDATED_PRODUCT_SUCCESSFULLY: {
            newState.updatedSelectedCardItem = {};
            if (action.data.success === true) {
                newState.updatedSelectedCardItem = action.data.object;
                action.oldData = Object.assign({}, newState.selectedProductData);
                console.log("UPDATED_PRODUCT_SUCCESSFULLY", newState.updatedSelectedCardItem, action.data.object.productMedias);
                action.oldData.category = action.data.object.category;
                action.oldData.version = action.data.object.version;
                action.oldData.productMedias = action.data.object.productMedias;
                initialUpdateToEmptyProductCategory(action.oldData);
                newState.selectedProductData = action.oldData;
                newState.selectedDataById = action.oldData;
                console.log("UPDATED_PRODUCT_SUCCESSFULLY 1", newState.selectedProductData, action.oldData.productMedias);
                newState.productUpdated = true;
                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
                bottomCenter().success(Utility.getLang().successMessages.productUpdated);

            }
            return newState;
        }

        case CLEAR_INITIAL_VALUES: {
            clearMemorial();
            clearColumnPageData();
            clearGlobalColumnLoadingTracker();
            clearProbate();
            newState.newActiveCallDetails = {};
            newState.cardSelectedFromGlobalSearch = {};
            newState.selectedDataById = {};
            newState.selectedProductData = {};
            newState.selectedCaseData = {};
            newState.selectedLocationData = {};
            newState.selectedContactData = {};
            newState.deletedChildFromParentProduct = false;
            newState.updatedSelectedCardItem = {};
            newState.selectedVariantProduct = {};
            newState.deletedProductSuccessfully = {};
            newState.deletedProductParentId = null;
            newState.allPersonDir = [];
            return newState;
        }
        case 'MAKE_OLD_AND_NEW_CARD_VALUES_SAME_FOR_EMPTY_COLUMN': {
            newState.selectedDataById = newState.selectedOrderData;
            return newState;
        }
        case CLEAR_USELESS_VALUES: {
            newState.productWithVariantData = {};
            newState.selectedParentOfVariantProduct = {};

            newState.caseDetailsLogs = null;
            newState.orderDetailsLogs = null;
            newState.selectedDataById = {};
            newState.selectedCaseData = {};
            return newState;
        }
        case UPDATE_PRODUCT_WITH_VARIANT_DATA: {
            newState.productWithVariantData = {};
            if (newState.selectedParentOfVariantProduct.id === undefined) {
                newState.selectedParentOfVariantProduct = action.data;
            }
            newState.productWithVariantData = action.data;
            // console.log("UPDATE_PRODUCT_WITH_VARIANT_DATA", newState.productWithVariantData);
            return newState;
        }
        case CHANGE_DATA_IN_UI_FOR_VARIANTS: {
            newState.selectedVariantProduct = {};
            newState.selectedVariantProduct = action.data;
            console.log("newState.selectedVariantProduct ", newState.selectedVariantProduct);
            return newState;
        }
        case 'FETCHED_ALL_CHILD_PRODUCT': {
            let allChild = [];
            allChild = action.data.object.products;
            console.log("FETCHED_ALL_CHILD_PRODUCT ", newState.selectedDataById, allChild);
            newState.allChildDataExceptCurrentChild = [];
            for (let j = 0; j < allChild.length; j++) {
                let isMatched = false;
                for (let k = 0; k < newState.selectedProductData.relatedProducts.length; k++) {
                    if (newState.selectedProductData.relatedProducts[k].id === allChild[j].id) {
                        isMatched = true;
                        break
                    }
                }
                if (isMatched === false) {
                    newState.allChildDataExceptCurrentChild.push(allChild[j])
                }
            }
            newState.customData = [];

            if (newState.allChildDataExceptCurrentChild !== undefined && newState.allChildDataExceptCurrentChild.length > 0) {
                newState.allChildDataExceptCurrentChild.map((childData) => {
                    return newState.customData.push({
                        value: childData.id, label: childData.name
                    });
                })
            }
            return newState;
        }
        case 'GET_LOCATION_TYPES': {
            newState.locationTypes = action.data.object;
            return newState;
        }
        case 'GET_PERSON_TYPES': {
            newState.allPersonTypes = action.data.object;
            return newState;
        }
        case 'GET_LOCATION_FOR_CONTACT': {
            newState.locationsForContact = action.data.object;
            return newState;
        }
        case UPDATED_LOCATION_SUCCESSFULLY: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;

            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && AppUtil.isAvailable(respData.object)) {
                const respLocation = respData.object;
                newState.selectedLocationData = respLocation;
                newState.selectedDataById = respLocation;
                newState.locationUpdated = true;
                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
                bottomCenter().success(Utility.getLang().successMessages.locationUpdated);
                return newState;
            } else {
                return state;
            }
        }
        case UPDATED_CONTACT_SUCCESSFULLY: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;

            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && AppUtil.isAvailable(respData.object)) {
                const respContact = respData.object;
                newState.selectedContactData = respContact;
                newState.selectedDataById = respContact;
                newState.contactUpdated = true;
                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
                bottomCenter().success(Utility.getLang().successMessages.contactUpdated);
                return newState;
            } else {
                return state;
            }
        }
        case ADDED_CONTACT_SUCCESSFULLY: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;

            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && (respData.object !== undefined)) {
                const respContact = respData.object;
                newState.selectedContactData = respContact;
                newState.selectedDataById = respContact;
                newState.contactUpdated = true;
                bottomCenter().success(Utility.getLang().successMessages.contactAdded);
                return newState;
            } else {
                return state;
            }
        }
        case 'CHANGE_ACTIVE_STATUS_FOR_LOCATION': {
            // newState.locationActiveStatus = action.data;
            newState.selectedLocationData.isActive = action.data;
            return newState;
        }
        case 'CHANGE_ALTTEXT_VALUE': {
            // newState.locationActiveStatus = action.data;
            newState.altTextInfo = action.data;
            return newState;
        }
        case ADDED_LOCATION: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && (respData.object !== undefined)) {
                const respLocation = respData.object;
                newState.selectedDataById = respLocation;
                newState.selectedLocationData = respLocation;
                newState.locationUpdated = true;
                return newState;
            } else {
                return state;
            }
        }
        /*case 'UPDATED_PRODUCT_WITH_CHILD': {
            newState.updatedProductWithChild = action.data.object;
            return newState;
        }*/

        case 'FETCHED_ALL_STAGES': {
            newState.caseAllStages = [];
            if (action.data.success === true) {
                newState.caseAllStages = action.data.object;
            }
            if (action.data.success) {
                setCaseStages(action.data.object);
            }
            return newState;
        }
        case 'FETCHED_ALL_FOOD_RESTRICTION': {
            //console.log("FETCHED_ALL_FOOD_RESTRICTION",action.data);
            newState.foodRestrictionList = action.data;
            return newState;
        }
        case 'SET_FOOD_RESTRICTION': {
            newState.selectedCaseData.foodRestrictions = action.data;
            return newState;
        }
        case 'FETCHED_ALL_LABELS': {
            newState.caseAllLabels = [];
            newState.newLabel = [];
            if (action.data.success === true) {
                if (action.data.object !== undefined && action.data.object.labels.length > 0) {
                    for (let n = 0; n < action.data.object.labels.length; n++) {
                        let isMatched = false;
                        if (newState.selectedDataById.labels !== undefined) {
                            for (let b = 0; b < newState.selectedDataById.labels.length; b++) {
                                if (action.data.object.labels[n].id === newState.selectedDataById.labels[b].id) {
                                    isMatched = true;
                                    break
                                }
                            }
                        }
                        if (isMatched === false) {
                            newState.newLabel.push(action.data.object.labels[n])
                        }
                    }
                    //newState.caseAllLabels = newState.newLabel;
                }
                newState.newLabel.map((childData) => {
                    //console.log("childData",childData)
                    return newState.caseAllLabels.push({
                        value: childData.id.toString(),
                        label: childData.title,
                        colorCode: childData.colorCode,
                        id: childData.id,
                        isActive: childData.isActive,
                        title: childData.title,
                        type: childData.type
                    });
                })
            }
            return newState;
        }
        case 'ADD_LABEL_TO_STATE': {
            let allLable = [];
            newState.selectedCaseData.labels.push(action.data);
            for (let n = 0; n < newState.caseAllLabels.length; n++) {
                let isMatched = false;
                for (let b = 0; b < newState.selectedCaseData.labels.length; b++) {
                    console.log("Check", newState.caseAllLabels[n].id, newState.selectedCaseData.labels[b].id);

                    if (newState.caseAllLabels[n].id === newState.selectedCaseData.labels[b].id) {
                        isMatched = true;
                        console.log("Matched", newState.caseAllLabels[n].id, newState.selectedCaseData.labels[b].id);
                        break
                    }
                }
                if (isMatched === false) {
                    console.log("NOT Matched", newState.caseAllLabels[n]);
                    allLable.push(newState.caseAllLabels[n])
                }

            }
            newState.caseAllLabels = [];
            newState.caseAllLabels = allLable;
            console.log("908", newState.caseAllLabels);
            return newState;
        }
        case 'REMOVE_LABEL_FROM_VIEW': {
            let newLabelList = [];
            newState.caseAllLabels.push(action.data);
            for (let z = 0; z < newState.selectedCaseData.labels.length; z++) {
                let match = false;
                if (action.data.id === newState.selectedCaseData.labels[z].id && action.index === z) {
                    if (newState.selectedCaseData.labels.length === 1) {
                        newState.selectedCaseData.labels = [];
                    }
                    match = true
                } else if (match === false) {
                    newLabelList.push(newState.selectedCaseData.labels[z])
                }
            }
            if (newLabelList.length > 0) {
                newState.selectedCaseData.labels = newLabelList;
            }
            return newState;
        }

        case 'ON_DETAILS_FROM_SPAR': {
            if (action.data.object.found === true) {
                let sparData = action.data.object;
                newState.selectedCaseData.deceased.firstName = sparData.firstName;
                newState.selectedCaseData.deceased.lastName = sparData.lastName;
                newState.selectedCaseData.deceased.address.address = sparData.address;
                newState.selectedCaseData.deceased.address.city = sparData.city;
                newState.selectedCaseData.deceased.address.postalCode = sparData.postalCode;
            } else {
                bottomCenter().error(action.data.message);
            }
            return newState;
        }
        case 'ON_DETAILS_FROM_SPAR_OF_RELATIVE': {
            if (action.data.object.found === true) {
                let sparData = action.data.object;
                let relatives = newState.selectedCaseData.relatives[action.index];
                relatives.firstName = sparData.firstName;
                relatives.lastName = sparData.lastName;
                if (sparData.hasSpecialAddress === true) {
                    relatives.address.address = sparData.specialAddress.address;
                    relatives.address.city = sparData.specialAddress.city;
                    relatives.address.postalCode = sparData.specialAddress.postalCode;
                } else {
                    relatives.address.address = sparData.address;
                    relatives.address.city = sparData.city;
                    relatives.address.postalCode = sparData.postalCode;
                }
                newState.selectedCaseData.relatives[action.index] = relatives;
            } else {
                bottomCenter().error(action.data.message);
            }
            return newState;
        }
        case 'ON_DETAILS_FROM_FLAX': {
            if (action.data.success === true) {
                let parishName = action.data.object.parishName;
                if (parishName !== null && parishName !== undefined) {
                    newState.selectedCaseData.deceased.parishName = parishName;
                } else {
                    bottomCenter().error(Utility.getLang().errorMessages.case.parishNameNotFound);
                }
            } else {
                bottomCenter().error(action.data.message);
            }
            return newState;
        }
        case 'FETCHED_ALL_RELATIONSHIP_DETAILS': {
            if (action.data.success === true) {
                newState.allRelationshipInfo = action.data.object;
            }
            return newState;
        }
        case 'FETCHED_ALL_USERS_FOR_CASES': {
            newState.allUserDetailsForCases = [];
            if (action.data.success === true) {
                action.data.object.users.forEach((user) => {
                    if (!user.isactive) {
                        user.isDisabled = true
                    }
                });
                newState.allUserDetailsForCases = action.data.object.users
            }
            return newState;
        }
        case 'FETCHED_ALL_BILLING_CONTACT_FOR_CASES': {
            //Relatives as billing contacts
            let billingContacts = [];
            let relatives = action.data.object !== null ? action.data.object.relatives : null;
            if (AppUtil.isAvailable(relatives) && relatives.length > 0) {
                for (let i = 0; i < relatives.length; i++) {
                    if (relatives[i].isBillingContact === true) {
                        billingContacts.push(relatives[i]);
                    }
                }
            }
            newState.billingContactsForCase = billingContacts;
            return newState;
        }
        case 'SET_OWNER_AND_LOSS_REASON': {
            newState.popupCase.owner = action.user.id;
            newState.popupCase.lossReason = action.reason;
            return newState;
        }
        case 'FETCHED_LOST_REASONS': {
            if (action.data.success) {
                newState.lostReasons = action.data.object;
            }
            return newState;
        }
        case 'FETCHED_NO_CASE_REASONS': {
            if (action.data.success) {
                newState.noCaseReasons = action.data.object;
            }
            return newState;
        }
        case 'CLEAR_OWNER_AND_LOSS_REASON': {
            newState.popupCase.owner = null;
            newState.popupCase.lossReason = null;
            return newState;
        }
        case 'CLEAR_BILLING_CONTACT_AND_DELIVERY_METHOD': {
            newState.orderDragDrop.billingContactId = null;
            newState.orderDragDrop.deliveryMethod = null;
            return newState;
        }

        case UPDATED_CASE_STAGE: {
            // console.log("action", action.data);
            if (action.data.success === false) {
                bottomCenter().error(action.data.message)
            } else {
                newState.updatedCaseStage = action.data.object;//TODO:Unused, remove it
                newState.updatedCaseStageObject = action.data;//TODO:Unused, remove it
            }
            return newState;
        }

        case 'FETCH_CASE_LOGS': {
            newState.caseDetailsLogs = action.data.object;
            return newState;
        }
        case 'FETCH_ORDER_LOGS': {
            newState.orderDetailsLogs = action.data.object;
            return newState;
        }
        case 'FETCH_CALL_LOGS': {
            newState.callDetailsLogs = action.data.object;
            return newState;
        }
        case 'FETCH_CASE_LOGS_WITH_FILTER': {
            const respObject = action.data.object;
            if (AppUtil.isAvailable(respObject.logs) && (respObject.logs.length > 0)) {
                newState.caseLogDetails = respObject.logs;
            }
            return newState;
        }
        case 'FETCH_CASE_EVENTS': {
            if (action.data.success) {
                newState.caseEvents = action.data.object;
            }
            return newState;
        }
        case 'FETCH_CASE_AUTHORS': {
            if (action.data.success) {
                newState.caseAuthors = action.data.object;
            }
            return newState;
        }
        case SET_COUNTERS: {
            // console.log("action.data.count", action.data);
            if (action.data.success) {
                const resp = action.data.object;
                // console.log("action.data.count", action.data);
                if (AppUtil.isAvailable(resp.logs)) {
                    // console.log("action.data.count", action.data);
                    switch (action.headerClicked) {
                        case AppUtil.linkPaths.case.pathToRoute:
                        case AppUtil.linkPaths.convert.pathToRoute:
                        case AppUtil.linkPaths.organise.pathToRoute:
                            newState.counter.caseLogs = resp.logs;
                            break;
                        case AppUtil.linkPaths.bill.pathToRoute:
                        case AppUtil.linkPaths.order.pathToRoute:
                            newState.counter.orderLogs = resp.logs;
                            break;
                        case AppUtil.linkPaths.call.pathToRoute:
                            newState.counter.callLogs = resp.logs;
                            break;
                        default:
                            break;
                    }
                }
                if (AppUtil.isAvailable(resp.documents)) {
                    newState.counter.documents = resp.documents;
                }
                if (AppUtil.isAvailable(resp.reportedMemoriesCount)) {
                    newState.counter.reportedMemoriesCount = resp.reportedMemoriesCount;
                }
            }
            return newState;
        }
        case 'EMAIL_CASE_SUMMARY': {
            if (action.data.success === true) {
                bottomCenter().success(action.data.message);
            } else {
                bottomCenter().error(action.data.message);
            }

            return newState;
        }

        case 'SAVE_ORDER_RELATIVE': {
            let relativesWithEmail = [];
            if (action.data.case !== undefined) {
                relativesWithEmail = RelativeUtil.getRelativesWithEmail(action.data.case.relatives);
            }
            newState.caseRelativesWithEmailId = relativesWithEmail;
            return newState;
        }
        case 'SAVE_BILLING_CONTACT_ID_FOR_ORDERS': {
            if (action.data !== undefined && action.data.id !== null) {
                if (newState.selectedOrderData.billingContactId !== undefined) {
                    newState.selectedOrderData.billingContactId = action.data.id;
                }
            }
            return newState;
        }

        case 'SAVE_ASSIGNED_USER_ID_FOR_ORDERS': {
            if (AppUtil.isAvailable(action.data) && AppUtil.isAvailable(action.data.id)) {
                if (newState.selectedOrderData.assignedUserId !== undefined) {
                    newState.selectedOrderData.assignedUserId = action.data.id;
                }
                if (AppUtil.isAvailable(newState.selectedOrderData.case)) {
                    newState.selectedOrderData.case.assignedUserId = action.data.id;
                }
            }
            return newState;
        }

        case 'SAVE_ASSIGNED_USER_ID_FOR_ORDERS_IN_CASES': {
            if (action.data !== undefined && action.data.id !== null && action.orderId) {
                // eslint-disable-next-line
                newState.selectedCaseData.orders.some((order) => {
                    if (order.id === action.orderId) {
                        order.assignedUserId = action.data.id;
                        return true;
                    }
                });
            }
            return newState;
        }
        case 'SAVE_BILLING_CONTACT_ID_FOR_ORDERS_IN_CASES': {
            if (action.data !== undefined && action.data.id !== null && action.orderId) {
                // eslint-disable-next-line
                newState.selectedCaseData.orders.some((order) => {
                    if (order.id === action.orderId) {
                        order.billingContactId = action.data.id;
                        return true;
                    }
                });
            }
            return newState;
        }
        case UPDATE_WORK_LOG_PDF_DETAILS: {
            const {property, payload, matchId} = action;
            if (payload && matchId) {
                // eslint-disable-next-line
                newState.selectedCaseData.orders.some((order) => {
                    if (order.id === matchId) {
                        order[property] = payload;
                        return true;
                    }
                });
            }
            return newState;
        }
        case UPDATE_CASE_ORDER_PROPERTY: {
            const {payload, property, orderId} = action;
            // eslint-disable-next-line
            newState.selectedCaseData.orders.some((order) => {
                if (order.id === orderId) {
                    order[property] = payload;
                    return true;
                }
            });
            return newState;
        }
        case 'SAVE_DELIVERY_METHOD_FOR_ORDERS': {
            if (action.data !== undefined) {
                if (newState.selectedOrderData.deliveryMethod !== undefined) {
                    newState.selectedOrderData.deliveryMethod = parseInt(action.data, 10);
                }
            }
            console.log("newState.selectedOrderData", newState.selectedOrderData)
            return newState;
        }
        case 'SAVE_BILLING_CONTACT_ORDER_DRAG': {
            newState.orderDragDrop.billingContactId = action.data.id !== undefined ? action.data.id : action.data;
            return newState;
        }
        case 'SAVE_DELIVERY_METHOD_ORDER_DRAG': {
            newState.orderDragDrop.deliveryMethod = action.data;
            return newState;
        }
        case CLEAR_CASE_RELATED_DATA: {
            newState.mergedCaseDetails = {};
            newState.beforeSelectedId = "";
            return newState;
        }
        case CHANGE_DECEASED_PROPERTY: {
            newState.selectedCaseData.deceased[action.name] = action.value;
            return newState;
        }

        case 'ASSIGN_SELECTED_DATE_OF_BIRTH': {
            newState.selectedCaseData.deceased.dateOfBirth = action.data;
            return newState;
        }
        case 'CHANGE_PERSON_NUMBER_INFO': {
            newState.selectedCaseData.deceased.personNumber = action.data;
            return newState;
        }
        case 'CHANGE_ADDRESS_INFO': {
            newState.selectedCaseData.deceased.address.address = action.data;
            return newState;
        }

        case 'CHANGE_ADDRESS2_INFO': {
            newState.selectedCaseData.deceased.address.address2 = action.data;
            return newState;
        }
        case 'CHANGE_POSTAL_CODE_INFO': {
            newState.selectedCaseData.deceased.address.postalCode = action.data;
            return newState;
        }
        case 'CHANGE_ADDRESS_OF_PASSING_INFO': {
            newState.selectedCaseData.deceased.addressOfPassing.address = action.data;
            return newState;
        }
        case 'CHANGE_ADDRESS2_OF_PASSING_INFO': {
            newState.selectedCaseData.deceased.addressOfPassing.address2 = action.data;
            return newState;
        }
        case 'CHANGE_POSTALCODE_OF_PASSING_INFO': {
            newState.selectedCaseData.deceased.addressOfPassing.postalCode = action.data;
            return newState;
        }
        case 'CHANGE_CITY_OF_PASSING_INFO': {
            newState.selectedCaseData.deceased.addressOfPassing.city = action.data;
            return newState;
        }
        case UPDATE_DECEASED_PROPERTY: {
            newState.selectedCaseData.deceased[action.property] = action.payload;
            return newState;
        }
        case 'CHANGE_LOCATION_OF_PASSING': {
            newState.selectedCaseData.deceased.locationOfPassing = AppUtil.isAvailable(action.data) ?
                action.data.value !== "0" ? action.data.value : action.data.label
                : undefined;
            return newState;
        }
        case 'SET_CAUSE_OF_DEATH': {
            newState.selectedCaseData.deceased.causeOfDeath = AppUtil.isAvailable(action.data) ?
                action.data.value !== "0" ? action.data.value : action.data.label
                : undefined;
            return newState;
        }
        case 'SET_CREMATION_PAID_BY': {
            newState.selectedCaseData.deceased.cremationPaperPaidBy = AppUtil.isAvailable(action.data) ?
                action.data.value !== "0" ? action.data.value : action.data.label
                : undefined;
            return newState;
        }
        case 'SET_GREEN_FORM_OPTIONS': {
            newState.selectedCaseData.deceased.greenFormWith = action.data.value !== "0" ? action.data.value : action.data.label;
            return newState;
        }
        case 'SET_PAYMENT_DETIALS_FOR_CREMATION_PAPERS': {
            newState.selectedCaseData.deceased.paymentDetailsForCremationPapers = action.data.trim();
            return newState;
        }
        case 'CHANGE_STATUS_INFO': {
            newState.selectedCaseData.stage = action.data;
            console.log("newState.selectedCaseData.stage", newState.selectedCaseData.stage, action.data);
            return newState;
        }
        case 'ON_CHANGE_CITY_INFO': {
            newState.selectedCaseData.deceased.address.city = action.data;
            return newState;
        }
        case 'ON_CHANGE_OTHER_DETAILS_INFO': {
            newState.selectedCaseData.deceased.otherDetails = action.data;
            return newState;
        }
        case 'ADD_USER_TO_STATE': {
            newState.selectedCaseData.assigned.id = action.data.id;
            newState.selectedCaseData.assigned.name = action.data.name;
            newState.selectedCaseData.assigned.roles = action.data.roles;
            newState.selectedCaseData.orders.forEach((order, orderIndex) => {
                order.prices = order.bcVat = order.compensations = [];
                reduceTotalDetails(order, CaseUtil.hasCompensationAccess(newState.selectedCaseData.assigned));
            });
            return newState;
        }
        case ADD_COLLABORATOR_USER_TO_STATE: {
            newState.selectedCaseData.collaborator.id = AppUtil.isAvailable(action.data) ? action.data.id : null;
            newState.selectedCaseData.collaborator.name = AppUtil.isAvailable(action.data) ? action.data.name : null;
            newState.selectedCaseData.collaborator.roles = AppUtil.isAvailable(action.data) ? action.data.roles : null;
            return newState;
        }
        case SET_COLLABORATOR: {
            newState.popupCase.collaboratorId = action.data.id;
            return newState;
        }
        case 'ADD_USER_TO_STATE_ORDER': {
            newState.selectedOrderData.assignedUserId = action.data.id;
            newState.selectedOrderData.case.assignedUserId = action.data.id;
            return newState;
        }
        case 'SET_LOCATION_OF_REMAIN': {
            newState.selectedCaseData.deceased.hospitalId = AppUtil.isAvailable(action.data) && AppUtil.isAvailable(action.data.id) ? action.data.id : null;
            newState.selectedCaseData.deceased.hospitalName = AppUtil.isAvailable(action.data) ? (AppUtil.isAvailable(action.data.name) ? action.data.name : (AppUtil.isAvailable(action.data.label) ? action.data.label : null)) : null;
            return newState;
        }
        case 'SET_LOCATION_OF_BODY_PICKUP': {
            newState.selectedCaseData.deceased.locationBodyPickupId = AppUtil.isAvailable(action.data) && AppUtil.isAvailable(action.data.id) ? action.data.id : null;
            newState.selectedCaseData.deceased.locationBodyPickupName = AppUtil.isAvailable(action.data) ? (AppUtil.isAvailable(action.data.name) ? action.data.name : (AppUtil.isAvailable(action.data.label) ? action.data.label : null)) : null;
            return newState;
        }
        case 'SET_BODY_DESTINATION': {
            newState.selectedCaseData.deceased.bodyDestinationId = AppUtil.isAvailable(action.data) && AppUtil.isAvailable(action.data.id) ? action.data.id : null;
            newState.selectedCaseData.deceased.bodyDestinationName = AppUtil.isAvailable(action.data) ? (AppUtil.isAvailable(action.data.name) ? action.data.name : (AppUtil.isAvailable(action.data.label) ? action.data.label : null)) : null;
            return newState;
        }
        case 'SET_PARISH_DATA': {
            newState.selectedCaseData.deceased.parishName = action.data;
            return newState;
        }
        case 'ON_CHANGE_CASE_ISMEMBER_SWEDISH_CHURCH_STATUS': {
            console.log("ON_CHANGE_CASE_ISMEMBER_SWEDISH_CHURCH_STATUS", action.data);
            newState.isMemberSwedishChurch = action.data;
            newState.selectedCaseData.deceased.isMemberSwedishChurch = newState.isMemberSwedishChurch;
            console.log("newState.selectedCaseData.deceased.isMemberSwedishChurch ", newState.selectedCaseData.deceased.isMemberSwedishChurch);
            return newState;
        }
        case 'ON_CHANGE_CASE_DECEASED_DROPDOWN_OPTION_STATUS': {
            newState.selectedCaseData.deceased[AppUtil.isAvailable(action.data) ? action.data.name : action.fieldName.name] = AppUtil.isAvailable(action.data) ? action.data.value : undefined;
            return newState;
        }
        case UPDATE_CASE_INFO_INTO_EXISTING_CASE: {
            const {payload, unfilteredPayload} = action;
            const response = payload;
            const respData = response.data;
            // console.log("UPDATE_CASE_INFO_INTO_EXISTING_CASE = ", action.data);
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && AppUtil.isAvailable(respData.object)) {
                newState.selectedCaseData = {};
                newState.selectedDataById = {};
                newState.tempNote = '';

                let respCase = cloneDeep(respData.object);
                const unfilteredReqCase = unfilteredPayload;

                //Order Email
                newState.caseDetailsOnOrderChangeForEmail = CaseUtil.getCaseWithUpdatedBillingContact(respCase);

                //Orders except 'stage = deleted'
                respCase.orders = CaseUtil.getOrdersExceptDeleted(respCase);

                //Order event, categories, categoryData
                respCase.orders.forEach((order, orderIndex) => {
                    order.events.forEach((event, eventIndex) => {
                        const oldEvent = OrderUtil.getMatchedEvent(unfilteredReqCase.orders, order.id, event.id, orderIndex, eventIndex, event.eventType);
                        if (oldEvent) {
                            ProductUtil.updateProductsCategoryWithLocalParams(event, oldEvent);
                        }
                    });

                    if (order.anyChangesToSendMail === true) {
                        newState.openOnSaveCase = true;
                    }
                });

                //Todos
                //local param "todo.indexPosition" will automatically deleted, as respCase is used

                //Deadline
                //api does not return "IsManual" property, so retaining isManual value of the case before updating from api response
                const reqCaseIsManual = unfilteredReqCase.deadline.isManual;
                respCase.deadline.isManual = reqCaseIsManual;
                respCase.deadline.initialDeadline = respCase.deadline.deadline;

                //Relatives
                if (AppUtil.isAvailable(respCase.relatives)) {
                    respCase.relatives.forEach((relative) => {
                        updateIsGuestOfRelative(relative);
                    })
                }

                newState.selectedCaseData = respCase;
                newState.selectedDataById = respCase;

                //Relative information for popups
                newState.caseRelativesWithEmailId = RelativeUtil.getRelativesWithEmail(respCase.relatives);

                newState.caseUpdated = true;
                newState.componentValidationErrorsInfo = {};

                //Tab counts
                newState.counter.caseRelatives = respCase.relatives.length;
                newState.counter.caseOrders = respCase.orders.length;
                newState.counter.caseNotes = respCase.notes.length;
                newState.counter.caseGuests = respCase.guests.length;
                newState.counter.caseCalls = respCase.calls.length;

                if (AppUtil.isAvailable(respCase.counter)) {
                    newState.counter.caseLogs = respCase.counter.logs;
                }

                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
                //accessUserList from case data
                newState.wsLockCaseData.assigned = respCase.assigned;
                newState.wsLockCaseData.accessUserListWithOwnerDict[respCase.id] = LockCaseUtil.getAccessUserListWithOwner(cloneDeep(respCase.accessUserList), respCase.assigned);

                // TODO: currently IDA is handling this, it should be sent from server
                respCase.pageVisibility.isMessagePageEnabled = respCase.isMessagePageEnabled;
                //Saved page visibility
                setPageVisibilityForCardId(respCase.id, respCase.pageVisibility);

                bottomCenter().success(Utility.getLang().successMessages.caseUpdated);
                return newState;
            } else {
                bottomCenter().error(respData.message);
                return state;
            }
        }
        case UPDATE_CASE_VERSION: {
            newState.caseVersionInfo.version = action.payload;
            newState.caseVersionInfo.caller = action.caller;
            return newState;
        }
        case CLEAR_CASE_RELATIVES_WITH_EMAIL_ID: {
            newState.caseRelativesWithEmailId = [];
            return newState;
        }
        case 'UPDATE_UPDATING_STATUS': {
            newState.caseUpdated = action.data;
            return newState;
        }
        case MERGED_CASE_DATA: {
            if (action.data.success === true && action.data.object !== null) {
                newState.selectedCaseData = {};
                newState.selectedDataById = {};
                newState.beforeSelectedId = "";
                newState.beforeSelectedId = action.currentCaseId;
                console.log("newState.beforeSelectedId", newState.beforeSelectedId)
                newState.mergedCaseDetails = action.data.object;
                bottomCenter().success(Utility.getLang().successMessages.caseUpdated);
            } else {
                bottomCenter().error(action.data.message);
            }
            return newState;
        }
        case CREATED_CASE_INFO: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && (respData.object !== undefined)) {
                newState.selectedCaseData = {};
                newState.selectedDataById = {};
                newState.loadingView = AppUtil.loadingStatus.hasLoaded;
                bottomCenter().success(Utility.getLang().successMessages.caseCreated);
                return newState;
            } else {
                return state;
            }
        }
        case 'NEW_FORM_FOR_RELATIVE': {
            if (newState.selectedCaseData.relatives === undefined) {
                newState.selectedCaseData = action.data;
                newState.selectedDataById = action.data;
            } else {
                newState.selectedCaseData.relatives.push(action.data);
            }
            return newState;
        }
        case UPDATE_VALIDATION_ERRORS_INFO: {
            newState.componentValidationErrorsInfo = action.payload;
            return newState;
        }
        case 'REMOVE_RELATIVE_INFO': {
            const relative = action.data;
            let newRelative = [];
            for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                let matched = false;
                if (relative.id === newState.selectedCaseData.relatives[b].id && action.indexInfo === b) {
                    matched = true
                } else if (matched === false) {
                    newRelative.push(newState.selectedCaseData.relatives[b]);
                }
            }
            newState.selectedCaseData.relatives = newRelative;

            return newState;
        }
        case 'CHANGE_REL_NAME': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    const relative = newState.selectedCaseData.relatives[b];
                    if (action.data?.id === relative.id && b === action.index) {
                        relative.firstName = action.data.firstName === "" ? null : action.data.firstName;
                        relative.lastName = action.data.lastName === "" ? null : action.data.lastName;
                    }
                    if (action.id === relative.id && b === action.index) {
                        relative.firstName = action.data?.firstName === "" ? relative.firstName : action.data?.firstName;
                        relative.lastName = action.data?.lastName === "" ? relative.lastName : action.data?.lastName;
                        relative.address.city = action.data?.city === "" ? relative.address.city : action.data?.city;
                        relative.address.postalCode = action.data?.postalCode === "" ? relative.address.postalCode : action.data?.postalCode;
                        relative.address.address2 = action.data?.address === "" ? relative.address.address2 : action.data?.address;
                        relative.organisationNumber = action?.organisationNumber === "" ? null : action?.organisationNumber;
                    }
                }
            }

            if (newState.selectedCaseData.guests !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.guests.length; b++) {
                    if (action.data.id === newState.selectedCaseData.guests[b].id) {
                        newState.selectedCaseData.guests[b].firstName = action.data.firstName === "" ? null : action.data.firstName;
                        newState.selectedCaseData.guests[b].lastName = action.data.lastName === "" ? null : action.data.lastName;
                    }
                }
            }
            return newState;
        }
        case 'CHANGE_REL_PHONE_DETAILS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].phoneNumber = action.data.phoneNumber === "" ? null : action.data.phoneNumber;
                    }
                }
            }
            if (newState.selectedCaseData.guests !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.guests.length; b++) {
                    if (action.data.id === newState.selectedCaseData.guests[b].id) {
                        newState.selectedCaseData.guests[b].phoneNumber = action.data.phoneNumber === "" ? null : action.data.phoneNumber;
                    }
                }
            }
            return newState;
        }
        case 'CHANGE_REL_MOBILE_DETAILS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].mobileNumber = action.data.mobileNumber === "" ? null : action.data.mobileNumber;
                    }
                }
            }
            if (newState.selectedCaseData.guests !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.guests.length; b++) {
                    if (action.data.id === newState.selectedCaseData.guests[b].id) {
                        newState.selectedCaseData.guests[b].mobileNumber = action.data.mobileNumber === "" ? null : action.data.mobileNumber;
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_PIN': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].personNumber = action.data.personNumber === "" ? null : action.data.personNumber;
                    }
                }
            }
            return newState;
        }
        case 'SET_ORGANISATION_NUMBER': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].organisationNumber = action.data.organisationNumber === "" ? null : action.data.organisationNumber;
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_BILLING_CONTACT_STATUS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].isBillingContact = action.data.isBillingContact;
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_IS_PRIMARY_CONTACT_STATUS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    let matched = false;
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].isPrimary = action.data.isPrimary;
                        matched = true
                    } else if (matched === false) {
                        newState.selectedCaseData.relatives[b].isPrimary = false;
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_IS_GUEST_STATUS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                let relativeWithGuestInfo = action.data;
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (relativeWithGuestInfo.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].isGuest = relativeWithGuestInfo.isGuest;
                        if (relativeWithGuestInfo.isGuest === true) {
                            RelativeUtil.handleRelativeIsAGuest(relativeWithGuestInfo, newState.selectedCaseData)
                        } else {
                            RelativeUtil.handleRelativeIsNotAGuest(relativeWithGuestInfo, newState.selectedCaseData);
                        }
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_ADDRESS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                if (action.field === "address2") {
                    for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                        if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                            if (newState.selectedCaseData.relatives[b].address !== undefined) {
                                if (newState.selectedCaseData.relatives[b].address.address2 !== undefined) {
                                    newState.selectedCaseData.relatives[b].address.address2 = action.data.address.address2 === "" ? null : action.data.address.address2;
                                }
                            }

                        }
                    }
                } else {
                    for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                        if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                            if (newState.selectedCaseData.relatives[b].address !== undefined) {
                                if (newState.selectedCaseData.relatives[b].address.address !== undefined) {
                                    newState.selectedCaseData.relatives[b].address.address = action.data.address.address === "" ? null : action.data.address.address;
                                }
                            }
                        }
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_PLACE_NAME': {
            console.log("SET_REL_PLACE_NAME")
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        if (newState.selectedCaseData.relatives[b].address !== undefined) {
                            if (newState.selectedCaseData.relatives[b].address.city !== undefined) {
                                newState.selectedCaseData.relatives[b].address.city = action.data.address.city === "" ? null : action.data.address.city;
                            }
                        }

                    }
                }
            }
            return newState;
        }
        case 'SET_REL_POSTAL_CODE': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        if (newState.selectedCaseData.relatives[b].address !== undefined) {
                            if (newState.selectedCaseData.relatives[b].address.postalCode !== undefined) {
                                newState.selectedCaseData.relatives[b].address.postalCode = action.data.address.postalCode === "" ? null : action.data.address.postalCode;
                            }
                        }

                    }
                }
            }
            return newState;
        }
        case 'SET_REL_DATE_OF_BIRTH': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].dateOfBirth = action.data.dateOfBirth === "" ? null : action.data.dateOfBirth;
                    }
                }
            }
            if (newState.selectedCaseData.guests !== undefined) {//Currently Guest DOB not present
                for (let b = 0; b < newState.selectedCaseData.guests.length; b++) {
                    if (action.data.id === newState.selectedCaseData.guests[b].id) {
                        newState.selectedCaseData.guests[b].dateOfBirth = action.data.dateOfBirth === "" ? null : action.data.dateOfBirth;
                    }
                }
            }
            return newState;
        }
        case 'CHANGE_REL_EMAIL_DETAILS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].email = action.data.email === "" ? null : action.data.email;
                    }
                }
            }
            if (newState.selectedCaseData.guests !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.guests.length; b++) {
                    if (action.data.id === newState.selectedCaseData.guests[b].id) {
                        newState.selectedCaseData.guests[b].email = action.data.email === "" ? null : action.data.email;
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_COMMENT': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].comment = action.data.comment === "" ? null : action.data.comment
                    }
                }
            }
            return newState;
        }
        case 'SET_UK_CONTACT_PROPERTIES': {
            if (newState.selectedCaseData.relatives !== undefined) {
                const dataToMatch = action.data;
                newState.selectedCaseData.relatives.forEach((relative, index) => {
                    if (dataToMatch.id === relative.id && index === action.index) {
                        if (!Array.isArray(relative.ukContactProperties)) {
                            relative.ukContactProperties = [];
                        }
                        if (Array.isArray(relative.ukContactProperties)) {
                            if (dataToMatch.isUkPropChecked) {
                                const content = {id: dataToMatch.ukPropId, isEditable: true};
                                ArrayUtil.addUniqueObject(dataToMatch.ukPropId, content, relative.ukContactProperties)
                            } else {
                                RelativeUtil.removeObject(dataToMatch.ukPropId, "id", relative, "ukContactProperties");
                            }
                            // console.log("SET_UK_CONTACT_PROPERTIES :: ukContactProperties = ", relative.ukContactProperties);
                        }
                    }
                });
            }
            return newState;
        }

        case 'SET_UKPAYMENT_METHOD': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].ukPaymentMethod = action.data.ukPaymentMethod;
                    }
                }
            }
            return newState;
        }
        case 'SET_REL_NATIONAL_INSURANCE_NUMBER': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].nationalInsuranceNumber = action.data.nationalInsuranceNumber === "" ? null : action.data.nationalInsuranceNumber;
                    }
                }
            }
            return newState;
        }

        case 'SET_REL_FINANCIAL_ASSISTANCE_DETAILS': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (action.data.id === newState.selectedCaseData.relatives[b].id && b === action.index) {
                        newState.selectedCaseData.relatives[b].financialAssistanceDetails = action.data.financialAssistanceDetails === "" ? null : action.data.financialAssistanceDetails.trim();
                    }
                }
            }
            return newState;
        }

        case SET_REL_COMPANY_NAME: {
            if (newState.selectedCaseData.relatives !== undefined) {
                const {index, data} = action;
                if (newState.selectedCaseData.relatives.length > index) {
                    const relative = newState.selectedCaseData.relatives[index];
                    if (data.id === relative.id) {
                        relative.companyName = data.companyName;
                    }
                }
            }
            return newState;
        }

        case CLEAR_UK_PAYMENT_DETAILS: {
            const {idToMatch} = action;
            const matchedIndex = ArrayUtil.matchedIndex(newState.selectedCaseData.relatives, idToMatch);
            if (matchedIndex !== NOT_FOUND) {
                const relative = newState.selectedCaseData.relatives[matchedIndex];
                //Clean-up UK payment details
                relative.ukPaymentMethod = "";
                relative.nationalInsuranceNumber = "";
                relative.financialAssistanceDetails = "";
                return newState;
            } else {
                return state;
            }
        }
        case 'SET_RELATIONSHIP_ID': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (newState.selectedCaseData.relatives[b].id === parseInt(action.data.id, 10) && b === action.index) {
                        newState.selectedCaseData.relatives[b].relationshipId = action.data.relationshipId;
                    }
                }
            }
            return newState;
        }
        case 'SET_RELATIONSHIP_ID_RELATIVE': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (newState.selectedCaseData.relatives[b].id === parseInt(action.relativeId, 10)) {
                        newState.selectedCaseData.relatives[b].relationshipId = parseInt(action.data.value, 10);
                    }
                }
            }
            return newState;
        }

        case 'SAVE_ALL_FILTER_APPLIED_DATA': {
            let name = {};
            name = action.name
            newState[name + "filter"] = action.data;
            return newState;
        }

        case REMOVE_FILTER_APPLIED: {
            newState[action.data + "filter"] = [];
            return newState;
        }
        case 'LOAD_LOADING_VIEW': {
            newState.loadingView = action.data;
            return newState;
        }
        case FILTER_APPLIED_TO_COLUMN: {
            newState[action.data] = action.data;
            return newState;
        }
        case NOTIFICATION_CASE_ID: {
            const newState = JSON.parse(JSON.stringify(state));
            newState.notificationCaseId = action.data;
            return newState;
        }
        case 'MARKED_NOTIFICATION_RESPONSE': {
            const newState = JSON.parse(JSON.stringify(state));
            return newState;
        }
        case 'SET_ESCAPE_KEY': {
            const newState = JSON.parse(JSON.stringify(state));
            if (action.data === true) {
                if (action.moveToRoute !== undefined && action.moveToRoute !== '') {
                    newState.headerMenuItemClicked = action.moveToRoute;
                    newState.showEscapeKey = false;
                } else {
                    newState.headerMenuItemClicked = AppUtil.linkPaths.convert.pathToRoute;
                    newState.showEscapeKey = action.data;
                }
            } else {
                newState.showEscapeKey = action.data;
            }

            return newState;
        }
        case 'SET_HEADER_MENU_ITEM': {
            newState.headerMenuItemClicked = AppUtil.linkPaths.case.pathToRoute;
            return newState;
        }
        case ON_ERROR_UNDER_CASE: {
            action.data.message === undefined ? bottomCenter().error(action.data) : bottomCenter().error(action.data.message);
            return newState;
        }
        case 'ON_ERROR_UNDER_CASE_ORDER': {
            action.data.message === undefined ? bottomCenter().error(action.data) : bottomCenter().error(action.data.message);
            return newState;
        }
        case 'FETCHED_ALL_DRESSCODE_TYPES': {
            if (action.data.success === true) {
                newState.allDresscodeTypes = action.data.object;
            }
            return newState;
        }
        case 'FETCHED_MEMORIAL_FUNDS': {
            if (action.data.success === true) {
                newState.memorialFunds = action.data.object;
            }
            return newState;
        }
        case 'CHANGE_PRODUCT_ITEM_FOR_OTTYPE_ONE_STATUS': {
            console.log("CHANGE_PRODUCT_ITEM_FOR_OTTYPE_ONE_STATUS", action.data);
            newState.selectedCaseData.orders.forEach((orderType, i) => {
                if (orderType.orderType === 1) {
                    orderType.events.forEach((event, j) => {
                        event.categories.forEach((category, k) => {
                            category.eventProducts.forEach((product, l) => {
                                console.log("product", product);
                                product.productId = parseInt(action.data, 10);
                            })
                        })
                    })
                }
            });
            return newState;
        }
        case 'SHOW_AND_HIDE_ON_TOGGLE_CLICK': {
            if (action.calledInfo === 'case') {
                newState.selectedCaseData.orders.forEach((orderType, i) => {
                    if (orderType.orderType === action.order && action.orderIndex === i) {
                        orderType.events.forEach((event, j) => {
                            if (event.eventType === action.event) {
                                event.categories.forEach((cate, k) => {
                                    if (cate.id === action.data.id) {
                                        console.log("cate", cate.id, action.data.id);
                                        cate.toggle === true ? (cate.toggle = false) : (cate.toggle = true)
                                    }
                                })
                            }
                        })

                    }
                });
                newState.selectedDataById.orders.forEach((orderType, i) => {
                    if (orderType.orderType === action.order) {
                        orderType.events.forEach((event, j) => {
                            if (event.eventType === action.event) {
                                event.categories.forEach((cate, k) => {
                                    if (cate.id === action.data.id) {
                                        console.log("cate", cate.id, action.data.id);
                                        cate.toggle === true ? (cate.toggle = false) : (cate.toggle = true)
                                    }
                                })
                            }
                        })

                    }
                });
            } else {
                if (newState.selectedOrderData.orderType === action.order) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        if (event.eventType === action.event) {
                            event.categories.forEach((cate, k) => {
                                if (cate.id === action.data.id) {
                                    console.log("cate", cate.id, action.data.id);
                                    cate.toggle === true ? (cate.toggle = false) : (cate.toggle = true)
                                }
                            })
                        }
                    })

                }
                if (newState.selectedOrderData === action.order) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        if (event.eventType === action.event) {
                            event.categories.forEach((cate, k) => {
                                if (cate.id === action.data.id) {
                                    console.log("cate", cate.id, action.data.id);
                                    cate.toggle === true ? (cate.toggle = false) : (cate.toggle = true)
                                }
                            })
                        }
                    })

                }
            }
            return newState
        }
        case 'ADD_NEW_PRODUCT_IN_EXISTING_PARENT_PRODUCT': {
            if (action.calledInfo === 'case') {
                addChildProductInsideParent(newState.selectedCaseData, action.data, action.orderInfo, action.eventInfo, action.calledInfo, action.orderIndex, action.product, action.productIndex)
            } else {
                addChildProductInsideParent(newState.selectedOrderData, action.data, action.orderInfo, action.eventInfo, action.calledInfo, action.orderIndex, action.product, action.productIndex)
            }
            return newState;
        }
        case 'SET_CHILD_PRODUCT_INTO_PARENT': {
            if (action.calledInfo === 'case') {
                newState.selectedCaseData.orders.forEach((orderType, i) => {
                    if (orderType.orderType === action.order && action.orderIndex === i) {
                        orderType.events.forEach((event, j) => {
                            if (event.eventType === action.event) {
                                event.categories.forEach((category, k) => {
                                    if (category.id === action.categoryInfo.id) {
                                        category.eventProducts.forEach((product, l) => {
                                            if (action.product.productId === product.productId && l === action.indexOfItem) {
                                                product.childProducts.forEach((childData, c) => {
                                                    if (childData.productId === action.child.productId && c === action.childIndex) {
                                                        console.log("SET_CHILD_PRODUCT_INTO_PARENT = child", action.child)
                                                        childData.productId = parseInt(action.data.id, 10);
                                                        childData.finalPrice = action.data.price;
                                                        childData.typeId = action.data.typeId;
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                });
                getTotalPriceForOrder(newState.selectedCaseData, action.order, action.orderIndex, action.calledInfo)
            } else {
                if (newState.selectedOrderData.orderType === action.order) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        if (event.eventType === action.event) {
                            event.categories.forEach((category, k) => {
                                if (category.id === action.categoryInfo.id) {
                                    category.eventProducts.forEach((product, l) => {
                                        if (action.product.productId === product.productId && l === action.indexOfItem) {
                                            product.childProducts.forEach((childData, c) => {
                                                if (childData.productId === action.child.productId && c === action.childIndex) {
                                                    console.log("SET_CHILD_PRODUCT_INTO_PARENT child = ", action.child)
                                                    childData.productId = parseInt(action.data.id, 10);
                                                    childData.finalPrice = action.data.price;
                                                    childData.typeId = action.data.typeId;
                                                }
                                            })
                                        }

                                    })
                                }
                            })
                        }
                    })
                }
                getTotalPriceForOrder(newState.selectedOrderData, action.order, action.orderIndex, action.calledInfo)

            }

            return newState;
        }
        case 'CREATE_PRODUCT_ITEM_FOR_CUSTOM_CATEGORY': {
            if (action.calledInfo === 'case') {
                newState.selectedCaseData.orders.forEach((orderType, i) => {
                    if (orderType.orderType === action.order && action.orderIndex === i) {
                        orderType.events.forEach((event, j) => {
                            if (event.eventType === action.event) {
                                event.categories.forEach((category, k) => {
                                    if (category.id === action.categoryInfo.id) {
                                        category.eventProducts.forEach((product, l) => {
                                            if (action.product.productId === product.productId && l === action.indexOfItem) {
                                                product.productName = action.data;
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                });
            } else {
                if (newState.selectedOrderData.orderType === action.order) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        if (event.eventType === action.event) {
                            event.categories.forEach((category, k) => {
                                if (category.id === action.categoryInfo.id) {
                                    category.eventProducts.forEach((product, l) => {
                                        if (action.product.productId === product.productId && l === action.indexOfItem) {
                                            product.productName = action.data;
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            }

            return newState;
        }
        case SET_CHILD_PRODUCT_CONFIRM_STATUS: {
            if (action.calledInfo === 'case') {
                newState.selectedCaseData.orders.forEach((orderType, i) => {
                    if (orderType.orderType === action.order && action.orderIndex === i) {
                        orderType.events.forEach((event, j) => {
                            if (event.eventType === action.event) {
                                event.categories.forEach((category, k) => {
                                    if (category.id === action.categoryInfo.id) {
                                        category.eventProducts.forEach((product, l) => {
                                            if (action.product.productId === product.productId && l === action.indexOfItem) {
                                                product.childProducts.forEach((childData, c) => {
                                                    if (action.child.productId === childData.productId && c === action.childIndex) {
                                                        childData.isConfirm = action.data;
                                                    }
                                                })
                                            }
                                        })
                                    }

                                })
                            }

                        })

                    }
                });
            } else {
                if (newState.selectedOrderData.orderType === action.order) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        if (event.eventType === action.event) {
                            event.categories.forEach((category, k) => {
                                if (category.id === action.categoryInfo.id) {
                                    category.eventProducts.forEach((product, l) => {
                                        if (action.product.productId === product.productId && l === action.indexOfItem) {
                                            product.childProducts.forEach((childData, c) => {
                                                if (action.child.productId === childData.productId && c === action.childIndex) {
                                                    childData.isConfirm = action.data;
                                                }
                                            })
                                        }
                                    })
                                }

                            })
                        }

                    })

                }
            }

            return newState;
        }
        case ON_ORDER_EVENT_CATEGORY_DISBURSEMENT_PAYMENT_SELECT: {
            if (action.functionSource === 'case') {
                newState.selectedCaseData.orders.forEach((order, i) => {
                    if (order.orderType === action.orderType && action.orderIndex === i) {
                        order.events.forEach((event, j) => {
                            ProductUtil.updateProductDisbursement(event, action.eventType, action.categoryId, action.productIndex, action.selectedDisbursementPayment, action.product);
                        })
                    }
                });
            } else {
                if (newState.selectedOrderData.orderType === action.orderType) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        ProductUtil.updateProductDisbursement(event, action.eventType, action.categoryId, action.productIndex, action.selectedDisbursementPayment, action.product);
                    })
                }
            }
            return newState;
        }
        case CHANGE_PRODUCT_ITEM_GREETING_TEXT: {
            if (action.calledInfo === 'case') {
                newState.selectedCaseData.orders.forEach((orderType, i) => {
                    if (orderType.orderType === action.order && action.orderIndex === i) {
                        orderType.events.forEach((event, j) => {
                            if (event.eventType === action.event) {
                                event.categories.forEach((category, k) => {
                                    if (category.id === action.categoryInfo.id) {
                                        category.eventProducts.forEach((product, l) => {
                                            if (action.product.productId === product.productId && l === action.indexOfItem) {
                                                product.freeText = action.data;
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                });
            } else {
                if (newState.selectedOrderData.orderType === action.order) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        if (event.eventType === action.event) {
                            event.categories.forEach((category, k) => {
                                if (category.id === action.categoryInfo.id) {
                                    category.eventProducts.forEach((product, l) => {
                                        if (action.product.productId === product.productId && l === action.indexOfItem) {
                                            product.freeText = action.data;
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            }

            return newState;
        }
        case 'FETCHED_ALL_PRODUCT_ACC_TO_CATEGORY': {
            if (action.data.success === true) {
                newState.productAccToCategory = action.data.object.products;
            }
            return newState;
        }
        case FETCHED_ORDER_JSON: {
            if (action.data.success === true) {
                const order = action.data.object;
                delete order.labels;
                delete order.notes;
                delete order.salesOrders;
                delete order.stageText;
                delete order.isFuneralOrder;
                delete order.case;
                setProductNames(order, 'order');
                newState.selectedCaseData.orders.push(order);

                //Maintain old order during unsaved order scenario and used in 'PopupForStageChange'(ie: When order type added, however, not saved). Fixes: FENIX-4876, FENIX-4900
                if (OrderUtil.isNewOrder(order.id)) {
                    const orderCopy = cloneDeep(order);
                    orderCopy.version = '';//Intentionally original order changed
                    newState.selectedDataById.orders.push(orderCopy);
                }

                //disable the stage dropdown of other funeral orders if stage is deleted
                if (order.stage !== OrderUtil.orderStages.deleted.value) {
                    if (OrderFieldRules.isFuneral(order.orderType)) {
                        OrderFieldRules.disableFuneralOrderInDeleteStage(newState.selectedCaseData.orders, true)
                    }
                    if (OrderFieldRules.isRepatriation(order.orderType)) {
                        OrderFieldRules.disableRepatriationOrderInDeleteStage(newState.selectedCaseData.orders, true)
                    }
                }
            }
            return newState;
        }
        case 'UPGRADE_FUNERAL_PACKAGE': {
            if (action.data.success === true) {
                delete action.data.object.labels;
                delete action.data.object.notes;
                delete action.data.object.salesOrders;
                delete action.data.object.stageText;
                delete action.data.object.isFuneralOrder;
                delete action.data.object.case;
                setProductNames(action.data.object, 'order');

                const categoryData = [];
                const upgradedOrderData = action.data.object;

                newState.selectedCaseData.orders.map(order => {
                    if (order.id === upgradedOrderData.id)
                        order.events.map(category => {
                            category.categories.map(data => {
                                categoryData.push(data)
                            })
                        });
                });

                const uniqueData = new Set();
                const filteredArr = categoryData.filter(el => {
                    const duplicate = uniqueData.has(el.id);
                    uniqueData.add(el.id);
                    return !duplicate;
                });

                upgradedOrderData.events.map(upgradeCategory => {
                    upgradeCategory.categories.map(newData => {
                        filteredArr.map(dataInputs => {
                            if (dataInputs.id === newData.id)
                                Object.assign(newData, {['data']: dataInputs.data});
                        });
                    })
                });
                console.log("upgradedOrderData", upgradedOrderData);
                newState.selectedCaseData.orders = newState.selectedCaseData.orders.filter((item) => item.id !== upgradedOrderData.id);
                newState.selectedDataById.orders = newState.selectedDataById.orders.filter((item) => item.id !== upgradedOrderData.id);
                newState.selectedCaseData.orders.push(upgradedOrderData);
                newState.selectedDataById.orders.push(upgradedOrderData);//update old data too

                const selectedCase = newState.selectedCaseData;
                getTotalPriceForOrder(selectedCase, action.orderType, action.orderIndex, action.orderFrom)
                getTotalPriceForOrder(newState.selectedDataById, action.orderType, action.orderIndex, action.orderFrom)
                //disable the stage dropdown of other funeral orders if stage is deleted
                let order = upgradedOrderData;
                if (order.stage !== OrderUtil.orderStages.deleted.value) {
                    if (OrderFieldRules.isFuneral(order.orderType)) {
                        OrderFieldRules.disableFuneralOrderInDeleteStage(newState.selectedCaseData.orders, true)
                    }
                    if (OrderFieldRules.isRepatriation(order.orderType)) {
                        OrderFieldRules.disableRepatriationOrderInDeleteStage(newState.selectedCaseData.orders, true)
                    }
                }
            }
            return newState;
        }
        case REMOVE_EVENT: {
            if (action.calledInfo === 'case') {
                newState.selectedCaseData.orders.forEach((order, o) => {
                    if (order.orderType === action.data) {
                        newState.selectedCaseData.orders[o].events.forEach((event, j) => {
                            if (newState.selectedCaseData.orders[o].events[j].eventType === action.eventInfo) {
                                //Delete event
                                newState.selectedCaseData.orders[o].events.splice(j, 1);
                                getTotalPriceForOrder(newState.selectedCaseData, action.data, o, action.calledInfo)
                            }
                        });
                    }
                });
            } else {
                if (newState.selectedOrderData.orderType === action.data) {
                    newState.selectedOrderData.events.forEach((event, j) => {
                        if (newState.selectedOrderData.events[j].eventType === action.eventInfo) {
                            //Delete event
                            newState.selectedOrderData.events.splice(j, 1);
                            getTotalPriceForOrder(newState.selectedOrderData, action.data, 0, action.calledInfo)
                        }
                    });
                }
            }
            return newState;
        }
        case UPDATE_OPTIONAL_EVENT: {
            if (action.data.success === true) {
                if (action.calledInfo === 'case') {
                    newState.selectedCaseData.orders.forEach((order, o) => {
                        if (order.orderType === action.order.orderType) {
                            // newState.selectedCaseData.orders[o].events.splice(action.position, 0, action.data.object)
                            //Add & empty optionalEvent
                            const order = newState.selectedCaseData.orders[o];
                            order.events.push(action.data.object);
                            if (order.optionalEvent) {
                                order.optionalEvent = null;
                            }
                        }
                    });
                } else {
                    if (newState.selectedOrderData.orderType === action.order.orderType) {
                        // newState.selectedOrderData.events.splice(action.position, 0, action.data.object)
                        //Add & empty optionalEvent
                        newState.selectedOrderData.events.push(action.data.object);
                        if (newState.selectedOrderData.optionalEvent) {
                            newState.selectedOrderData.optionalEvent = null;
                        }
                    }
                }
            }
            return newState;
        }
        case 'SHOW_POPUP_FOR_REMOVE_RELATIVE': {
            newState.openPopupForRelativeOnly = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_FUNERAL_ORDER': {
            newState.deletedOrderIndex = action.data
            newState.openFuneralPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_FUNERAL_WITHOUT_CEREMONY_ORDER': {
            newState.deletedOrderIndex = action.data
            newState.openFuneralWithoutPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_LEGAL_ORDER': {
            newState.deletedOrderIndex = action.data
            newState.openLegalOrderPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_TOMBSTONE_ORDER': {
            newState.deletedOrderIndex = action.data
            newState.openTombstoneOrderPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_TRANSPORT_ORDER': {
            newState.deletedOrderIndex = action.data
            console.log("newState.deletedOrderIndex = ", newState.deletedOrderIndex);
            newState.openTransportOrderPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_FLOWER_ORDER': {
            newState.deletedOrderIndex = action.data;
            newState.openFlowerOrderPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_ESTATE_ORDER': {
            newState.deletedOrderIndex = action.data;
            newState.openEstateOrderPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_DELETE_CUSTOM_ORDER': {
            newState.deletedOrderIndex = action.data;
            newState.openCustomOrderPopup = true;
            return newState;
        }
        case 'ASK_BEFORE_CLEANING_ORDER': {
            newState.deletedOrderIndex = action.data;
            newState.openCleaningOrderPopup = true;
            return newState;
        }
        case 'CONFIRMATION_POPUP_FOR_DELETE': {
            newState.deletedOrderData = {};
            newState.deletedOrderData.orderId = action.data;
            newState.deletedOrderData.orderIndex = action.orderIndex;
            newState.deletedOrderData.id = action.id;
            console.log("CONFIRMATION_POPUP_FOR_DELETE = ", action.data, action.orderIndex, newState.deletedOrderData);
            newState.deleteOrderPopup = true;
            return newState;
        }
        case REMOVE_ORDER: {
            const {data} = action;
            const {orderId, orderIndex, id} = data;
            newState.deletedOrderData = {};
            newState.deleteOrderPopup = false;

            const key = 'orderType';
            newState.selectedCaseData.orders = OrderUtil.removeItems(newState.selectedCaseData.orders, key, orderId, orderIndex);
            //Clear old order added during unsaved order scenario
            if (OrderUtil.isNewOrder(id)) {
                newState.selectedDataById.orders = OrderUtil.removeItems(newState.selectedDataById.orders, key, orderId, orderIndex);
            }
            OrderFieldRules.enableOrDisableFuneralOrderStageDropDown(newState.selectedCaseData.orders);
            OrderFieldRules.enableOrDisableRepatriationOrderStageDropDown(newState.selectedCaseData.orders);
            return newState;
        }
        case 'CHANGE_LOCATION_TEXT_FIELD': {
            if (action.id === "name") {
                newState.selectedLocationData.name = action.data;
            } else if (action.id === "comment") {
                newState.selectedLocationData.comment = action.data;
            } else if (action.id === "address") {
                if (AppUtil.isAvailable(newState.selectedLocationData.contactAddress)) {
                    newState.selectedLocationData.contactAddress.address = action.data;
                } else {
                    newState.selectedLocationData.contactAddress = {};
                    newState.selectedLocationData.contactAddress.address = action.data;
                }
            } else if (action.id === "postalAddress") {
                if (AppUtil.isAvailable(newState.selectedLocationData.contactAddress)) {
                    newState.selectedLocationData.contactAddress.postalAddress = action.data;
                } else {
                    newState.selectedLocationData.contactAddress = {};
                    newState.selectedLocationData.contactAddress.postalAddress = action.data;
                }
            } else if (action.id === "city") {
                if (AppUtil.isAvailable(newState.selectedLocationData.contactAddress)) {
                    newState.selectedLocationData.contactAddress.city = action.data;
                } else {
                    newState.selectedLocationData.contactAddress = {};
                    newState.selectedLocationData.contactAddress.city = action.data;
                }
            } else if (action.id === "places") {
                if (AppUtil.isAvailable(newState.selectedLocationData.contactAddress)) {
                    newState.selectedLocationData.contactAddress.placeName = action.data.name;
                    newState.selectedLocationData.contactAddress.placeId = action.data.id;
                } else {
                    newState.selectedLocationData.contactAddress = {};
                    newState.selectedLocationData.contactAddress.placeName = action.data.name;
                    newState.selectedLocationData.contactAddress.placeId = action.data.id;
                }
            } else if (action.id === "slug") {
                newState.selectedLocationData.slug = action.data;
            } else if (action.id === "seoTitle") {
                newState.selectedLocationData.seoTitle = action.data;
            } else if (action.id === "seoMetaDescription") {
                newState.selectedLocationData.seoMetaDescription = action.data;
            } else if (action.id === "typeId") {
                newState.selectedLocationData.typeId = parseInt(action.data, 10);
            } else if (action.id === "altText") {
                if (newState.selectedLocationData.locationMedias !== null) {
                    newState.selectedLocationData.locationMedias.altText = action.data;
                } else {
                    newState.selectedLocationData.locationMedias = {};
                    newState.selectedLocationData.locationMedias.altText = action.data;
                }
                newState.selectedLocationData.mediaAltText = action.data;
            } else if (action.id === "latitude") {
                if (action.data.toString().match(/[a-z]/i)) {
                    bottomCenter().warning(Utility.getLang().warningMessages.cannotSetAlphabets);
                } else {
                    newState.selectedLocationData.contactAddress.latitude = action.data;
                }
            } else if (action.id === "longitude") {
                if (action.data.toString().match(/[a-z]/i)) {
                    bottomCenter().warning(Utility.getLang().warningMessages.cannotSetAlphabets);
                } else {
                    newState.selectedLocationData.contactAddress.longitude = action.data;
                }
            } else if (action.id === "attributes") {
                for (let b = 0; b < newState.selectedLocationData.attributes.length; b++) {
                    if (action.data.id === newState.selectedLocationData.attributes[b].id) {
                        newState.selectedLocationData.attributes[b].value = action.value
                    }
                }
            } else if (action.id === "imageUrl") {
                newState.selectedLocationData.imageUrl = action.data;
            } else if (action.id === "formDataUpload") {
                newState.selectedLocationData.formDataToUpload = action.data;
            } else if (action.id === "removeadminPersonId") {
                newState.selectedLocationData.adminPerson = action.data;
                newState.selectedLocationData.adminPersonId = action.data;
            } else if (action.id === "addadminPersonId") {
                newState.selectedLocationData.adminPersonId = action.data;
            }
            return newState;
        }

        case 'UPDATE_YEAR_BUILT': {
            newState.selectedLocationData.yearBuilt = action.yearBuilt;
            return newState
        }
        case 'CHANGE_CONTACT_TEXT_FIELD': {
            if (action.id === "name") {
                newState.selectedContactData.name = action.data;
            } else if (action.id === "comment") {
                newState.selectedContactData.comment = action.data;
            } else if (action.id === "address") {
                if (newState.selectedContactData.contactAddress !== undefined && newState.selectedContactData.contactAddress !== null) {
                    newState.selectedContactData.contactAddress.address = action.data;
                } else {
                    newState.selectedContactData.contactAddress = {};
                    newState.selectedContactData.contactAddress.address = action.data;
                }

            } else if (action.id === "postalAddress") {
                if (newState.selectedContactData.contactAddress !== undefined && newState.selectedContactData.contactAddress !== null) {
                    newState.selectedContactData.contactAddress.postalAddress = action.data;
                } else {
                    newState.selectedContactData.contactAddress = {};
                    newState.selectedContactData.contactAddress.postalAddress = action.data;
                }
            } else if (action.id === "placeName") {
                if (newState.selectedContactData.contactAddress !== undefined && newState.selectedContactData.contactAddress !== null) {
                    newState.selectedContactData.contactAddress.placeName = action.data;
                } else {
                    newState.selectedContactData.contactAddress = {};
                    newState.selectedContactData.contactAddress.placeName = action.data;
                }

            } else if (action.id === "city") {
                if (newState.selectedContactData.contactAddress !== undefined && newState.selectedContactData.contactAddress !== null) {
                    newState.selectedContactData.contactAddress.city = action.data;
                } else {
                    newState.selectedContactData.contactAddress = {};
                    newState.selectedContactData.contactAddress.city = action.data;
                }

            } else if (action.id === "mailingAddress") {
                if (newState.selectedContactData.mailingAddress !== null && newState.selectedContactData.mailingAddress !== undefined) {
                    newState.selectedContactData.mailingAddress.address = action.data;
                } else {
                    newState.selectedContactData.mailingAddress = {};
                    newState.selectedContactData.mailingAddress.address = action.data;
                }

            } else if (action.id === "mailingPostalAddress") {
                if (newState.selectedContactData.mailingAddress !== null && newState.selectedContactData.mailingAddress !== undefined) {
                    newState.selectedContactData.mailingAddress.postalAddress = action.data;
                } else {
                    newState.selectedContactData.mailingAddress = {};
                    newState.selectedContactData.mailingAddress.postalAddress = action.data;
                }

            } else if (action.id === "mailingCity") {
                if (newState.selectedContactData.mailingAddress !== null && newState.selectedContactData.mailingAddress !== undefined) {
                    newState.selectedContactData.mailingAddress.city = action.data;
                } else {
                    newState.selectedContactData.mailingAddress = {};
                    newState.selectedContactData.mailingAddress.city = action.data;
                }

            } else if (action.id === "email") {
                newState.selectedContactData.email = action.data;
            } else if (action.id === "phoneNumber") {
                newState.selectedContactData.phoneNumber = action.data;
            } else if (action.id === "seoMetaDescription") {
                newState.selectedLocationData.seoMetaDescription = action.data;
            } else if (action.id === "personTypeId") {
                newState.selectedContactData.personTypeId = parseInt(action.data, 10);
            } else if (action.id === "latitude") {
                if (action.data.toString().match(/[a-z]/i)) {
                    bottomCenter().warning(Utility.getLang().warningMessages.cannotSetAlphabets);
                } else {
                    newState.selectedContactData.contactAddress.latitude = action.data;
                }
            } else if (action.id === "longitude") {
                if (action.data.toString().match(/[a-z]/i)) {
                    bottomCenter().warning(Utility.getLang().warningMessages.cannotSetAlphabets);
                } else {
                    newState.selectedContactData.contactAddress.longitude = action.data;
                }
            } else if (action.id === "imageUrl") {
                newState.selectedContactData.url = action.data;
            } else if (action.id === "formDataUpload") {
                newState.selectedLocationData.formDataToUpload = action.data;
            } else if (action.id === "removeadminPersonId") {
                newState.selectedLocationData.adminPerson = action.data;
                newState.selectedLocationData.adminPersonId = action.data;
            } else if (action.id === "addadminPersonId") {
                newState.selectedLocationData.adminPersonId = action.data;
            }
            return newState;
        }
        case CASE_DELETED_SUCCESSFULLY: {
            const {payload} = action;
            const respData = payload.data;

            newState.loadingView = AppUtil.loadingStatus.hasDeleted;
            respData.success ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(respData.message);
            return newState;
        }
        case GENERIC_DOCUMENT_DELETED_SUCCESSFULLY: {
            const {idToDelete} = action;
            const filteredArray = newState.caseDocuments.filter(document => {
                return document.id !== idToDelete
            });
            newState.caseDocuments = filteredArray;
            return newState;
        }
        case ORDER_DELETED_SUCCESSFULLY: {
            const {payload} = action;
            const respData = payload.data;

            newState.loadingView = AppUtil.loadingStatus.hasDeleted;
            respData.success ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(respData.message);
            return newState;
        }
        case CALL_DELETED_SUCCESSFULLY: {
            const {payload} = action;
            const respData = payload.data;

            respData.success ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(respData.message);
            return newState;
        }
        case DELETED_LOCATION_SUCCESSFULLY: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;

            respData.success ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(respData.message);
            return newState;
        }
        case DELETED_CONTACT_SUCCESSFULLY: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;

            respData.success ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(respData.message);
            return newState;
        }

        case 'CHANGE_PRODUCT_TEXT_FEILD': {
            if (action.id === 'name') {
                newState.selectedProductData.name = action.data;
            } else if (action.id === 'description') {
                newState.selectedProductData.description = action.data;
            } else if (action.id === 'specification') {
                newState.selectedProductData.specification = action.data;
            } else if (action.id === 'slug') {
                newState.selectedProductData.slug = action.data;
            } else if (action.id === 'seoTitle') {
                newState.selectedProductData.seoTitle = action.data;
            } else if (action.id === 'seoMetaDescription') {
                newState.selectedProductData.seoMetaDescription = action.data;
            } else if (action.id === 'price') {
                newState.selectedProductData.price = action.data;
            } else if (action.id === 'grossMargin') {
                newState.selectedProductData.grossMargin = action.data;
            } else if (action.id === 'commission') {
                newState.selectedProductData.commission = action.data;
            } else if (action.id === 'isDefaultMargin') {
                newState.selectedProductData.isDefaultMargin = action.data;
            } else if (action.id === 'isDefaultCommission') {
                newState.selectedProductData.isDefaultCommission = action.data;
            }
            return newState;
        }
        case 'SET_DROPDOWN_ITEM': {
            if (action.id === 'category') {
                if (newState.selectedProductData.category !== undefined && newState.selectedProductData.category !== null) {
                    newState.selectedProductData.category.id = isNaN(action.data) === true ? null : action.data;
                    newState.selectedProductData.category.grossMargin = action.data.grossMargin;
                    newState.selectedProductData.category.commission = action.data.commission;
                } else {
                    newState.selectedProductData.category = {
                        id: action.data
                    }
                }
            } else if (action.id === 'productType') {
                if (newState.selectedProductData.typeId !== undefined) {
                    newState.selectedProductData.typeId = isNaN(action.data) === true ? null : action.data;
                } else {
                    newState.selectedProductData.typeId = action.data;
                }
            } else if (action.id === 'makeDefault') {
                for (let n = 0; n < newState.selectedProductData.relatedProducts.length; n++) {
                    if (action.data === newState.selectedProductData.relatedProducts[n].id) {
                        newState.selectedProductData.relatedProducts[n].id = action.data;
                        newState.selectedProductData.relatedProducts[n].isDefault = true;
                    } else {
                        newState.selectedProductData.relatedProducts[n].isDefault = false;

                    }
                }
            } else if (action.id === 'availability') {
                newState.selectedProductData.productAvailability = action.data;
            } else if (action.id === 'status') {
                newState.selectedProductData.productStatus = action.data;
            }
            return newState;
        }

        case 'CHANGE_ACTIVE_STATUS_FOR_PRODUCT': {
            newState.selectedProductData.isActive = action.data;
            return newState;
        }
        case 'CHANGE_ENABLE_TEXT_VALUE_FOR_PRODUCT': {
            newState.selectedProductData.enableFreeText = action.data;
            return newState;
        }

        case CHANGE_ENABLE_DISBURSEMENT_VALUE_FOR_PRODUCT: {
            newState.selectedProductData.enableDisbursement = action.data;
            if (action.data === true) {
                newState.selectedProductData.enableFreeText = action.data;
            }
            return newState;
        }

        case 'CHANGE_ALT_TEXT': {
            for (let b = 0; b < newState.selectedProductData.productMedias.length; b++) {
                if (action.id === newState.selectedProductData.productMedias[b].id && action.index === b) {
                    newState.selectedProductData.productMedias[b].altText = action.data === "" ? null : action.data;
                }
            }

            return newState;
        }
        case 'CHANGE_IMAGE_DEFAULT_STATUS': {
            for (let b = 0; b < newState.selectedProductData.productMedias.length; b++) {
                let matched = false
                if (action.id === newState.selectedProductData.productMedias[b].id && action.index === b) {
                    matched = true
                    newState.selectedProductData.productMedias[b].isDefault = action.data;
                } else if (matched === false) {
                    newState.selectedProductData.productMedias[b].isDefault = false;
                }
            }
            return newState;
        }
        case 'REMOVE_IMAGE': {
            let temp = [];
            for (let b = 0; b < newState.selectedProductData.productMedias.length; b++) {
                let matched = false
                if (action.id === newState.selectedProductData.productMedias[b].id && action.index === b) {
                    if (newState.selectedProductData.productMedias.length === 1) {
                        newState.selectedProductData.productMedias = [];
                    }
                    matched = true
                } else if (matched === false) {
                    temp.push(newState.selectedProductData.productMedias[b])
                }
            }
            if (temp.length > 0) {
                newState.selectedProductData.productMedias = temp;
            }

            return newState;
        }
        case 'REMOVE_CHILD_FROM_PARENT': {
            let temp = [];
            for (let b = 0; b < newState.selectedProductData.relatedProducts.length; b++) {
                let matched = false
                if (action.data.id === newState.selectedProductData.relatedProducts[b].id && action.index === b) {
                    if (newState.selectedProductData.relatedProducts.length === 1) {
                        newState.selectedProductData.relatedProducts = [];
                    }
                    matched = true
                } else if (matched === false) {
                    temp.push(newState.selectedProductData.relatedProducts[b])
                }
            }
            if (temp.length > 0) {
                newState.selectedProductData.relatedProducts = temp;
            }

            return newState;
        }
        case 'ADD_CHILD_INSIDE_THE_PARENT': {
            newState.selectedProductData.relatedProducts.push(action.data);
            return newState;
        }
        case UPDATE_ORDER_DETAILS: {
            //TODO: Should use only "respOrder" instead of manually mapping oldOrder properties with "respOrder.properties"
            //Refer: UPDATE_CASE_INFO_INTO_EXISTING_CASE, UPDATE_ORDER_DETAILS_ON_SOURCE handling
            const {payload, oldPayload} = action;
            const response = payload;
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && AppUtil.isAvailable(respData.object)) {
                let respOrder = respData.object;
                let oldOrder = oldPayload;
                //Data used by Popup after Order change save
                newState.caseDetailsOnOrderChangeForEmail = {};
                newState.caseDetailsOnOrderChangeForEmail.orders = [respOrder];
                newState.caseDetailsOnOrderChangeForEmail.id = respOrder.case.id;
                newState.caseDetailsOnOrderChangeForEmail.orders[0].selectedBillingContact = [];
                respOrder.case.relatives.forEach((relative) => {
                    if (relative.id === respOrder.billingContactId) {
                        newState.caseDetailsOnOrderChangeForEmail.orders[0].selectedBillingContact.push(relative);
                    }
                });

                oldOrder.notes = respOrder.notes;
                oldOrder.labels = respOrder.labels;
                oldOrder.salesOrders = respOrder.salesOrders;
                oldOrder.deliveryMethod = respOrder.deliveryMethod;
                oldOrder.case = respOrder.case;
                oldOrder.stage = respOrder.stage;
                oldOrder.stageText = respOrder.stageText;
                oldOrder.billingContactId = respOrder.billingContactId;

                //Lock case version
                oldOrder.version = respOrder.version;

                newState.selectedOrderData = oldOrder;
                newState.selectedDataById = oldOrder;
                newState.orderUpdated = true;
                respOrder.events.forEach((event, eventIndex) => {
                    let oldEvent = oldOrder.events[eventIndex];
                    oldEvent.id = event.id;
                    updateRequestProductsCategoryWithLocalParams(event, oldEvent);
                });
                if (respOrder.anyChangesToSendMail === true) {
                    newState.openOnSaveCase = true;
                    newState.orderIdForSendingmail = respOrder.id;
                }
                newState.counter.orderNotes = respOrder.notes.length;
                //reset tempNote
                newState.tempNote = '';
                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
                bottomCenter().success(Utility.getLang().successMessages.orderUpdated);
            } else {
                respData.success ? bottomCenter().success(Utility.getLang().successMessages.deleted) : bottomCenter().error(respData.message);
            }
            // console.log("newState.updatedOrderDetails = ", newState.updatedOrderDetails);
            return newState;
        }
        //TODO: Below can be used instead of "UPDATE_ORDER_DETAILS"
        case UPDATE_ORDER_DETAILS_ON_SOURCE: {
            const {payload, unfilteredPayload, source} = action;
            const respOrder = cloneDeep(payload);
            const unfilteredReqOrder = unfilteredPayload;

            //Data used by Popup after Order change save
            newState.caseDetailsOnOrderChangeForEmail = OrderUtil.getCaseWithUpdatedBillingContact(respOrder);
            newState.orderUpdated = true;

            respOrder.events.forEach((event, eventIndex) => {
                let oldEvent = unfilteredReqOrder.events[eventIndex];
                oldEvent.id = event.id;
                updateProductsCategoryWithLocalParamsOnSource(event, oldEvent);
            });

            if (source === 'order') {
                newState.selectedOrderData = respOrder;
                newState.selectedDataById = respOrder;
            } else if (source === 'case') {
                const matchedIndex = newState.selectedCaseData.orders.findIndex(order => order.id === respOrder.id);
                if (matchedIndex !== -1) {
                    newState.selectedCaseData.orders[matchedIndex] = respOrder;
                    newState.selectedCaseData.version = respOrder.version;
                }
                const matchedInitialIndex = newState.selectedDataById.orders.findIndex(order => order.id === respOrder.id);
                if (matchedInitialIndex !== -1) {
                    newState.selectedDataById.orders[matchedIndex] = respOrder;
                    newState.selectedCaseData.version = respOrder.version;
                }
            }

            if (respOrder.anyChangesToSendMail === true) {
                newState.openOnSaveCase = true;
                newState.orderIdForSendingmail = respOrder.id;
            }
            newState.counter.orderNotes = respOrder.notes.length;
            //reset tempNote
            newState.tempNote = '';

            return newState;
        }
        case 'UPDATE_ORDER_UPDATING_STATUS': {
            newState.orderUpdated = action.data;
            return newState;
        }
        case 'UPDATE_CALL_UPDATING_STATUS': {
            newState.callUpdated = action.data;
            return newState;
        }
        case 'UPDATE_PRODUCT_UPDATING_STATUS': {
            newState.productUpdated = action.data;
            return newState;
        }
        case 'UPDATE_LOCATION_UPDATING_STATUS': {
            newState.locationUpdated = action.payload;
            return newState;
        }
        case 'UPDATE_CONTACT_UPDATING_STATUS': {
            newState.contactUpdated = action.data;
            return newState;
        }
        case 'FETCHED_ALL_STATUS_FOR_ORDER': {
            newState.orderStatus = action.data.object;
            if (action.data.success) {
                setOrderStages(action.data.object);
            }
            return newState;
        }
        case CHANGE_STAGE_FOR_ORDER_INFO: {
            if (action.source === 'case') {
                for (let b = 0; b < newState.selectedCaseData.orders.length; b++) {
                    if (action.index === b) {
                        let order = newState.selectedCaseData.orders[b];
                        order.stage = parseInt(action.data, 10);
                        if (OrderFieldRules.isFuneral(order.orderType)) {
                            if (order.stage !== OrderUtil.orderStages.deleted.value) {
                                OrderFieldRules.disableFuneralOrderInDeleteStage(newState.selectedCaseData.orders, true)
                            } else if (order.stage === OrderUtil.orderStages.deleted.value) {
                                OrderFieldRules.disableFuneralOrderInDeleteStage(newState.selectedCaseData.orders, false)
                            }
                        }
                        if (OrderFieldRules.isRepatriation(order.orderType)) {
                            if (order.stage !== OrderUtil.orderStages.deleted.value) {
                                OrderFieldRules.disableRepatriationOrderInDeleteStage(newState.selectedCaseData.orders, true)
                            } else if (order.stage === OrderUtil.orderStages.deleted.value) {
                                OrderFieldRules.disableRepatriationOrderInDeleteStage(newState.selectedCaseData.orders, false)
                            }
                        }
                    }
                }
            } else {
                newState.selectedOrderData.stage = parseInt(action.data, 10);
            }
            return newState;
        }
        case CHANGE_CEREMONY_INSTRUCTION_FOR_ORDER_INFO: {
            if (action.source === 'case') {
                for (let b = 0; b < newState.selectedCaseData.orders.length; b++) {
                    if (action.index === b) {
                        newState.selectedCaseData.orders[b].ceremonyInstructions = action.data;
                    }
                }
            } else {
                newState.selectedOrderData.ceremonyInstructions = action.data;
            }
            return newState;
        }
        case 'CHANGE_STAGE_FOR_ORDER_FROM_POPUP': {
            newState.selectedCaseData.orders.forEach((order, index) => {
                if (action.index === index) {
                    order.stage = parseInt(action.data, 10);
                }
            })
            //disable the stage dropdown of other funeral orders if stage is deleted
            OrderFieldRules.enableOrDisableFuneralOrderStageDropDown(newState.selectedCaseData.orders);
            OrderFieldRules.enableOrDisableRepatriationOrderStageDropDown(newState.selectedCaseData.orders);
            return newState;
        }
        case 'SET_ORDER_DATA_TO_SEND_FROM_POPUP': {
            newState.popupCase.orderDetails = action.data;
            newState.popupCase.owner = action.assignedUserId;

            //disable the stage dropdown of other funeral orders if stage is deleted
            OrderFieldRules.enableOrDisableFuneralOrderStageDropDown(newState.popupCase.orderDetails.order);
            OrderFieldRules.enableOrDisableRepatriationOrderStageDropDown(newState.popupCase.orderDetails.order);

            return newState;
        }
        case 'CHANGE_BILLING_CONTACT_FOR_ORDER_FROM_POPUP': {
            newState.selectedCaseData.orders.forEach((order, index) => {
                if (action.index === index) {
                    order.billingContactId = parseInt(action.data, 10);
                }
            })
            return newState;
        }
        case 'CHANGE_BILLING_CONTACT_FOR_ORDER_FROM_POPUP_ON_DRAG': {
            newState.popupCase.orderDetails.order.forEach((order, index) => {
                if (action.index === index) {
                    order.billingContactId = parseInt(action.data, 10);
                }
            });
            return newState;
        }
        case 'CHANGE_DELIVERY_METHOD_FOR_ORDER_FROM_POPUP': {
            newState.selectedCaseData.orders.forEach((order, index) => {
                if (action.index === index) {
                    order.deliveryMethod = parseInt(action.data, 10);
                }
            })
            return newState;
        }
        case 'CHANGE_DELIVERY_METHOD_FOR_ORDER_FROM_POPUP_ON_DRAG': {
            newState.popupCase.orderDetails.order.forEach((order, index) => {
                if (action.index === index) {
                    order.deliveryMethod = parseInt(action.data, 10);
                }
            })
            return newState;
        }
        case SET_CASE_ID_FOR_ORDER_TAB_ONLY: {
            newState.selectedOrderData.case = action.data;
            return newState;
        }
        case 'REGISTER_LISTENER': {
            let listenerIsRegistered = false;
            for (let i = 0; i < newState.registeredListeners.length; i++) {
                if (newState.registeredListeners[i].id === action.data.id) {
                    listenerIsRegistered = true;
                    break;
                }
            }

            if (listenerIsRegistered === false) {
                action.data.hasUpdatedItem = false;
                action.data.type = WebSocketUtil.webSocketNotificationTypes.default.value;
                newState.registeredListeners.push(action.data);
            }
            return newState;
        }
        case 'REGISTER_LISTENERS': {
            action.data.forEach(function (listener) {
                let listenerIsRegistered = false;
                for (let i = 0; i < newState.registeredListeners.length; i++) {
                    if (newState.registeredListeners[i].id === listener.id) {
                        listenerIsRegistered = true;
                        break;
                    }
                }

                if (listenerIsRegistered === false) {
                    listener.hasUpdatedItem = false;
                    listener.type = WebSocketUtil.webSocketNotificationTypes.default.value;
                    newState.registeredListeners.push(listener);
                }
            });
            return newState;
        }
        case 'UNREGISTER_LISTENER': {
            const matchedListenerIndex = newState.registeredListeners.findIndex((listener) => {
                return listener.id === action.data.id;
            });

            if (matchedListenerIndex !== -1) {//Found
                newState.registeredListeners.splice(matchedListenerIndex, 1);
            }
            return newState;
        }
        case UPDATED_ITEM: {
            const resp = action.data;
            // Only indicate new items added by other users than the logged in user
            if (getFenixUserId() !== resp.initiatedById) {
                newState.registeredListeners.forEach(function (listener) {
                    if (listener.hasUpdatedItem === false) {
                        for (let i = 0; i < listener.hooks.length; i++) {
                            if (listener.hooks[i].types.includes(resp.type) && listener.hooks[i].stages.includes(resp.stage)) {
                                newState.wsLockCaseData.reloadToSeeLatestBanner = resp;
                                listener.type = resp.type;
                                //FYI: Accessing  "columnReducer" state in this reducer
                                const columnPageDataMapper = store.getState().columnReducer.columnPageDataMapper;
                                listener.hasUpdatedItem = WebSocketUtil.getHasUpdatedItemsStatus(listener, resp, columnPageDataMapper[resp.stage], listener.id);
                                break;
                            }
                        }
                    }
                });

                if (WebSocketUtil.webSocketTypesForSemiTransparentCardUI.includes(resp.type)) {
                    newState.wsLockCaseData.semiTransparentCard = resp;
                }

                if (WebSocketUtil.webSocketTypesForDataOutDatedBanner.includes(resp.type)) {
                    newState.wsLockCaseData.outdatedBanner = resp;
                }
            }
            //Takeover "initiatedById" are same
            if (WebSocketUtil.webSocketTypesForCurrentEditorAndTakeOverBanner.includes(resp.type)) {
                newState.wsLockCaseData.currentEditorAndTakeOverBanner = resp;
                //accessUserList from WS
                newState.wsLockCaseData.accessUserListWithOwnerDict[resp.value] = LockCaseUtil.getAccessUserListWithOwner(cloneDeep(resp.accessUserList), newState.wsLockCaseData.assigned);
            }
            return newState;
        }
        case UPDATE_LOCK_CASE_EDITOR: {
            let accessUserListWithOwner = newState.wsLockCaseData.accessUserListWithOwnerDict[action.key];
            AppUtil.isAvailable(accessUserListWithOwner) && accessUserListWithOwner.forEach((accessUser) => {
                if (accessUser.userId === action.userId) {
                    accessUser.accessMode = UserAccessType.editing.value;
                } else if (accessUser.accessMode === UserAccessType.editing.value) {
                    accessUser.accessMode = UserAccessType.viewing.value;
                }
            });
            return newState;
        }
        case CLEAR_LOCK_CASE_ACCESS_USER_LIST: {
            newState.wsLockCaseData.assigned = [];
            newState.wsLockCaseData.accessUserListWithOwnerDict = {}
            return newState;
        }
        case CLEAR_WS_SEMITRANSPARENT_CARD: {
            newState.wsLockCaseData.semiTransparentCard = {};
            return newState;
        }
        case CLEAR_WS_OUTDATED_BANNER: {
            newState.wsLockCaseData.outdatedBanner = {};
            return newState;
        }
        case CLEAR_WS_CURRENT_EDITOR_AND_TAKEOVER_BANNER: {
            newState.wsLockCaseData.currentEditorAndTakeOverBanner = {};
            return newState;
        }
        case CLEAR_WS_RELOAD_TO_SEE_LATEST_BANNER: {
            newState.wsLockCaseData.reloadToSeeLatestBanner = {};
            return newState;
        }
        case 'CLEAR_LISTENER': {
            for (let i = 0; i < newState.registeredListeners.length; i++) {
                if (newState.registeredListeners[i].id === action.data) {
                    newState.registeredListeners[i].hasUpdatedItem = false;
                    newState.registeredListeners[i].type = WebSocketUtil.webSocketNotificationTypes.default.value;
                    break;
                }
            }
            return newState;
        }
        case UPDATE_CASE_CARDS_ACTIVE_CALL: {
            switch (action.data.type) {
                case WebSocketUtil.webSocketNotificationTypes.updateCallStart.value: {
                    CallUtil.updateOnGoingCalls(CRUDOperation.CREATE, newState.casesWithOngoingCalls, action.data.call, action.data.call.caseId);
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.updateCallEnd.value: {
                    CallUtil.updateOnGoingCalls(CRUDOperation.DELETE, newState.casesWithOngoingCalls, null, action.data.call.caseId);
                    newState.callsThatHaveEnded.push(action.data.call.id);
                    break;
                }
                default: {
                    console.log("Unhandled UPDATE_CASE_CARDS_ACTIVE_CALL with action = '", action.data.type, "'");
                }
            }
            return newState;
        }
        case UPDATE_ACTIVE_CALL_LIST: {
            newState.newActiveCallDetails = action.data;
            return newState;
        }
        case 'NEW_NOTIFICATIONS_ALERT': {
            newState.showNotificationPanel === false ? newState.newNotificationAlert = action.data : newState.newNotificationAlert = false;
            return newState;
        }
        case 'ADD_LABEL_TO_STATE_FOR_ORDER': {
            let allLable = [];
            newState.selectedOrderData.labels.push(action.data);
            for (let n = 0; n < newState.caseAllLabels.length; n++) {
                let isMatched = false;
                for (let b = 0; b < newState.selectedOrderData.labels.length; b++) {
                    console.log("Check = ", newState.caseAllLabels[n].id, newState.selectedOrderData.labels[b].id);

                    if (newState.caseAllLabels[n].id === newState.selectedOrderData.labels[b].id) {
                        isMatched = true;
                        console.log("Matched = ", newState.caseAllLabels[n].id, newState.selectedOrderData.labels[b].id);
                        break
                    }
                }
                if (isMatched === false) {
                    console.log("NOT Matched = ", newState.caseAllLabels[n]);
                    allLable.push(newState.caseAllLabels[n])
                }

            }
            newState.caseAllLabels = [];
            newState.caseAllLabels = allLable;
            return newState;
        }
        case 'REMOVE_LABEL_FROM_ORDER': {
            let newLabelList = [];
            newState.caseAllLabels.push(action.data);
            for (let z = 0; z < newState.selectedOrderData.labels.length; z++) {
                let match = false;
                if (action.data.id === newState.selectedOrderData.labels[z].id && action.index === z) {
                    if (newState.selectedOrderData.labels.length === 1) {
                        newState.selectedOrderData.labels = [];
                    }
                    match = true
                } else if (match === false) {
                    newLabelList.push(newState.selectedOrderData.labels[z])
                }
            }
            if (newLabelList.length > 0) {
                newState.selectedOrderData.labels = newLabelList;
            }
            return newState;
        }
        case 'ADD_LABEL_TO_STATE_FOR_CALL': {
            let allLable = [];
            newState.selectedCallData.labels.push(action.data);
            for (let n = 0; n < newState.caseAllLabels.length; n++) {
                let isMatched = false;
                for (let b = 0; b < newState.selectedCallData.labels.length; b++) {
                    console.log("Check = ", newState.caseAllLabels[n].id, newState.selectedCallData.labels[b].id);

                    if (newState.caseAllLabels[n].id === newState.selectedCallData.labels[b].id) {
                        isMatched = true;
                        console.log("Matched = ", newState.caseAllLabels[n].id, newState.selectedCallData.labels[b].id);
                        break
                    }
                }
                if (isMatched === false) {
                    console.log("NOT Matched = ", newState.caseAllLabels[n]);
                    allLable.push(newState.caseAllLabels[n])
                }

            }
            newState.caseAllLabels = [];
            newState.caseAllLabels = allLable;
            return newState;
        }
        case 'REMOVE_LABEL_FROM_CALL': {
            let newLabelList = [];
            newState.caseAllLabels.push(action.data);
            for (let z = 0; z < newState.selectedCallData.labels.length; z++) {
                let match = false;
                if (action.data.id === newState.selectedCallData.labels[z].id && action.index === z) {
                    if (newState.selectedCallData.labels.length === 1) {
                        newState.selectedCallData.labels = [];
                    }
                    match = true
                } else if (match === false) {
                    newLabelList.push(newState.selectedCallData.labels[z])
                }
            }
            if (newLabelList.length > 0) {
                newState.selectedCallData.labels = newLabelList;
            }
            return newState;
        }
        case CHANGE_BILLING_STATUS_FOR_ORDER_INFO: {
            if (action.data !== Utility.getLang().common.dropDownOptionPleaseSelect) {
                newState.selectedOrderData.billingContactId = parseInt(action.data, 10)
            } else {
                newState.selectedOrderData.billingContactId = null
            }

            return newState;
        }
        case 'SET_CASE_ID_FOR_CALL_TAB_ONLY': {
            newState.selectedCallData.caseId = action.data.id;
            return newState;
        }

        case 'OPEN_SEARCH_WITH_TEXT': {
            newState.locationSearchedSimpleResponse = [];
            newState.inputItem = action.data;
            newState.globalSearchPanel.inputItem = action.data;
            newState.globalSearchPanel.show = true;
            newState.showSearchPanel = true;
            newState.triggerSearch = true;
            newState.highLightIconSearch = AppUtil.linkPaths.search.pathToRoute;
            for (let b = 0; b < newState.parentSearchResponse.length; b++) {
                newState.parentSearchResponse[b].data = [];
                newState.parentSearchResponse[b].nextNavigation = "";
                newState.parentSearchResponse[b].toggleClick = "";
                newState.parentSearchResponse[b].count = 0;
            }
            return newState;
        }
        case 'CLEAR_TRIGGER_SEARCH': {
            newState.triggerSearch = false;
            return newState;
        }
        case UPDATED_CALL_DATA: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && (respData.object !== undefined)) {
                const respCall = respData.object;
                //reset tempNote
                newState.tempNote = '';
                bottomCenter().success(Utility.getLang().successMessages.callUpdated);
                action.oldPayload.notes = respCall.notes;
                action.oldPayload.labels = respCall.labels;
                action.oldPayload.callTakenBy = respCall.callTakenBy;
                action.oldPayload.callerId = respCall.callerId;
                action.oldPayload.callerName = respCall.callerName;
                action.oldPayload.caseId = respCall.caseId;
                action.oldPayload.ctmId = respCall.ctmId;
                action.oldPayload.deceasedName = respCall.deceasedName;
                newState.selectedCallData = action.oldPayload;
                newState.selectedDataById = action.oldPayload;
                newState.callUpdated = true;
                newState.loadingView = AppUtil.loadingStatus.hasUpdated;
                newState.counter.callNotes = respCall.notes.length;
            } else {
                bottomCenter().error(respData.message);
            }
            return newState;
        }
        case CREATE_NOTES_CONTENT: {
            if (newState.selectedCaseData.notes !== undefined) {
                newState.selectedCaseData.notes.unshift(action.data);
            }
            return newState;
        }
        case 'SAVE_TEMP_NOTE': {
            newState.tempNote = action.data;
            return newState;
        }
        case 'SAVE_CASE_TEMP_NOTE': {
            newState.selectedCaseData.tempNote = action.data;
            return newState;
        }
        case 'REMOVE_NOTES_ITEM': {
            removeNotes(newState.selectedCaseData, action.data, action.index)
            return newState;
        }
        case 'CREATE_NOTES_FOR_CALL': {
            if (newState.selectedCallData.notes !== undefined) {
                newState.selectedCallData.notes.unshift(action.data);
            }
            return newState;
        }
        case 'CREATE_NOTES_FOR_ORDER': {
            if (newState.selectedOrderData.notes !== undefined) {
                newState.selectedOrderData.notes.unshift(action.data);
            }
            return newState;
        }
        case 'REMOVE_NOTES_ITEM_FROM_CALL': {
            removeNotes(newState.selectedCallData, action.data, action.index);
            return newState;
        }
        case 'REMOVE_NOTES_ITEM_FROM_ORDER': {
            removeNotes(newState.selectedOrderData, action.data, action.index);
            return newState;
        }
        case 'SET_CALLER_INFO': {
            newState.selectedCallData.callerName = action.data.label;
            newState.selectedCallData.callerId = parseInt(action.data.value, 10);
            return newState;
        }
        case 'SET_CALLER_INFO_FOR_CASE': {
            console.log("SET_CALLER_INFO_FOR_CASE", action.data, action.callId, action.index)
            for (let b = 0; b < newState.selectedCaseData.calls.length; b++) {
                if (action.callId === newState.selectedCaseData.calls[b].id && action.index === b) {
                    newState.selectedCaseData.calls[b].callerId = parseInt(action.data.value, 10);
                    newState.selectedCaseData.calls[b].callerName = action.data.label
                }
            }
            return newState;
        }

        case 'SET_CALLER_LOCATION_FOR_CASE': {
            for (let b = 0; b < newState.selectedCaseData.calls.length; b++) {
                if (parseInt(action.callId, 10) === newState.selectedCaseData.calls[b].id) {
                    console.log("test id", action.callId, newState.selectedCaseData.calls[b].id);
                    newState.selectedCaseData.calls[b].location = action.data;
                }
            }
            return newState;
        }
        case 'SET_CALLER_CONTACT_FOR_CASE': {
            for (let b = 0; b < newState.selectedCaseData.calls.length; b++) {
                if (parseInt(action.callId, 10) === newState.selectedCaseData.calls[b].id) {
                    newState.selectedCaseData.calls[b].callerNumber = action.data;
                }
            }
            return newState;
        }

        case 'SET_CALLER_LOCATION_FOR_CALL': {
            newState.selectedCallData.location = action.data;
            return newState;
        }
        case 'SET_CALLER_CONTACT_FOR_CALL': {
            newState.selectedCallData.callerNumber = action.data;
            return newState;
        }
        case 'SET_CALL_DETAILS': {
            newState.selectedCaseData.calls.push(action.data);
            return newState;
        }
        case 'REMOVE_CALL_FROM_CASE': {
            if (newState.selectedCaseData !== undefined && newState.selectedCaseData.calls !== undefined) {
                let calls = [];
                for (let b = 0; b < newState.selectedCaseData.calls.length; b++) {
                    let matched = false;
                    if (action.data.id === newState.selectedCaseData.calls[b].id && action.index === b) {
                        matched = true
                    } else if (matched === false) {
                        calls.push(newState.selectedCaseData.calls[b]);
                    }
                }
                newState.selectedCaseData.calls = calls;
            }
            return newState;
        }
        case 'FETCHED_RELATIVE': {
            newState.relatives = [];
            let temp = [];
            if (action.data.success === true) {
                temp = action.data.object
            }
            console.log("newState.relatives = ", newState.relatives);
            if (temp.length > 0) {
                temp.map((childData) => {
                    return newState.relatives.push({
                        value: childData.id.toString(),
                        label: childData.name,
                    });
                })
            }
            return newState;
        }
        case 'CLEAR_RELATIVE': {
            newState.relatives = [];
            return newState;
        }
        case 'START_LOADER': {
            for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.isLoading
            }
            return newState;
        }
        case 'STOP_LOADER': {
            for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
            }
            return newState;
        }
        case 'FETCHED_PRODUCT_STATUS': {
            if (action.data.success === true && action.data.object !== undefined) {
                if (action.data.object.products.length > 0) {
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.product) {
                            newState.parentSearchResponse[m].data = newState.parentSearchResponse[m].data.concat(action.data.object.products)
                            newState.parentSearchResponse[m].toggleClick = "is-active";
                            newState.parentSearchResponse[m].count = action.data.object.count;
                            if (action.data.object.count > 1) {
                                newState.parentSearchResponse[m].name = Utility.getLang().search.productPlural
                            }
                            newState.parentSearchResponse[m].nextNavigation = action.data.object.navigation.next;
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                        }
                    }
                } else {
                    let newA = [];
                    let newB = [];
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.product && newState.parentSearchResponse[m].count === 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded;
                            newA.push(newState.parentSearchResponse[m])
                        } else if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.product && newState.parentSearchResponse[m].count !== 0) {
                            newState.parentSearchResponse[m].nextNavigation = "";
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                        } else {
                            newB.push(newState.parentSearchResponse[m])
                        }
                    }
                    if (newA.length > 0 && newB.length > 0) {
                        newState.parentSearchResponse = newB.concat(newA)
                    }
                }

            }
            return newState;
        }
        case 'FETCHED_CASE_STATUS': {
            const responseObj = action.data.object;
            if (action.data.success === true && responseObj !== undefined) {
                if (responseObj.cases.length > 0) {
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.case) {
                            newState.parentSearchResponse[m].data = newState.parentSearchResponse[m].data.concat(responseObj.cases)
                            newState.parentSearchResponse[m].toggleClick = "is-active";
                            newState.parentSearchResponse[m].count = responseObj.count;
                            if (responseObj.count > 1) {
                                newState.parentSearchResponse[m].name = Utility.getLang().search.casePlural
                            }
                            newState.parentSearchResponse[m].nextNavigation = responseObj.navigation.next;
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                        }
                    }
                } else {
                    let newA = [];
                    let newB = [];
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.case && newState.parentSearchResponse[m].count === 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                            newA.push(newState.parentSearchResponse[m])
                        } else if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.case && newState.parentSearchResponse[m].count !== 0) {
                            newState.parentSearchResponse[m].nextNavigation = "";
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                        } else {
                            newB.push(newState.parentSearchResponse[m])
                        }
                    }
                    if (newA.length > 0 && newB.length > 0) {
                        newState.parentSearchResponse = newB.concat(newA)
                    }
                }
            }
            return newState;
        }
        case 'FETCHED_LOCATION_STATUS': {
            if (action.data.success === true && action.data.object !== undefined) {
                if (action.data.object.locations.length > 0) {
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.location) {
                            newState.parentSearchResponse[m].data = newState.parentSearchResponse[m].data.concat(action.data.object.locations);
                            newState.parentSearchResponse[m].toggleClick = "is-active";
                            newState.parentSearchResponse[m].count = action.data.object.count;
                            if (action.data.object.count > 1) {
                                newState.parentSearchResponse[m].name = Utility.getLang().search.locationPlural
                            }
                            newState.parentSearchResponse[m].nextNavigation = action.data.object.navigation.next;
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                        }
                    }
                } else {
                    let newA = [];
                    let newB = [];
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.location && newState.parentSearchResponse[m].count === 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                            newA.push(newState.parentSearchResponse[m])
                        } else if (newState.parentSearchResponse[m].id === 5 && newState.parentSearchResponse[m].count !== 0) {
                            newState.parentSearchResponse[m].nextNavigation = "";
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded;
                        } else {
                            newB.push(newState.parentSearchResponse[m])
                        }
                    }
                    if (newA.length > 0 && newB.length > 0) {
                        newState.parentSearchResponse = newB.concat(newA)
                    }
                }
            }
            return newState;
        }
        case 'FETCHED_SIMPLE_LOCATION_STATUS': {
            if (action.data.success === true && action.data.object !== undefined && action.data.object.locations.length > 0) {
                newState.locationSearchedSimpleResponse = action.data.object.locations;
            }
            return newState;
        }
        case 'FETCHED_ORDER_STATUS': {
            if (action.data.success === true && action.data.object !== undefined) {
                if (action.data.object.orders.length > 0) {
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.order) {
                            newState.parentSearchResponse[m].data = newState.parentSearchResponse[m].data.concat(action.data.object.orders)
                            newState.parentSearchResponse[m].toggleClick = "is-active";
                            newState.parentSearchResponse[m].count = action.data.object.count;
                            if (action.data.object.count > 1) {
                                newState.parentSearchResponse[m].name = Utility.getLang().search.orderPlural
                            }
                            newState.parentSearchResponse[m].nextNavigation = action.data.object.navigation.next;
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded;
                        }
                    }
                } else {
                    let newA = [];
                    let newB = [];
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.order && newState.parentSearchResponse[m].count === 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                            newA.push(newState.parentSearchResponse[m])
                        } else if (newState.parentSearchResponse[m].id === 2 && newState.parentSearchResponse[m].count !== 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded;
                            newState.parentSearchResponse[m].nextNavigation = ""
                        } else {
                            newB.push(newState.parentSearchResponse[m])
                        }
                    }
                    if (newA.length > 0 && newB.length > 0) {
                        newState.parentSearchResponse = newB.concat(newA)
                    }
                }
            }
            return newState;
        }
        case 'FETCHED_CASE_CALLS_STATUS': {
            if (action.data.success === true && action.data.object !== undefined) {
                if (action.data.object.calls.length > 0) {
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.call) {
                            newState.parentSearchResponse[m].data = newState.parentSearchResponse[m].data.concat(action.data.object.calls);
                            newState.parentSearchResponse[m].toggleClick = "is-active";
                            newState.parentSearchResponse[m].count = action.data.object.count;
                            if (action.data.object.count > 1) {
                                newState.parentSearchResponse[m].name = Utility.getLang().search.callPlural
                            }
                            newState.parentSearchResponse[m].nextNavigation = action.data.object.navigation.next
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                        }
                    }
                } else {
                    let newA = [];
                    let newB = [];
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.call && newState.parentSearchResponse[m].count === 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                            newA.push(newState.parentSearchResponse[m])
                        } else if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.call && newState.parentSearchResponse[m].count !== 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                            newState.parentSearchResponse[m].nextNavigation = ""
                        } else {
                            newB.push(newState.parentSearchResponse[m])
                        }
                    }
                    if (newA.length > 0 && newB.length > 0) {
                        newState.parentSearchResponse = newB.concat(newA)
                    }
                }
            }
            return newState;
        }
        case 'FETCHED_CONTACTS_STATUS': {
            if (action.data.success === true && action.data.object !== undefined) {
                if (action.data.object.persons.length > 0) {
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.contact) {
                            newState.parentSearchResponse[m].data = newState.parentSearchResponse[m].data.concat(action.data.object.persons)
                            newState.parentSearchResponse[m].toggleClick = "is-active";
                            newState.parentSearchResponse[m].count = action.data.object.count;
                            if (action.data.object.count > 1) {
                                newState.parentSearchResponse[m].name = Utility.getLang().search.contactPlural
                            }
                            newState.parentSearchResponse[m].nextNavigation = action.data.object.navigation.next;
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded
                        }
                    }
                } else {
                    let newA = [];
                    let newB = [];
                    for (let m = 0; m < newState.parentSearchResponse.length; m++) {
                        if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.contact && newState.parentSearchResponse[m].count === 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded;
                            newA.push(newState.parentSearchResponse[m])
                        } else if (newState.parentSearchResponse[m].id === SearchUtil.searchCategory.contact && newState.parentSearchResponse[m].count !== 0) {
                            newState.parentSearchResponse[m].loaderStatus = AppUtil.loadingStatus.hasLoaded;
                            newState.parentSearchResponse[m].nextNavigation = ""
                        } else {
                            newB.push(newState.parentSearchResponse[m])
                        }
                    }
                    if (newA.length > 0 && newB.length > 0) {
                        newState.parentSearchResponse = newB.concat(newA)
                    }
                }

            }
            return newState;
        }
        case 'SET_TOGGLE_SHOW_STATUS': {
            if (action.data === 'map') {
            } else {
                for (let k = 0; k < newState.parentSearchResponse.length; k++) {
                    if (newState.parentSearchResponse[k].id === action.data) {
                        newState.parentSearchResponse[k].toggleClick === "" ? (newState.parentSearchResponse[k].toggleClick = "is-active") : (newState.parentSearchResponse[k].toggleClick = "")
                    }
                }
            }
            return newState;
        }

        case SET_CARD_SELECTED_FROM_GLOBAL_SEARCH: {
            // console.log("SET_CARD_SELECTED_FROM_GLOBAL_SEARCH", action.data);
            newState.cardSelectedFromGlobalSearch = action.data;
            return newState;
        }

        case 'CLEAR_DATA_FOR_SEARCH': {
            newState.locationSearchedSimpleResponse = [];
            newState.triggerSearch = false;
            for (let b = 0; b < newState.parentSearchResponse.length; b++) {
                newState.parentSearchResponse[b].data = [];
                newState.parentSearchResponse[b].nextNavigation = "";
                newState.parentSearchResponse[b].toggleClick = "";
                newState.parentSearchResponse[b].count = 0;
            }

            return newState;
        }

        case 'SET_INPUT_TEXT': {
            newState.inputItem = action.data;
            newState.globalSearchPanel.inputItem = action.data;
            return newState;
        }
        case 'SET_SELECTED_SEARCH_OPTION': {
            newState.globalSearchPanel.selectedSearchOption = action.data;
            return newState;
        }
        case FETCHED_ALL_CATEGORY_FOR_ANONYMOUS: {
            if (action.data.success === true) {
                for (let m = 0; m < action.data.object.length; m++) {
                    action.data.object[m].isConfirm = false
                }
                newState.categoryAccToEvent16 = action.data.object;
                if (action.functionSource === 'case') {//TODO: Check object.length
                    displayCategoryDataForAnonymous(action.data.object[0], OrderUtil.eventTypes.anonymous.value, newState.selectedCaseData, action.functionSource)
                    displayCategoryDataForAnonymous(action.data.object[0], OrderUtil.eventTypes.anonymous.value, newState.selectedDataById, action.functionSource)
                } else {
                    displayCategoryDataForAnonymous(action.data.object[0], OrderUtil.eventTypes.anonymous.value, newState.selectedOrderData, action.functionSource)
                    displayCategoryDataForAnonymous(action.data.object[0], OrderUtil.eventTypes.anonymous.value, newState.selectedDataById, action.functionSource)
                }
            }
            return newState;
        }
        case CHANGE_BILLING_STATUS_FOR_CASE_ORDER: {
            let relativeDeletableId = null;
            let oldRelativeDeletableId = null;
            let oldRelativeDeletable = true;
            let relativeDeletable = true;
            for (let b = 0; b < newState.selectedCaseData.orders.length; b++) {
                if (action.order === newState.selectedCaseData.orders[b].orderType && action.orderIndex === b) {
                    if (action.data === Utility.getLang().common.dropDownOptionPleaseSelect) {
                        oldRelativeDeletableId = newState.selectedCaseData.orders[b].billingContactId;
                        newState.selectedCaseData.orders[b].billingContactId = null;
                    } else {
                        oldRelativeDeletableId = newState.selectedCaseData.orders[b].billingContactId;
                        newState.selectedCaseData.orders[b].billingContactId = parseInt(action.data, 10);
                        relativeDeletableId = parseInt(action.data, 10);
                        relativeDeletable = false;
                    }

                }
            }
            newState.selectedCaseData.orders.forEach((order) => {
                if (order.billingContactId !== null && oldRelativeDeletableId !== null && order.billingContactId === oldRelativeDeletableId) {
                    oldRelativeDeletable = false;
                }
            });

            newState.selectedCaseData.relatives.forEach((relative) => {
                if (relative.id === relativeDeletableId) {
                    relative.isDeletable = relativeDeletable;
                }

                if (relative.id === oldRelativeDeletableId) {
                    relative.isDeletable = oldRelativeDeletable;
                }
            });

            return newState;
        }
        case GET_PRODUCT_TOTAL_PRICE: {
            if (action.calledInfo === 'case') {
                getTotalPriceForOrder(newState.selectedCaseData, action.order, action.orderIndex, action.calledInfo)
                getTotalPriceForOrder(newState.selectedDataById, action.order, action.orderIndex, action.calledInfo)
            } else {
                getTotalPriceForOrder(newState.selectedOrderData, action.order, action.orderIndex, action.calledInfo)
                getTotalPriceForOrder(newState.selectedDataById, action.order, action.orderIndex, action.calledInfo)
            }
            return newState;
        }
        case 'SHOW_DISPLAY_NOTHING_COMPONENT': {
            newState.displayNothing = action.data;
            return newState;
        }
        case 'CLEAR_ALL_COUNTS': {
            newState.counter.caseRelatives = 0;
            newState.counter.caseOrders = 0;
            newState.counter.caseNotes = 0;
            newState.counter.caseGuests = 0;
            newState.counter.caseCalls = 0;
            newState.counter.orderNotes = 0;
            newState.counter.orderLogs = 0;
            newState.counter.caseLogs = 0;
            newState.counter.documents = 0;
            newState.counter.reportedMemoriesCount = 0;
            newState.counter.orderLogs = 0;
            newState.counter.callNotes = 0;
            newState.counter.callLogs = 0;
            return newState;
        }

        case SET_ORDER_BILLING_STATUS: {
            if (newState.selectedOrderData.salesOrders !== undefined && newState.selectedOrderData.salesOrders.length > 0) {
                const {data, status} = action;
                for (let s = 0; s < newState.selectedOrderData.salesOrders.length; s++) {
                    const selectedSalesOrder = newState.selectedOrderData.salesOrders[s];
                    if (data.id === selectedSalesOrder.id) {
                        selectedSalesOrder.status = status;
                        //Updating pausedDate with pause btn action
                        selectedSalesOrder.pausedUntilDate = data.nextDate;
                        selectedSalesOrder.pausedUntilDateText = AppUtil.isAvailable(data.nextDate) ? DateUtilExt.yearString(data.nextDate) : "";
                    }
                }
            }
            return newState;
        }
        case 'UPDATED_PRODUCT_CATEGORY': {
            newState.copyselectedProductCategory = {};
            newState.selectedProductCategory = {};
            newState.hasCardInfoChanged = false;
            return newState
        }
        case 'CHANGE_GUEST_DETAILS': {
            newState.selectedCaseData.guests.forEach((guest, i) => {
                if (i === action.index) {
                    if (action.field === 'firstName') {
                        guest.firstName = action.data;
                    } else if (action.field === 'lastName') {
                        guest.lastName = action.data;
                    } else if (action.field === 'additionalGuests') {
                        if (guest.rsvp === CaseUtil.rsvp.yes) {
                            const newAdditionalGuests = parseInt(action.data, 10);
                            GuestUtil.updateRSVPYesAdditionalGuestsOfGuestCountInfo(newState.selectedCaseData.guestCountInfo, guest, newAdditionalGuests);
                        }
                        guest.additionalGuests = parseInt(action.data, 10);
                    } else if (action.field === 'email') {
                        guest.email = action.data;
                    } else if (action.field === 'phoneNumber') {
                        guest.phoneNumber = action.data;
                    } else if (action.field === 'rsvp') {
                        const fromRsvp = guest.rsvp;
                        const toRsvp = action.data;
                        if (GuestUtil.rsvpYesToOther(fromRsvp, toRsvp) || GuestUtil.rsvpOtherToYes(fromRsvp, toRsvp)) {
                            GuestUtil.updateAllRSVPYesOfGuestCountInfo(newState.selectedCaseData.guestCountInfo, guest, GuestUtil.getGuestActionOnRsvpForUpdatingGuestCountInfo(guest, toRsvp));
                        }

                        guest.rsvp = action.data;

                    } else if (action.field === 'isActive') {

                        if (guest.rsvp === CaseUtil.rsvp.yes) {
                            GuestUtil.updateAllRSVPYesOfGuestCountInfo(newState.selectedCaseData.guestCountInfo, guest, GuestUtil.guestAction.delete);
                        }

                        guest.isActive = action.data;

                        if (guest.id !== null) {
                            for (let i = 0; i < newState.selectedCaseData.relatives.length; i++) {
                                if (guest.id === newState.selectedCaseData.relatives[i].id) {
                                    if (guest.id !== 0) {
                                        newState.selectedCaseData.relatives[i].isGuest = false;
                                    } else {
                                        if (guest.email === newState.selectedCaseData.relatives[i].email) {
                                            newState.selectedCaseData.relatives[i].isGuest = false;
                                        }
                                    }

                                }
                            }
                        }
                        if (guest.id === 0) {
                            newState.selectedCaseData.guests.splice(i, 1);
                        }
                    } else if (action.field === 'isConfirmationMailSent') {
                        guest.isConfirmationMailSent = action.data;
                    }
                }
            });
            return newState
        }
        case 'CHANGE_GUEST_FOOD_RESTRICTION_DETAILS': {
            newState.selectedCaseData.guests.forEach((guest, i) => {
                if (i === action.index) {
                    newState.selectedCaseData.guests[i].foodRestrictions = action.data;
                }
            });
            return newState
        }
        case 'ADD_NEW_GUEST': {
            if (newState.selectedCaseData.guests !== undefined) {
                if (newState.selectedCaseData.guests !== null) {
                    newState.selectedCaseData.guests.push(action.data);
                } else {
                    newState.selectedCaseData.guests = [];
                    newState.selectedCaseData.guests.push(action.data);
                }

                const guest = action.data;
                if (guest.rsvp === CaseUtil.rsvp.yes) {
                    GuestUtil.updateAllRSVPYesOfGuestCountInfo(newState.selectedCaseData.guestCountInfo, guest, GuestUtil.guestAction.add);
                }
            }
            return newState;
        }
        case 'HANDLE_SALES_ORDER': {
            dateHandlingForOrderBillingStatus(newState.selectedOrderData);
            dateHandlingForOrderBillingStatus(newState.selectedDataById);
            return newState;
        }
        case SET_TIME_PERIOD_FOR_ORDER_INVOICE: {
            if (newState.selectedOrderData.salesOrders !== undefined && newState.selectedOrderData.salesOrders.length > 0) {
                const {salesId, data} = action;
                const daysToAdd = parseInt(data, 10);
                for (let v = 0; v < newState.selectedOrderData.salesOrders.length; v++) {
                    const selectedSalesOrder = newState.selectedOrderData.salesOrders[v];
                    if (salesId === selectedSalesOrder.id && selectedSalesOrder.expiryDate !== null) {
                        const nextDate = DateUtilExt.dateByAddingDays(daysToAdd, selectedSalesOrder.expiryDate, TimestampFormat.DATE_TIME);
                        selectedSalesOrder.nextDate = DateUtilExt.dateToUTCString(nextDate);
                    }
                }
            }
            return newState;
        }
        case SET_CUSTOM_TIME_PERIOD_FOR_ORDER_INVOICE: {
            if (newState.selectedOrderData.salesOrders !== undefined && newState.selectedOrderData.salesOrders.length > 0) {
                const {salesOrder, data} = action;
                for (let v = 0; v < newState.selectedOrderData.salesOrders.length; v++) {
                    const selectedSalesOrder = newState.selectedOrderData.salesOrders[v];
                    if (salesOrder.id === selectedSalesOrder.id) {
                        selectedSalesOrder.nextDate = DateUtilExt.dateToUTCString(data);
                    }
                }
            }
            return newState;
        }
        case 'SAVE_TODO': {
            if (newState.selectedCaseData.todos !== undefined) {
                if (newState.selectedCaseData.todos.length !== 0) {
                    if (action.data.indexPosition === undefined) {
                        newState.selectedCaseData.todos.push(action.data);
                    } else {
                        const {indexPosition, todoType} = action.data;
                        const matchedIndex = newState.selectedCaseData.todos.findIndex(todo => todo.indexPosition === indexPosition
                            && todo.todoType === todoType);
                        if (matchedIndex !== NOT_FOUND) {
                            newState.selectedCaseData.todos[matchedIndex] = action.data;
                        }
                    }
                } else {
                    newState.selectedCaseData.todos = [];
                    newState.selectedCaseData.todos.push(action.data);
                }
            } else {
                newState.selectedCaseData.todos.push(action.data);
            }
            return newState;
        }
        case 'DELETE_TODO': {
            const {indexPosition, todoType} = action;
            const matchedIndex = newState.selectedCaseData.todos.findIndex(todo => todo.indexPosition === indexPosition
                && todo.todoType === todoType);
            if (matchedIndex !== NOT_FOUND) {
                newState.selectedCaseData.todos.splice(matchedIndex, 1);
            }
            return newState;
        }
        case UPDATE_CASE_DOCUMENTS: {
            const {payload, property} = action;
            if (!newState[property]) {//if empty
                newState[property] = [];
            }
            newState[property] = payload;
            return newState;
        }
        case UPDATE_GENERIC_DOCUMENT_TYPES: {
            const {payload, property} = action;
            if (!newState[property]) {//if empty
                newState[property] = [];
            }
            newState[property] = payload;
            return newState;
        }
        case 'UPDATE_GENERATED_CASE_DOCUMENT': {
            newState.generatedDocument = {};
            if (action.data.success === true) {
                newState.generatedDocument = action.data.object;
            }
            return newState;
        }
        case 'UPDATE_GENERATED_CASE_DOCUMENT_NEW_VERSION': {
            newState.selectedCaseData.generatedDocumentNewVersion = {};
            if (action.data.success === true) {
                newState.selectedCaseData.generatedDocumentNewVersion = action.data.object;
            }
            return newState;
        }
        case UPDATE_GENERIC_DOCUMENTS_ON_SAVE: {
            const {property, payload} = action;
            newState[property] = DocumentUtil.getUpdatedDocumentList(newState[property], payload);
            return newState;
        }
        case UPDATE_BASE64_GENERATED_DOCUMENT: {
            newState.generatedBase64Document = {};
            if (action.data.success === true) {
                newState.generatedBase64Document = action.data.object;
            }
            return newState;
        }
        case UPDATE_BASE64_GENERATED_DOCUMENT_NEW_VERSION: {
            newState.selectedCaseData.generatedBase64DocumentNewVersion = {};
            if (action.data.success === true) {
                newState.selectedCaseData.generatedBase64DocumentNewVersion = action.data.object;
            }
            return newState;
        }
        case CLEAR_BASE64_GENERATED_CASE_DOCUMENT: {
            const {payload} = action;
            newState.generatedBase64Document = payload;
            return newState;
        }
        case UPDATE_UPLOADED_CASE_DOCUMENT: {
            newState.generatedDocument = {};
            if (action.data.success === true) {
                newState.generatedDocument = action.data.object;
            }
            return newState;
        }
        case 'TODO_TEMPLATES': {
            newState.caseTodoTemplates.todoTemplates = [];
            //newState.selectedDataById.todoTemplates = [];
            action.data.object.forEach(template => {
                newState.caseTodoTemplates.todoTemplates.push(template);
            });
            // newState.selectedDataById.todoTemplates = newState.selectedCaseData.todoTemplates;
            return newState;
        }
        case 'TODO_TEMPLATE_ITEMS': {
            action.data.object.forEach(template => {
                if (newState.selectedCaseData.todos !== undefined) {
                    if (newState.selectedCaseData.todos.length !== 0) {
                        newState.selectedCaseData.todos.push(template);
                    } else {
                        newState.selectedCaseData.todos = [];
                        newState.selectedCaseData.todos.push(template);
                    }
                } else {
                    newState.selectedCaseData.todos.push(template);
                }
            });
            return newState;
        }
        case 'UPDATE_CASE_SELECTED_TEMPLATE': {
            if (newState.selectedCaseData.selectedTodoTemplates !== undefined) {
                newState.selectedCaseData.selectedTodoTemplates.push(action.data);
            } else {
                newState.selectedCaseData.selectedTodoTemplates = [];
                newState.selectedCaseData.selectedTodoTemplates.push(action.data);
            }
            newState.selectedDataById.selectedTodoTemplates = newState.selectedCaseData.selectedTodoTemplates;
            return newState;
        }
        case 'UPDATE_GUEST_MAIL': {
            if (action.data.success === true) {
                bottomCenter().success(action.data.message);
            }
            return newState;
        }
        case 'TENANT_BASED_ORDER_TYPES': {
            if (action.data.success) {
                newState.orderTypes = action.data.object;
                setTenantBasedOrderTypes(action.data.object);
            }
            return newState;
        }
        case ADD_NEW_EVENT_CATEGORY: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, addNewEventCategoryForOrderEvent);
                processOrderDataArray(newState.selectedDataById, action, addNewEventCategoryForOrderEvent);
            } else {
                addNewEventCategoryForOrderEvent(newState.selectedOrderData, action);
                addNewEventCategoryForOrderEvent(newState.selectedDataById, action);
            }
            return newState;
        }
        case ADD_MORE_EVENT_CATEGORY_FIELD: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, addMoreEventFieldCategory);
                processOrderDataArray(newState.selectedDataById, action, addMoreEventFieldCategory);
            } else {
                addMoreEventFieldCategory(newState.selectedOrderData, action);
                addMoreEventFieldCategory(newState.selectedDataById, action);
            }
            return newState;
        }
        case ADD_PRODUCTS_TO_EXISTING_EVENT_PRODUCT_CATEGORY: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, addProductToExistingEventCategoryProduct);
                processOrderDataArray(newState.selectedDataById, action, addProductToExistingEventCategoryProduct);
            } else {
                addProductToExistingEventCategoryProduct(newState.selectedOrderData, action);
                addProductToExistingEventCategoryProduct(newState.selectedDataById, action);
            }
            return newState;
        }
        case REMOVE_SINGLE_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, removeSingleProductFromOrderEventCategoryProducts);
                processOrderDataArray(newState.selectedDataById, action, removeSingleProductFromOrderEventCategoryProducts);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            } else {
                removeSingleProductFromOrderEventCategoryProducts(newState.selectedOrderData, action);
                removeSingleProductFromOrderEventCategoryProducts(newState.selectedDataById, action);
                getTotalPriceForOrder(newState.selectedOrderData, action.orderType, action.orderIndex, action.functionSource);
            }
            return newState;
        }
        case ON_ORDER_EVENT_CATEGORY_PRODUCT_SELECT: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, onProductSelectForEventCategory);
                processOrderDataArray(newState.selectedDataById, action, onProductSelectForEventCategory);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            } else {
                onProductSelectForEventCategory(newState.selectedOrderData, action);
                onProductSelectForEventCategory(newState.selectedDataById, action);
                getTotalPriceForOrder(newState.selectedOrderData, action.orderType, action.orderIndex, action.functionSource);
            }
            return newState;
        }

        case GET_REMAINING_PRODUCT_CATEGORIES_FOR_AN_EVENT: {
            for (let m = 0; m < action.data.object.length; m++) {
                var eventWithProductCategories = action.data.object[m];
                for (let n = 0; n < eventWithProductCategories.productCategories.length; n++) {
                    var category = eventWithProductCategories.productCategories[n];
                    category.isConfirm = false
                }
            }

            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, getRemainingProductCategoriesForAnEvent);
                processOrderDataArray(newState.selectedDataById, action, getRemainingProductCategoriesForAnEvent);
            } else {
                getRemainingProductCategoriesForAnEvent(newState.selectedOrderData, action);
                getRemainingProductCategoriesForAnEvent(newState.selectedDataById, action);
            }
            return newState;
        }

        case REMOVE_FULL_ORDER_EVENT_CATEGORY: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                let eventCategories = removeFullOrderEventCategory(newState.selectedCaseData, action);
                reArrangeCategories(newState.selectedCaseData, action, eventCategories);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            } else {
                let eventCategories = removeFullEventCategory(newState.selectedOrderData, action, {});
                reArrangeOrderEventCategories(newState.selectedOrderData, action, eventCategories);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            }
            return newState;
        }
        case UPDATE_EVENT_ATTRIBUTE_DATA: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, updateEventAttributeData);
            } else {
                updateEventAttributeData(newState.selectedOrderData, action);
            }
            return newState;
        }
        case ON_ORDER_EVENT_CATEGORY_PRODUCT_QUANTITY_AND_PRICE_CHANGE: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, updateProductQuantityAndPriceChange);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            } else {
                updateProductQuantityAndPriceChange(newState.selectedOrderData, action);
                getTotalPriceForOrder(newState.selectedOrderData, action.orderType, action.orderIndex, action.functionSource);
            }
            return newState;
        }
        case ON_ORDER_EVENT_CATEGORY_CHILD_PRODUCT_QUANTITY_AND_PRICE_CHANGE: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, updateChildProductQuantityAndPrice);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            } else {
                updateChildProductQuantityAndPrice(newState.selectedOrderData, action);
                getTotalPriceForOrder(newState.selectedOrderData, action.orderType, action.orderIndex, action.functionSource);
            }
            return newState;
        }
        case REMOVE_SINGLE_CHILD_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, removeSingleProductLineFromEventCategory);
                processOrderDataArray(newState.selectedDataById, action, removeSingleProductLineFromEventCategory);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            } else {
                removeSingleProductLineFromEventCategory(newState.selectedOrderData, action);
                removeSingleProductLineFromEventCategory(newState.selectedDataById, action);
                getTotalPriceForOrder(newState.selectedOrderData, action.orderType, action.orderIndex, action.functionSource);
            }
            return newState;
        }
        case CHANGE_ATTRIBUTE_CONFIRM_STATUS: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, updateAttributeConfirmStatus);
            } else {
                updateAttributeConfirmStatus(newState.selectedOrderData, action);
            }
            return newState;
        }
        case CHANGE_CUSTOM_PRODUCT_NAME: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, changeCustomProductName);
                getTotalPriceForOrder(newState.selectedCaseData, action.orderType, action.orderIndex, action.functionSource);
            } else {
                changeCustomProductName(newState.selectedOrderData, action);
                getTotalPriceForOrder(newState.selectedOrderData, action.orderType, action.orderIndex, action.functionSource);
            }
            return newState;
        }
        case CHANGE_EVENT_CATEGORY_CONFIRM_STATUS: {
            if (action.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE) {
                processOrderDataArray(newState.selectedCaseData, action, updateEventCategoryConfirmStatus);
            } else {
                updateEventCategoryConfirmStatus(newState.selectedOrderData, action);
            }
            return newState;
        }
        case UPDATE_DECEASED_DATE_OF_PASSING: {
            switch (action.dateUnit) {
                case 'date':
                    newState.selectedCaseData.deceased.dateOfPassing = action.data;
                    break;
                case 'time':
                    newState.selectedCaseData.deceased.dateOfPassing = action.data;
                    newState.selectedCaseData.deceased.timeOfPassing = action.data;
                    break;
                default:
                    break;
            }
            return newState;
        }
        case UPDATE_DECEASED_DATE_OF_BODY_PICKUP: {
            switch (action.dateUnit) {
                case 'date':
                    newState.selectedCaseData.deceased.dateOfBodyPickup = action.data;
                    break;
                case 'time':
                    newState.selectedCaseData.deceased.dateOfBodyPickup = action.data;
                    newState.selectedCaseData.deceased.timeOfBodyPickupText = action.data;
                    break;
                default:
                    break;
            }
            return newState;
        }
        case SET_TRANSPORTER_PICKUP: {
            newState.selectedCaseData.deceased.bodyPickupTransporterId = AppUtil.isAvailable(action.data) && AppUtil.isAvailable(action.data.id) ? action.data.id : null;
            return newState;

        }
        case UPDATE_DEADLINE_DETAILS: {
            const deadline = newState.selectedCaseData.deadline;
            const {isManual, dateUnit, payload} = action;
            switch (dateUnit) {
                case 'date':
                case 'time': {
                    deadline.isManual = isManual;
                    deadline.deadline = payload;
                    break;
                }
                case 'cancel':
                case 'reset': {
                    deadline.isManual = isManual;
                    // Todo set some different code for reset deadline value
                    deadline.deadlineType = 4;
                    deadline.deadline = payload;
                    break;
                }
                default:
                    break
            }
            return newState;
        }
        case UPDATE_DEADLINE_EDIT_BUTTON: {
            let deadline = newState.selectedCaseData.deadline;
            deadline.editButton = {isActive: action.data};
            deadline.resetButton = {isDisabled: deadline.editButton.isActive};
            return newState;
        }
        case UPDATE_DEADLINE_RESET_BUTTON: {
            let deadline = newState.selectedCaseData.deadline;
            deadline.resetButton = {isActive: action.data};
            deadline.editButton = {isDisabled: deadline.resetButton.isActive};
            return newState;
        }
        case DISABLE_CASE_SAVE_BUTTON: {
            newState.disableCaseSaveButton = action.payload;
            return newState;
        }
        case 'UPDATE_INITIAL_DEADLINE_DATE': {
            let deadline = newState.selectedCaseData.deadline;
            deadline.initialDeadline = cloneDeep(action.data);
            return newState;
        }
        case 'FETCHED_ALL_COUNTRIES': {
            newState.countries = action.data.object;
            return newState;
        }
        case 'FETCHED_ALL_COUNTIES': {
            newState.counties = action.data.object;
            return newState;
        }
        case 'ON_CHANGE_COUNTRY_INFO': {
            newState.selectedCaseData.deceased.address.countryId = action.data.id;
            newState.selectedCaseData.deceased.address.country = action.data;
            return newState;
        }
        case 'ON_CHANGE_COUNTY_INFO': {
            newState.selectedCaseData.deceased.address.countyId = action.data.id;
            newState.selectedCaseData.deceased.address.county = action.data;
            return newState;
        }
        case 'ON_CHANGE_COUNTRY_INFO_OF_PASSING': {
            newState.selectedCaseData.deceased.addressOfPassing.countryId = action.data.id;
            newState.selectedCaseData.deceased.addressOfPassing.country = action.data;
            return newState;
        }
        case 'ON_CHANGE_COUNTY_INFO_OF_PASSING': {
            newState.selectedCaseData.deceased.addressOfPassing.countyId = action.data.id;
            newState.selectedCaseData.deceased.addressOfPassing.county = action.data;
            return newState;
        }
        case 'ON_CHANGE_PHONE_OF_PASSING': {
            newState.selectedCaseData.deceased.phoneOfPassing = action.data;
            return newState;
        }
        case 'SET_COUNTRY_ID': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (newState.selectedCaseData.relatives[b].id === parseInt(action.data.id, 10) && b === action.index) {
                        newState.selectedCaseData.relatives[b].address.country = action.data.address.country;
                        newState.selectedCaseData.relatives[b].address.countryId = parseInt(action.data.address.country.id, 10);
                        console.log("onChangeCountry", newState.selectedCaseData.relatives[b].address);
                    }
                }
            }
            return newState;
        }
        case 'SET_COUNTY_ID': {
            if (newState.selectedCaseData.relatives !== undefined) {
                for (let b = 0; b < newState.selectedCaseData.relatives.length; b++) {
                    if (newState.selectedCaseData.relatives[b].id === parseInt(action.data.id, 10) && b === action.index) {
                        newState.selectedCaseData.relatives[b].address.county = action.data.address.county;
                        newState.selectedCaseData.relatives[b].address.countyId = parseInt(action.data.address.county.id, 10);
                        console.log("onChangeCounty", newState.selectedCaseData.relatives[b].address);
                    }
                }
            }
            return newState;
        }
        case 'SEND_COMMISSION_REPORT_MAIL': {
            if (action.data.success === true) {
                bottomCenter().success(action.data.message);
            } else {
                bottomCenter().error(action.data.message);
            }
            return newState;
        }
        case SHOULD_SYNCHRONIZE_LOCAL_STORAGE:
            newState.shouldSynchronizeLocalStorage = action.payload;
            return newState;
        case SHOULD_SYNCHRONIZE_GLOBAL_VARIABLES:
            newState.shouldSynchronizeGlobalVariables = action.payload;
            return newState;
        case UPDATE_CASE_CONNECTION_INFO: {
            const {property} = action;
            if (AppUtil.isAvailable(newState.selectedCaseData) && newState.selectedCaseData?.[property]) {
                const {payload} = action;
                //Updated probateCase details
                newState.selectedCaseData[property] = {...payload}
                return newState;
            } else {
                return state;
            }
        }
        case UPDATE_CASE_PROBATE_PROPERTY:
            const {payload, property} = action;
            newState[property] = payload;
            return newState;
        case FETCHED_RELATED_CASES:
            newState.relatedCases = action.data.object;
            return newState;
        case CLEAR_RELATED_CASES:
            newState.relatedCases = [];
            return newState;
        case UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER:
            newState.caseDocuments.forEach((document) => {
                if (document.id === action.data.id) {
                    document.visibleToCustomer = action.data.checked;
                }
            });
            return newState;
        case ADD_CASE_COUNTY_TO_STATE: {
            if (newState.selectedCaseData.relatives !== undefined) {
                const primaryContact = newState.selectedCaseData.relatives.find(item => item.id === action.data.primaryContactId);
                if (AppUtil.isAvailable(primaryContact)) {
                    primaryContact.address.countyId = action.data.selectedCounty.id;
                }
            }
            //During drag drop in convert view, passed county info to "case stage popup", as case relative is not available in card info
            newState.popupCase.countyId = action.data.selectedCounty.id;
            return newState;
        }
        case UPDATE_CASE_GENERIC_PROPERTY: {
            const {property, payload} = action;
            newState.selectedCaseData[property] = payload;
            return newState;
        }
        default:
            return state;
    }

    function applyRoleRestrictions(allRoleDataWithSelectedUserInfo, selectedCheckBoxId, isLoadCall) {
        console.log(allRoleDataWithSelectedUserInfo);

        let obj = allRoleDataWithSelectedUserInfo.find(o => o.id === selectedCheckBoxId);
        if (obj.checked) {
            console.log(CaseUtil.roleRestrictions[selectedCheckBoxId]);
            let disableRoles = CaseUtil.roleRestrictions[selectedCheckBoxId].disable;
            let enableRoles = CaseUtil.roleRestrictions[selectedCheckBoxId].enable;

            for (let i = 0; i < enableRoles.length; i++) {
                let enableRoleMatch = allRoleDataWithSelectedUserInfo.find(o => o.id === enableRoles[i]);
                if (AppUtil.isAvailable(enableRoleMatch)) {
                    enableRoleMatch.enable = true;
                }
            }

            for (let j = 0; j < disableRoles.length; j++) {
                let disableRoleMatch = allRoleDataWithSelectedUserInfo.find(o => o.id === disableRoles[j]);
                if (AppUtil.isAvailable(disableRoleMatch)) {
                    if (!isLoadCall) {
                        disableRoleMatch.enable = false;
                        disableRoleMatch.checked = false;
                    } else if (isLoadCall && disableRoleMatch.Id === obj.id) {
                        disableRoleMatch.enable = true;
                        disableRoleMatch.checked = true;
                    }
                }
            }
        }

        let noneChecked = allRoleDataWithSelectedUserInfo.filter(i => i.checked === true);
        if (!AppUtil.isAvailable(noneChecked)) {
            for (let j = 0; j < allRoleDataWithSelectedUserInfo.length; j++) {
                allRoleDataWithSelectedUserInfo[j].enable = true;
            }
        }
    }
}

/**
 * Process case orders
 * @param caseData
 * @param action
 * @param enclosedFunction
 */
function processOrderDataArray(caseData, action, enclosedFunction) {
    if (AppUtil.isAvailable(caseData.orders)) {
        caseData.orders.forEach((order, orderIndex) => {
            enclosedFunction(order, action, orderIndex);
        });
    } else {
        console.log("[Debug]:: Failure:: processing data.orders for different actions: as orders not available = ", caseData.orders, action);
    }
}

function addNewEventCategoryForOrderEvent(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && orderIndex === action.orderIndex) {
        order.events.forEach(event => {
            if (event.eventType === action.eventType) {
                addNewEventCategory(event, action.category);
                removeSelectedCategoryFromCategoryData(event, action.category.id);
            } else {

            }
        });
    } else {
        console.log("No order qualified!!")
    }
}

function addMoreEventFieldCategory(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && orderIndex === action.orderIndex) {
        order.events.forEach(event => {
            if (event.eventType === action.eventType) {
                event.categories.forEach(category => {
                    if (category.id === action.category.id) {
                        addNewFieldInEventCategory(category);
                    }
                });
            }
        });
    } else {
        console.log("No order qualified!!")
    }
}

function removeFullOrderEventCategory(caseData, action) {
    let eventCategory = {};
    caseData.orders.forEach((order, orderIndex) => {
        eventCategory = removeFullEventCategory(order, action, eventCategory, orderIndex);
    });
    return eventCategory;
}

function removeFullEventCategory(order, action, eventCategory, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    let temp = [];
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event, j) => {
            if (event.eventType === action.eventType) {
                if (event.categories.length === 1) {
                    eventCategory = JSON.parse(JSON.stringify(event.categories[0]));
                    event.categories = [];
                } else {
                    event.categories.forEach((category, categoryIndex) => {
                        let matched = false;
                        if (category.id === action.categoryId) {
                            eventCategory = JSON.parse(JSON.stringify(category));
                            matched = true;
                        } else if (matched === false) {
                            temp.push(category);
                            event.categories = temp
                        }
                    })
                }
            }
        })
    }
    return eventCategory;
}

function reArrangeCategories(caseData, action, eventCategories) {
    caseData.orders.forEach((order, orderIndex) => {
        reArrangeOrderEventCategories(order, action, eventCategories, orderIndex);
    })
}

function reArrangeOrderEventCategories(order, action, eventCategories, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event, j) => {
            if (event.eventType === action.eventType) {
                if (event.categories !== null && event.categories !== undefined) {
                    event.categoryData.push(eventCategories);
                }
            }
        })
    }
}

function getRemainingProductCategoriesForAnEvent(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    let temp = [];
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach(event => {
            var eventWithProductCategories = action.data.object.filter(item => {
                return item.id === event.eventType
            })

            if (event.categories.length > 0) {
                if (AppUtil.isAvailable(eventWithProductCategories)) {
                    eventWithProductCategories[0].productCategories
                        .forEach(proCate => {
                            let matchedEventCategory = event.categories.filter(obj => {
                                return obj.id === proCate.id;
                            });
                            if (!AppUtil.isAvailable(matchedEventCategory)) {
                                temp.push(proCate);
                                event.categoryData = temp;
                            }
                        })
                }
            } else {
                if (AppUtil.isAvailable(eventWithProductCategories)) {
                    event.categoryData = eventWithProductCategories[0].productCategories;
                }
            }
        });
    }
}

function addProductToExistingEventCategoryProduct(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && orderIndex === action.orderIndex) {
        order.events.forEach(event => {
            if (event.eventType === action.eventType) {
                event.categories.forEach(category => {
                    if (category.id === action.category.id) {
                        if (event.eventType === OrderUtil.eventTypes.disbursements.value) {
                            getDisbursementProductsForDropdown(action.category, "data");
                        }
                        refactorProductsForDropdown(action.category);
                        category.data = action.category.data;
                    }
                });
            }
        });
    } else {
        console.log("No order qualified!!")
    }
}

function removeSingleProductFromOrderEventCategoryProducts(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    let temp = [];
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                event.categories.forEach((category) => {
                    if (category.id === action.categoryId) {
                        if (category.eventProducts.length === 1) {
                            category.eventProducts = []
                        } else {
                            category.eventProducts.forEach((product, productIndex) => {
                                let matched = false;
                                if (action.product.productId === product.productId && productIndex === action.productIndex) {
                                    matched = true;
                                }
                                if (matched === false) {
                                    temp.push(product);
                                    category.eventProducts = temp
                                }
                            })
                        }
                    }
                })
            }
        })
    }
}

function updateAttributeConfirmStatus(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                if (action.eventAttributeType === undefined && action.isConfirm !== undefined) {
                    event.isConfirm = action.isConfirm;
                } else {
                    event.eventAttributes.forEach(attribute => {
                        if (attribute.attributeType === action.eventAttributeType) {
                            attribute.isConfirm = action.isConfirm;
                        }
                    })
                }
            }
        })
    }
}

function changeCustomProductName(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                event.categories.forEach((category) => {
                    if (category.id === action.categoryId) {
                        category.eventProducts.forEach((product, productIndex) => {
                            if (action.product.productId === product.productId && productIndex === action.productIndex) {
                                product.customName = action.customProductName;
                            }
                        })
                    }
                })
            }
        })
    }
}

function updateEventCategoryConfirmStatus(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                event.categories.forEach((category) => {
                    if (category.id === action.categoryId) {
                        if (action.isConfirm !== undefined && action.productId === undefined) {
                            category.isConfirm = action.isConfirm;
                        } else {
                            category.eventProducts.forEach((product, productIndex) => {
                                if (action.productId === product.productId && productIndex === action.productIndex) {
                                    product.isConfirm = action.isConfirm;
                                }
                            })
                        }
                    }
                })
            }
        })
    }
}

function onProductSelectForEventCategory(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                event.categories.forEach((category) => {
                    if (category.id === action.categoryId) {
                        category.eventProducts.forEach((product, productIndex) => {
                            if (AppUtil.isAvailable(action.selectedProduct)) {
                                if (action.product.productId === product.productId && productIndex === action.productIndex) {
                                    product.productId = parseInt(action.selectedProduct.id, 10);
                                    product.finalPrice = action.selectedProduct.finalPrice;
                                    product.typeId = action.selectedProduct.typeId;
                                    product.productName = action.selectedProduct.name;
                                    product.enableFreeText = action.selectedProduct.enableFreeText;
                                    product.freeText = "";
                                    product.disbursementPayment = AppUtil.isAvailable(product.disbursementPayment) ? product.disbursementPayment : action.selectedProduct.disbursementPayment;
                                    product.name = action.selectedProduct.name;
                                    product.grossMargin = action.selectedProduct.grossMargin;
                                    product.commission = action.selectedProduct.commission;
                                    product.originalBcVat = action.selectedProduct.bcVat;
                                    if (action.selectedProduct.typeId === ProductTypes.parentProduct) {
                                        if (product.childProducts.length === 0) {
                                            product.relatedProducts = [];
                                            product.relatedProducts = action.selectedProduct.relatedProducts;
                                            product.childProducts.push({
                                                eventId: 0,
                                                id: 0,
                                                isConfirm: false,
                                                price: 0,
                                                finalPrice: 0,
                                                productId: 0,
                                                productName: "",
                                                quantity: 1,
                                                customName: "",
                                                typeId: 0,
                                                grossMargin: 0,
                                                commission: 0,
                                                originalBcVat: 0
                                            })
                                        }
                                    }
                                }
                            }
                        })
                    }
                })
            }
        })
    }
}

/***
 * @description:
 * - If reception date is changed, RSVP Date = 5 days before the reception date.
 * - When "Handle RSVP" is true
 *      RSVP Date = 5 days before the reception date(Default),
 *      else
 *      RSVP Date = current date, If no reception date,
 */
function updateEventAttributeData(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    const {data} = action;
    if (order.orderType === data.orderType && orderIndex === data.orderIndex) {
        order.events.forEach((event, j) => {
            if (event.eventType === data.eventType) {
                if (event.eventAttributes !== undefined) {
                    event.eventAttributes.forEach((attType, k) => {
                        if (attType.attributeType === data.selectedEventAttribute.attributeType) {
                            attType.selectedValue = data.selectedValue;
                            attType.selectedValueText = data.selectedText;
                        }
                    });
                    if (data.selectedEventAttribute.attributeType === EventAttribute.receptionDate) {
                        event.eventAttributes.forEach((attType, k) => {
                            if (attType.attributeType === EventAttribute.receptionRsvpDate) {
                                attType.selectedValue = data.selectedValue ? DateUtilExt.addDays(dateFromString(data.selectedValue), -5) : null;
                                attType.selectedValueText = data.selectedValue ? DateUtilExt.addDays(dateFromString(data.selectedValue), -5) : null;
                            }
                        });
                    }
                    if (data.selectedEventAttribute.attributeType === EventAttribute.receptionHandleRsvp) {
                        event.eventAttributes.forEach((attType, k) => {
                            if (attType.attributeType === EventAttribute.receptionRsvpDate) {
                                let matched = OrderFieldRules.findAttribute(event, EventAttribute.receptionDate);
                                const receptionDateEventAttribute = matched[0];
                                if (AppUtil.isAvailable(receptionDateEventAttribute.selectedValue)) {
                                    attType.selectedValue = DateUtilExt.addDays(dateFromString(receptionDateEventAttribute.selectedValue), -5);
                                    attType.selectedValueText = DateUtilExt.addDays(dateFromString(receptionDateEventAttribute.selectedValue), -5);
                                } else {
                                    attType.selectedValue = dateFromString(DateUtilExt.nowDateString());
                                    attType.selectedValueText = dateFromString(DateUtilExt.nowDateString());
                                }
                            }
                        });
                    }
                    if (data.selectedEventAttribute.attributeType === EventAttribute.attendedFuneralServiceTypeOfService) {
                        setDefaultAttributesValueBasedOnSelectedTypeOfService(order);
                    }
                }
            }
        })
    }
}

const dateFromString = (dateStr, format = TimestampFormat.DATE) => {
    return DateUtilExt.date(dateStr, format);
}

function updateProductQuantityAndPriceChange(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                event.categories.forEach((category) => {
                    if (category.id === action.categoryId) {
                        category.eventProducts.forEach((product, productIndex) => {
                            if (action.product.productId === product.productId && productIndex === action.productIndex) {
                                if (action.quantity !== undefined)
                                    product.quantity = action.quantity;
                                if (action.price !== undefined)
                                    product.finalPrice = action.price;
                                if (action.originalBcVat !== undefined)
                                    product.originalBcVat = action.bcVat;
                                if (action.commission !== undefined)
                                    product.commission = action.commission;
                                if (action.grossMargin !== undefined)
                                    product.grossMargin = action.grossMargin;
                            }
                        })
                    }
                })
            }
        })
    }
}

function updateChildProductQuantityAndPrice(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                event.categories.forEach((category) => {
                    if (category.id === action.categoryId) {
                        category.eventProducts.forEach((product, productIndex) => {
                            if (action.product.productId === product.productId && productIndex === action.productIndex) {
                                product.childProducts.forEach((childData, childProductIndex) => {
                                    if (action.childProduct.productId === childData.productId && childProductIndex === action.childProductIndex) {
                                        if (action.quantity !== undefined)
                                            childData.quantity = action.data;
                                        if (action.price !== undefined)
                                            childData.finalPrice = action.data;
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }
}

function removeSingleProductLineFromEventCategory(order, action, orderIndex = OrderUtil.ORDER_DEFAULT_INDEX) {
    let temp = [];
    if (order.orderType === action.orderType && action.orderIndex === orderIndex) {
        order.events.forEach((event) => {
            if (event.eventType === action.eventType) {
                event.categories.forEach((category) => {
                    if (category.id === action.categoryId) {
                        category.eventProducts.forEach((product, productIndex) => {
                            if (action.product.productId === product.productId && productIndex === action.productIndex) {
                                if (product.childProducts.length === 1) {
                                    product.childProducts = []
                                } else {
                                    product.childProducts.forEach((childProduct, childProductIndex) => {
                                        let matchedChild = false;
                                        if (action.childProduct.productId === childProduct.productId && childProductIndex === action.childProductIndex) {
                                            matchedChild = true
                                        } else if (matchedChild === false) {
                                            temp.push(childProduct);
                                            product.childProducts = temp
                                        }
                                    })

                                }
                            }
                        })
                    }
                })
            }
        })
    }
}

function getDisbursementProductsForDropdown(selectedCategory, property) {
    selectedCategory[property] = selectedCategory[property].filter(item => item.enableDisbursement === true);
}

function refactorProductsForDropdown(selectedCategory) {
    selectedCategory.data.forEach((d, i) => {
        selectedCategory.data[i].name = createProductName(selectedCategory.data[i].fullName, selectedCategory.data[i].price);
    });
}

function addNewEventCategory(event, selectedCategory) {
    if (event.eventType === OrderUtil.eventTypes.disbursements.value) {
        getDisbursementProductsForDropdown(selectedCategory, "data");
    }
    refactorProductsForDropdown(selectedCategory);
    event.categories.push({
        eventProducts: [{
            childProducts: [],
            eventId: 0,
            id: 0,
            isConfirm: false,
            price: 0,
            finalPrice: 0,
            productId: 0,
            productName: "",
            quantity: 1,
            customName: "",
            typeId: 0
        }],
        id: selectedCategory.id,
        isConfirm: selectedCategory.isConfirm,
        name: selectedCategory.name,
        data: selectedCategory.data,
        toggle: true
    });
}

function addNewFieldInEventCategory(category) {
    category.eventProducts.push({
        childProducts: [],
        eventId: 0,
        id: 0,
        isConfirm: false,
        price: 0,
        finalPrice: 0,
        productId: 0,
        productName: "",
        quantity: 1,
        customName: "",
        typeId: 0
    });
}

function removeSelectedCategoryFromCategoryData(event, targetCategoryId) {
    if (event.categoryData !== undefined && event.categoryData.length > 0) {
        event.categoryData = event.categoryData.filter(x => x.id !== targetCategoryId);
    }
}

function handlingVariantProductDropdownData(data) {
    if (data.typeId === ProductTypes.productWithVariants || data.typeId === ProductTypes.variantProduct) {
        data.parentChildData = [];
        data.parentChildData.unshift({
            "id": data.version.parent.id,
            "name": data.version.parent.name
        });
        for (let d = 0; d < data.version.versions.length; d++) {
            data.parentChildData.push({
                id: data.version.versions[d].id,
                name: " ╰ " + data.version.versions[d].name
            })
        }
    }
}

function dateHandlingForOrderBillingStatus(object) {
    if (object.salesOrders !== undefined && object.salesOrders.length > 0) {
        for (let b = 0; b < object.salesOrders.length; b++) {
            const salesOrder = object.salesOrders[b];
            if (salesOrder.expiryDate !== null) {
                salesOrder.nextDate = DateUtilExt.dateByAddingDays(PAUSE_INVOICE_TWO_DAYS, salesOrder.expiryDate, TimestampFormat.DATE_TIME);
                salesOrder.nextDate = DateUtilExt.dateToUTCString(salesOrder.nextDate);
            }
        }
    }
}

function getTotalPriceForOrder(object, order, orderIndex, calledInfo) {
    let hasCompensationAccess = false;
    if (calledInfo === 'case') {
        if (CaseUtil.hasCompensationAccess(object.assigned)) {
            hasCompensationAccess = true;
        }
        object.orders.forEach((orderInfo, j) => {
            if (orderInfo.orderType === order && j === orderIndex) {
                orderInfo.prices = [];
                orderInfo.compensations = [];
                orderInfo.bcVat = [];
                orderInfo.events.forEach((event, k) => {
                    let catStatus = false
                    if (event.categories.length > 0) {
                        catStatus = true
                        event.categories.forEach((category, l) => {
                            category.catPrice = []
                            category.catTotal = []
                            let status = false
                            if (category.eventProducts.length > 0) {
                                status = true;
                                category.eventProducts.forEach((product, m) => {
                                    calculateTotalDetails(orderInfo, product, hasCompensationAccess);
                                    category.catPrice.push(product.finalPrice * product.quantity)
                                    category.catTotal = category.catPrice.reduce((a, b) => a + b, 0)
                                    reduceTotalDetails(orderInfo, hasCompensationAccess);
                                    let childStatus = false
                                    if (product.childProducts.length > 0) {
                                        product.childProducts.forEach((childProduct, c) => {
                                            calculateTotalDetails(orderInfo, product, hasCompensationAccess);
                                            category.catPrice.push(childProduct.finalPrice * childProduct.quantity)
                                            category.catTotal = category.catPrice.reduce((a, b) => a + b, 0)
                                            reduceTotalDetails(orderInfo, hasCompensationAccess);
                                        })
                                    } else if (childStatus === false) {
                                        reduceTotalDetails(orderInfo, hasCompensationAccess);
                                    }
                                })
                            } else if (status === false) {
                                reduceTotalDetails(orderInfo, hasCompensationAccess);
                            }

                        })
                    } else if (catStatus === false) {
                        reduceTotalDetails(orderInfo, hasCompensationAccess);
                    }

                })
            }
        })
    } else {
        if (object.orderType === order) {
            if (object.case.hasCompensationAccess) {
                hasCompensationAccess = true;
            }

            object.prices = [];
            object.compensations = [];
            object.bcVat = [];
            object.events.forEach((event, k) => {
                let catStatus = false
                if (event.categories.length > 0) {
                    catStatus = true
                    event.categories.forEach((category, l) => {
                        category.catPrice = []
                        category.catTotal = []
                        let status = false
                        if (category.eventProducts.length > 0) {
                            status = true;
                            category.eventProducts.forEach((product, m) => {
                                calculateTotalDetails(object, product, hasCompensationAccess);
                                category.catPrice.push(product.finalPrice * product.quantity)
                                category.catTotal = category.catPrice.reduce((a, b) => a + b, 0)
                                reduceTotalDetails(object, hasCompensationAccess);
                                let childStatus = false
                                if (product.childProducts.length > 0) {
                                    product.childProducts.forEach((child, c) => {
                                        calculateTotalDetails(object, product, hasCompensationAccess);
                                        category.catPrice.push(product.child.finalPrice * product.child.quantity)
                                        category.catTotal = category.catPrice.reduce((a, b) => a + b, 0)
                                        reduceTotalDetails(object, hasCompensationAccess);
                                    })
                                } else if (childStatus === false) {
                                    reduceTotalDetails(object, hasCompensationAccess);
                                }
                            })
                        } else if (status === false) {
                            console.log("ELSE")
                            reduceTotalDetails(object, hasCompensationAccess);
                        }

                    })
                } else if (catStatus === false) {
                    reduceTotalDetails(object, hasCompensationAccess);
                }
            })
        }
    }
}

function calculateTotalDetails(orderDetails, productDetails, hasCompensationAccess) {
    orderDetails.prices.push(productDetails.finalPrice * productDetails.quantity);
    orderDetails.bcVat.push(ProductUtil.calculateVat(productDetails.finalPrice, productDetails.originalBcVat) * productDetails.quantity);
    if (hasCompensationAccess === true) {
        const bcVat = ProductUtil.calculateVat(productDetails.finalPrice, productDetails.originalBcVat)
        const commission = productDetails.quantity * ProductUtil.calculateCommission(productDetails.finalPrice, bcVat, productDetails.grossMargin, productDetails.commission, commissionCoefficient());
        const commissionRoundedOff = Math.round(commission);
        orderDetails.compensations.push(commissionRoundedOff);
    }
}

function reduceTotalDetails(data, hasCompensationAccess) {
    if (AppUtil.isEmpty(data.totalDetails)) {
        data.totalDetails = {};
    }
    data.totalDetails.total = data.prices.reduce((a, b) => a + b, 0);

    if (hasCompensationAccess) {
        data.totalDetails.compensation = Math.round(data.compensations.reduce((a, b) => a + b, 0));
    } else {
        data.totalDetails.compensation = 0;
    }

    data.totalDetails.totalVat = AppUtil.isAvailable(data.bcVat) ? data.bcVat.reduce((a, b) => a + b, 0) : 0;
    data.totalDetails.totalExVat = data.totalDetails.total - data.totalDetails.totalVat;
}

function displayCategoryDataForAnonymous(apiResponse, eventType, object, functionSource) {
    if (functionSource === 'case') {
        if (AppUtil.isAvailable(object.orders)) {
            for (let m = 0; m < object.orders.length; m++) {
                if (object.orders[m].events[0].eventType === OrderUtil.eventTypes.anonymous.value
                    && (AppUtil.isAvailable(apiResponse) && object.orders[m].orderType === apiResponse.orderType)) {
                    let temp = [];
                    object.orders[m].events[0].categoryData = [];
                    if (object.orders[m].events[0].categories.length > 0) {
                        for (let c = 0; c < apiResponse.productCategories.length; c++) {
                            let touch = false;
                            for (let v = 0; v < object.orders[m].events[0].categories.length; v++) {
                                if (object.orders[m].events[0].categories[v].id === apiResponse.productCategories[c].id) {
                                    touch = true
                                }
                            }
                            if (touch === false) {
                                temp.push(apiResponse.productCategories[c]);
                                object.orders[m].events[0].categoryData = temp
                            }
                        }
                    } else {
                        object.orders[m].events[0].categoryData = apiResponse.productCategories;
                    }

                }
            }
        } else {
            console.log(`[Debug]:: Case>Order:: Failure:: Updating order.events.category, as for eventType = ${eventType}, orders not available = ${object.orders}, object = ${JSON.stringify(object)}`);
        }
    } else {
        if (AppUtil.isAvailable(object.events) && (object.events.length > 0)) {
            if ((object.events[0].eventType === OrderUtil.eventTypes.anonymous.value)) {
                let temp = [];
                object.events[0].categoryData = [];
                if (object.events[0].categories.length > 0) {
                    for (let c = 0; c < apiResponse.length; c++) {
                        let touch = false;
                        for (let v = 0; v < object.events[0].categories.length; v++) {
                            if (object.events[0].categories[v].id === apiResponse[c].id) {
                                touch = true
                            }
                        }
                        if (touch === false) {
                            temp.push(apiResponse[c]);
                            object.events[0].categoryData = temp
                        }
                    }
                } else {
                    object.events[0].categoryData = apiResponse;
                }
            }
        } else {
            console.log(`[Debug]:: Order details:: Failure:: Updating order.events.category, as for eventType = ${eventType}, orders not available = ${object.events}, object = ${JSON.stringify(object)}`);
        }
    }
}

function removeNotes(sourceObject, data, index) {
    if (sourceObject !== undefined && sourceObject.notes !== undefined) {
        let notes = [];
        for (let b = 0; b < sourceObject.notes.length; b++) {
            let matched = false;
            if (data.id === sourceObject.notes[b].id && index === b) {
                matched = true
            } else if (matched === false) {
                notes.push(sourceObject.notes[b]);
            }
        }
        sourceObject.notes = notes;
    }
}

function addChildProductInsideParent(object, data, orderInfo, eventInfo, calledInfo, orderIndex, product, productIndex) {
    if (calledInfo === 'case') {
        object.orders.forEach((orderType, i) => {
            if (orderType.orderType === orderInfo && orderIndex === i) {
                orderType.events.forEach((event, j) => {
                    if (event.eventType === eventInfo) {
                        event.categories.forEach((category, k) => {
                            if (category.id === data.id) {
                                category.eventProducts.forEach((pro, p) => {
                                    if (pro.id === product.id && p === productIndex) {
                                        pro.childProducts.push({
                                            eventId: 0,
                                            id: 0,
                                            isConfirm: false,
                                            price: 0,
                                            finalPrice: 0,
                                            productId: 0,
                                            productName: "",
                                            quantity: 1,
                                            customName: "",
                                            typeId: 0
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });
    } else {
        if (object.orderType === orderInfo) {
            object.events.forEach((event, j) => {
                if (event.eventType === eventInfo) {
                    event.categories.forEach((category, k) => {
                        if (category.id === data.id) {
                            category.eventProducts.forEach((pro, p) => {
                                if (pro.id === product.id && p === productIndex) {
                                    pro.childProducts.push({
                                        eventId: 0,
                                        id: 0,
                                        isConfirm: false,
                                        price: 0,
                                        finalPrice: 0,
                                        productId: 0,
                                        productName: "",
                                        quantity: 1,
                                        customName: "",
                                        typeId: 0
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    }
}

function updateIsGuestOfRelative(relative) {
    relative.isGuest = (relative.guestId > 0);
}

/***
 * @description: set attribute Default value = No
 */
const updateFuneralServiceAttribute = (attributeType, fromEvents, isMatchingSelectedType) => {
    const attribute = OrderUtil.getFuneralServiceEventAttributeToMatch(attributeType, fromEvents);

    attribute.selectedValue = isMatchingSelectedType ? YesNoOptionsOnly.no.value : YesNoOptionsOnly.yes.value;
    attribute.selectedValueText = isMatchingSelectedType ? Utility.getLang().yesNoSelectOptions.no : Utility.getLang().yesNoSelectOptions.yes;
};


function setDefaultAttributesValueBasedOnSelectedTypeOfService(order) {
    const typeofServiceAttribute = OrderUtil.getFuneralServiceEventAttributeToMatch(OrderUtil.getTypeOfService(order.orderType), order.events);

    const isSimpleCremationServiceType = AppUtil.isAvailable(typeofServiceAttribute) && (typeofServiceAttribute.selectedValue === TypeOfAttendedFuneralService.simpleCremation.value);

    updateFuneralServiceAttribute(EventAttribute.funeralServiceConductorAttendance, order.events, isSimpleCremationServiceType);
    updateFuneralServiceAttribute(EventAttribute.funeralServiceHearse, order.events, isSimpleCremationServiceType);
}
