import CaseApi from "../api/caseApi";
import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import {bottomCenter} from "../components/common/customToastr";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {onErrorUnderCaseOrder} from "./caseOrderAction";
import CaseUtil from "../components/common/caseUtil";
import LogUtil, {LogCategory} from "../components/common/log/util/logUtil";
import {
    updateColumnPageDataBySearch,
    updatePropertyColumnPageDataBySearch
} from "../components/common/masterDetailView/column/redux/columnAction";
import {ColumnPageMapperProps, CRUDOperation} from "../components/common/masterDetailView/column/redux/types";
import {UPDATE_CASE_CONNECTION_INFO, UPDATE_CASE_PROBATE_PROPERTY} from "../components/case/probates/redux/types";
import {API} from "../components/case/memorialPage/api/memorialApis";

function getCaseStagesDetailsCommon(token, dispatch) {
    return (CommonApiCall.fetch_All_Data(token, ApiCollection.properties.fetchCaseStage).then(data => {
        dispatch(fetchedAllStages(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function getRelatedCasesDetailsApi(caseId) {
    return function (dispatch) {
        const url = ApiCollection.properties.fetchRelatedCases
            .replace("{caseId}", caseId);
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.genericApiCall(url, newData.idToken.jwtToken, "GET").then(data => {
                        return dispatch(fetchedRelatedCases(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.genericApiCall(url, getApiToken(), "GET").then(data => {
                    return dispatch(fetchedRelatedCases(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function getCaseStagesDetailsApi() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return getCaseStagesDetailsCommon(newData.idToken.jwtToken, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getCaseStagesDetailsCommon(getApiToken(), dispatch);
        }
    }
}

export function fetchedAllStages(data) {
    return {type: 'FETCHED_ALL_STAGES', data};
}

export const FETCHED_RELATED_CASES = 'FETCHED_RELATED_CASES';

export function fetchedRelatedCases(data) {
    return {type: FETCHED_RELATED_CASES, data};
}

export const CLEAR_RELATED_CASES = 'CLEAR_RELATED_CASES';

export function clearRelatedCases() {
    return {type: CLEAR_RELATED_CASES};
}

export function getAllFoodRestrictions() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchFoodRestrictions).then(data => {
                        return dispatch(fetchedAllFoodRestriction(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchFoodRestrictions).then(data => {
                    return dispatch(fetchedAllFoodRestriction(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllFoodRestriction(data) {
    return {type: 'FETCHED_ALL_FOOD_RESTRICTION', data};
}

export function getCaselogs(caseId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.fetchCaseLogs, caseId, "?ues=false").then(data => {
                        return dispatch(fetchCaseLogs(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.fetchCaseLogs, caseId, "?ues=false").then(data => {
                    return dispatch(fetchCaseLogs(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function fetchCaseLogs(data) {
    return {type: 'FETCH_CASE_LOGS', data};
}

export function getOrderlogs(orderId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.fetchOrderLogs, orderId, "?ues=false").then(data => {
                        return dispatch(fetchOrderLogs(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.fetchOrderLogs, orderId, "?ues=false").then(data => {
                    return dispatch(fetchOrderLogs(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }

}

export function fetchOrderLogs(data) {
    return {type: 'FETCH_ORDER_LOGS', data};
}

/***
 * @description: Generic log api call
 * @param data
 * @returns {{data: *, type: string}}
 */
export function fetchCaseLogsWithFilter(data) {
    return {type: 'FETCH_CASE_LOGS_WITH_FILTER', data};
}

export function getCaseLogsWithFilters(caseId, selectedFilters, startIndex, pageLimit = 25) {
    return getLogs(LogCategory.case, caseId, selectedFilters, startIndex, pageLimit);
}

/***
 * @description: CaseFilter API URL helper
 */
function getCaseFilterUrl(logCategory, logCategoryId, selectedFilters, startIndex, pageLimit) {
    let url = ApiCollection.properties.fetchLogs
        .replace("{logCategory}", logCategory)
        .replace("{logCategoryId}", logCategoryId)
        .replace("{startIndex}", startIndex)
        .replace("{pageLimit}", pageLimit);

    const parameterString = LogUtil.getCaseFilterUrlParameterString(selectedFilters);
    if (AppUtil.isAvailable(parameterString)) {
        url += `${parameterString}`;
    }
    return url;
}

function getLogsApi(token, logCategory, logCategoryId, selectedFilters, startIndex, pageLimit, dispatch) {
    const url = getCaseFilterUrl(logCategory, logCategoryId, selectedFilters, startIndex, pageLimit);
    return CommonApiCall.genericApiCall(url, token, "GET").then(responseData => {
        dispatch(fetchCaseLogsWithFilter(responseData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export function getLogs(logCategory, logCategoryId, selectedFilters, startIndex, pageLimit) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getLogsApi(responseData.idToken.jwtToken, logCategory, logCategoryId, selectedFilters, startIndex, pageLimit, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getLogsApi(getApiToken(), logCategory, logCategoryId, selectedFilters, startIndex, pageLimit, dispatch);
        }
    }
}

/***
 *@description: case transporters api
 */
export function getCaseTransporters() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getCaseTransportersApi(getApiToken(), dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getCaseTransportersApi(getApiToken(), dispatch);
        }
    }
}

function getCaseTransportersApi(token, dispatch) {
    const url = `${ApiCollection.properties.fetchOrderPreparationTransporters}?onlyBodyPickup=true`;

    return CommonApiCall.genericApiCall(url, token, "GET").then(responseData => {
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

/***
 *@description: case events api
 */
export function fetchCaseEvents(data) {
    return {type: 'FETCH_CASE_EVENTS', data};
}

function getCaseEventsApi(token, caseId, dispatch) {
    const url = ApiCollection.properties.fetchCaseEvents
        .replace("{caseId}", caseId);

    return CommonApiCall.genericApiCall(url, token, "GET").then(responseData => {
        dispatch(fetchCaseEvents(responseData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

/***
 *@description: case authors api
 */
export function fetchCaseAuthors(data) {
    return {type: 'FETCH_CASE_AUTHORS', data};
}

function getCaseAuthorsApi(token, caseId, dispatch) {
    const url = ApiCollection.properties.fetchCaseAuthors
        .replace("{caseId}", caseId);

    return CommonApiCall.genericApiCall(url, token, "GET").then(responseData => {
        dispatch(fetchCaseAuthors(responseData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export const CaseFilters = {
    events: 1,
    authors: 2
};

function getCaseFilters(caseId, caseFilters) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return (caseFilters === CaseFilters.events) ?
                    getCaseEventsApi(responseData.idToken.jwtToken, caseId, dispatch) :
                    getCaseAuthorsApi(responseData.idToken.jwtToken, caseId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return (caseFilters === CaseFilters.events) ?
                getCaseEventsApi(getApiToken(), caseId, dispatch) :
                getCaseAuthorsApi(getApiToken(), caseId, dispatch);
        }
    }
}

export function getCaseEvents(caseId) {
    return getCaseFilters(caseId, CaseFilters.events);
}

export function getCaseAuthors(caseId) {
    return getCaseFilters(caseId, CaseFilters.authors);
}

export function getAllRelationshipDetails() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchPersonRelationship).then(data => {
                        return dispatch(fetchedAllRelationshipDetails(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchPersonRelationship).then(data => {
                    return dispatch(fetchedAllRelationshipDetails(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllRelationshipDetails(data) {
    return {type: 'FETCHED_ALL_RELATIONSHIP_DETAILS', data};
}

export function getDetailsByPersonNumber(personNumber) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.fetchFromSpar, personNumber).then(data => {
                        if (data.object.found === true) {
                            let address = {};
                            address.address = data.object.address;
                            address.postalCode = data.object.postalCode;
                            address.city = data.object.city;
                            data.address = address;
                            dispatch(getParishNameOfDeceased(data));
                        }
                        return dispatch(onDetailsFromSpar(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.fetchFromSpar, personNumber).then(data => {
                    if (data.object.found === true) {
                        let address = {};
                        address.address = data.object.address;
                        address.postalCode = data.object.postalCode;
                        address.city = data.object.city;
                        data.address = address;
                        dispatch(getParishNameOfDeceased(data));
                    }
                    return dispatch(onDetailsFromSpar(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function onDetailsFromSpar(data) {
    return {type: 'ON_DETAILS_FROM_SPAR', data};
}

export function getDetailsByPersonNumberOfRelative(relative, index) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.fetchFromSpar, relative.personNumber).then(data => {
                        return dispatch(onDetailsFromSparOfRelative(data, index));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.fetchFromSpar, relative.personNumber).then(data => {
                    return dispatch(onDetailsFromSparOfRelative(data, index));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function onDetailsFromSparOfRelative(data, index) {
    return {type: 'ON_DETAILS_FROM_SPAR_OF_RELATIVE', data, index};
}

export function getParishNameOfDeceased(deceasedData) {
    if (deceasedData.address.address !== null && deceasedData.address.postalCode && deceasedData.address.city !== null) {
        return function (dispatch) {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    dispatch(afterRefreshToken(newData));
                    setTimeout(function () {
                        CaseApi.parishNameFromFlax(newData.idToken.jwtToken, deceasedData.address).then(data => {
                            return dispatch(onDetailsFromFlax(data));
                        }).catch(error => {
                            return dispatch(onErrorUnderCase(error));
                        });
                    }, 0);
                }).catch(err => {
                    dispatch(push('/'));
                })
            } else {
                setTimeout(function () {
                    CaseApi.parishNameFromFlax(getApiToken(), deceasedData.address).then(data => {
                        return dispatch(onDetailsFromFlax(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }
        }
    } else {
        bottomCenter().warning("Address not complete")
    }
}

export function onDetailsFromFlax(data) {
    return {type: 'ON_DETAILS_FROM_FLAX', data};
}

function getLabelDetailsApi(token, labelType, inputValue = '', dispatch) {
    const url = ApiCollection.properties.fetchAllLabelUrl.replace("{labelType}", labelType) + "&f_lt=" + inputValue;
    return (CommonApiCall.genericApiCall(url, token, "GET").then(data => {
        dispatch(fetchedAllLabels(data));
        return new Promise((resolve) => setTimeout(() => resolve(data), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function getLabelDetails(labelType, inputValue = '') {
    return async function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return await (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getLabelDetailsApi(responseData.idToken.jwtToken, labelType, inputValue, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return await getLabelDetailsApi(getApiToken(), labelType, inputValue, dispatch);
        }
    }
}

export function fetchedAllLabels(data) {
    return {type: 'FETCHED_ALL_LABELS', data};
}

export function getAllUserDetails() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchAllUserUrl).then(data => {
                        return dispatch(fetchedAllUsersForCases(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchAllUserUrl).then(data => {
                    return dispatch(fetchedAllUsersForCases(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllUsersForCases(data) {
    return {type: 'FETCHED_ALL_USERS_FOR_CASES', data};
}

export function getAllCountries() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchCountries)
                        .then(data => {
                            return dispatch(fetchedAllCountries(data));
                        }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchCountries)
                    .then(data => {
                        return dispatch(fetchedAllCountries(data));
                    }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllCountries(data) {
    return {type: 'FETCHED_ALL_COUNTRIES', data};
}

const getAllCountiesApi = async (countyParentId) => {
    const urlSuffix = ApiCollection.properties.fetchCounties.replace("{parentId}", countyParentId);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
};

export const getAllCounties = (countyParentId) => {
    return (dispatch) => {
        getAllCountiesApi(countyParentId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                return dispatch(fetchedAllCounties(resp.data));
            }
        });
    }
}

export function fetchedAllCounties(data) {
    return {type: 'FETCHED_ALL_COUNTIES', data};
}

/***
 * @description: Lost reasons api
 */

export function fetchLostReasons(data) {
    return {type: 'FETCHED_LOST_REASONS', data};
}

export function fetchNoCaseReasons(data) {
    return {type: 'FETCHED_NO_CASE_REASONS', data};
}


function getLostReasonsApi(token, dispatch) {
    const url = ApiCollection.properties.lostReasons;
    return CommonApiCall.genericApiCall(url, token, "GET").then(responseData => {
        dispatch(fetchLostReasons(responseData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

function getNoCaseReasonsApi(token, dispatch) {
    const url = ApiCollection.properties.noCaseReasons;
    return CommonApiCall.genericApiCall(url, token, "GET").then(responseData => {
        dispatch(fetchNoCaseReasons(responseData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}


export function getLostOrNoCaseReasons(stage) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return (stage === CaseUtil.caseStages.lost.value) ? getLostReasonsApi(responseData.idToken.jwtToken, dispatch) : getNoCaseReasonsApi(responseData.idToken.jwtToken, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return (stage === CaseUtil.caseStages.lost.value) ? getLostReasonsApi(getApiToken(), dispatch) : getNoCaseReasonsApi(getApiToken(), dispatch);
        }
    }
}


export function getOrderJson(orderTypeId, placeId) {
    let url = ApiCollection.properties.fetchDummyJsonForOrder.replace("{ORDER_TYPE_ID}", orderTypeId);
    if (placeId) {
        url += `&placeid=${placeId}`;
    }
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.fetchOrderJson(newData.idToken.jwtToken, url).then(data => {
                        return dispatch(fetchedOrderJson(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.fetchOrderJson(getApiToken(), url).then(data => {
                    return dispatch(fetchedOrderJson(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export const FETCHED_ORDER_JSON = 'FETCHED_ORDER_JSON';

export function fetchedOrderJson(data) {
    return {type: FETCHED_ORDER_JSON, data};
}

export function getFuneralPackage(data) {
    const urlSuffix = ApiCollection.properties.fetchFuneralPackage;
    const body = data;
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.fetchFuneralData(newData.idToken.jwtToken, urlSuffix, body).then(data => {
                        return dispatch(fetchedOrderJson(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.fetchFuneralData(getApiToken(), urlSuffix, body).then(data => {
                    return dispatch(fetchedOrderJson(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

function getBillingContactsOfCaseApi(token, caseId, dispatch) {
    return (CommonApiCall.fetch_Data_By_Id(token, ApiCollection.properties.caseSourceUrl, caseId).then(data => {
        dispatch(fetchBillingContactForCase(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function getBillingContactsOfCase(caseId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(respData => {
                dispatch(afterRefreshToken(respData));
                return getBillingContactsOfCaseApi(respData.idToken.jwtToken, caseId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getBillingContactsOfCaseApi(getApiToken(), caseId, dispatch);
        }
    };
}

export function fetchBillingContactForCase(data) {
    return {type: 'FETCHED_ALL_BILLING_CONTACT_FOR_CASES', data};
}

export function setOwnerAndLossReason(user, reason) {
    return {type: 'SET_OWNER_AND_LOSS_REASON', user, reason};
}

export function clearOwnerAndLossReason() {
    return {type: 'CLEAR_OWNER_AND_LOSS_REASON'};
}

export function clearBillingcontactAndDeliveryMethod() {
    return {type: 'CLEAR_BILLING_CONTACT_AND_DELIVERY_METHOD'};
}

/* Delete case start */
export function deleteCaseInfo(deletedData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                CommonApiCall.deleteItem_Axios(newData.idToken.jwtToken, ApiCollection.properties.caseSourceUrl, deletedData.id).then(respData => {
                    dispatch(updateColumnPageDataBySearch(deletedData.id, respData, CRUDOperation.DELETE, storingKey));
                    return dispatch(caseDeletedSuccessfully(respData, deletedData));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            CommonApiCall.deleteItem_Axios(getApiToken(), ApiCollection.properties.caseSourceUrl, deletedData.id).then(respData => {
                dispatch(updateColumnPageDataBySearch(deletedData.id, respData, CRUDOperation.DELETE, storingKey));
                return dispatch(caseDeletedSuccessfully(respData, deletedData));
            }).catch(error => {
                return dispatch(onErrorUnderCase(error));
            });
        }
    }
}

export const CASE_DELETED_SUCCESSFULLY = 'CASE_DELETED_SUCCESSFULLY';

export function caseDeletedSuccessfully(payload, deletedData) {
    return {type: CASE_DELETED_SUCCESSFULLY, payload, deletedData};
}

/* Add updateCaseInfo*/

function genericUpdateCaseApi(token, reqPayload, unfilteredReqPayload, storingKey, isColumnWithUniqueStage, dispatch) {
    return (CommonApiCall.updateDataById_Axios(token, ApiCollection.properties.caseSourceUrl, reqPayload).then(respData => {
        dispatch(updateCaseInfoIntoExistingCase(respData, unfilteredReqPayload));
        dispatch(updateColumnPageDataBySearch(reqPayload.id, respData, CRUDOperation.UPDATE, storingKey, {isColumnWithUniqueStage: isColumnWithUniqueStage}));
        return new Promise((resolve, reject) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        console.log("errorOnUpdate", error);
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function updateCaseApiCall(reqPayload, unfilteredReqPayload, storingKey, isColumnWithUniqueStage) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return genericUpdateCaseApi(newData.idToken.jwtToken, reqPayload, unfilteredReqPayload, storingKey, isColumnWithUniqueStage, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return genericUpdateCaseApi(getApiToken(), reqPayload, unfilteredReqPayload, storingKey, isColumnWithUniqueStage, dispatch);
        }
    }
}

export const UPDATE_CASE_INFO_INTO_EXISTING_CASE = 'UPDATE_CASE_INFO_INTO_EXISTING_CASE';
export const UPDATE_CASE_INFO_INTO_EXISTING_CASE_OLD_APPROACH = 'UPDATE_CASE_INFO_INTO_EXISTING_CASE_OLD_APPROACH';

export function updateCaseInfoIntoExistingCase(payload, unfilteredPayload) {
    return {type: UPDATE_CASE_INFO_INTO_EXISTING_CASE, payload, unfilteredPayload};
}

export function updateUpdatingStatus(data) {
    return {type: 'UPDATE_UPDATING_STATUS', data};
}

function genericCreateCaseApi(token, reqData, storingKey, dispatch) {
    return (CommonApiCall.addNewItems_Axios(token, ApiCollection.properties.caseSourceUrl, reqData).then(respData => {
        dispatch(createdCaseInfo(respData));
        dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.CREATE, storingKey));
        return new Promise((resolve, reject) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function createCaseApiCall(reqData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return genericCreateCaseApi(newData.idToken.jwtToken, reqData, storingKey, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return genericCreateCaseApi(getApiToken(), reqData, storingKey, dispatch);
        }
    }
}

export const CREATED_CASE_INFO = 'CREATED_CASE_INFO';

export function createdCaseInfo(payload) {
    return {type: CREATED_CASE_INFO, payload};
}

export const getUrlOfUpdateCaseStage = (caseId, stage, extraParams) => {
    const {owner, collaboratorId, lossReason, countyId} = extraParams;

    let url = ApiCollection.properties.updateStage
        .replace("{object}", "case")
        .replace("{objectId}", caseId)
        .replace("{newStageId}", stage);

    if (AppUtil.isAvailable(owner)) {
        url = url + '&assignedUserId=' + owner
    }
    if (AppUtil.isAvailable(collaboratorId)) {
        url = url + '&collaboratorUserId=' + collaboratorId
    }
    if (AppUtil.isAvailable(countyId)) {
        url = url + '&countyId=' + countyId
    }
    if (AppUtil.isAvailable(lossReason)) {
        url = url + '&lossReason=' + lossReason
    }
    return url;
};

export const getBodyOfUpdateCaseStage = (orderDetails, version) => {
    const body = orderDetails;
    if (AppUtil.isAvailable(version)) {
        body["version"] = version;
    }
    return body;
};

export function updateCaseStageApi(caseId, stage, url, body) {
    const storingKey = stage;
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.updateCaseStage(newData.idToken.jwtToken, url, body).then(respData => {
                        if (respData.success && AppUtil.isAvailable(respData.object)) {
                            dispatch(updatePropertyColumnPageDataBySearch(caseId, ColumnPageMapperProps.VERSION, respData.object.version, storingKey));
                        }
                        return dispatch(updatedCaseStage(respData));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.updateCaseStage(getApiToken(), url, body).then(respData => {
                    if (respData.success && AppUtil.isAvailable(respData.object)) {
                        dispatch(updatePropertyColumnPageDataBySearch(caseId, ColumnPageMapperProps.VERSION, respData.object.version, storingKey));
                    }
                    return dispatch(updatedCaseStage(respData));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export const UPDATED_CASE_STAGE = "UPDATED_CASE_STAGE";

export function updatedCaseStage(data) {
    return {type: UPDATED_CASE_STAGE, data};
}

function getUrlOfUpdateOrderStage(orderId, stage, billingContactId, deliveryMethod, version) {
    let url = ApiCollection.properties.updateStage
        .replace("{object}", "order")
        .replace("{objectId}", orderId)
        .replace("{newStageId}", stage);

    if (AppUtil.isAvailable(billingContactId)) {
        url += '&billingContactId=' + billingContactId;
    }
    if (AppUtil.isAvailable(deliveryMethod)) {
        url += '&deliveryMethod=' + deliveryMethod;
    }
    if (AppUtil.isAvailable(version)) {
        url += '&version=' + version;
    }
    return url;
}

export function updateOrderStageApi(orderId, stage, billingContactId, deliveryMethod, version) {
    // console.log("billingContactId", billingContactId, deliveryMethod);
    const url = getUrlOfUpdateOrderStage(orderId, stage, billingContactId, deliveryMethod, version);
    const storingKey = stage;
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.updateOrderStage(newData.idToken.jwtToken, url).then(respData => {
                        if (respData.success && AppUtil.isAvailable(respData.object)) {
                            dispatch(updatePropertyColumnPageDataBySearch(respData, ColumnPageMapperProps.VERSION, respData.object.version, storingKey));
                        }
                        return dispatch(updatedCaseStage(respData));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.updateOrderStage(getApiToken(), url).then(respData => {
                    if (respData.success && AppUtil.isAvailable(respData.object)) {
                        dispatch(updatePropertyColumnPageDataBySearch(orderId, ColumnPageMapperProps.VERSION, respData.object.version, storingKey));
                    }
                    return dispatch(updatedCaseStage(respData));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

/***
 * @description: Logs and Documents Count api
 */
function getCountersApi(token, caseId, headerClicked, dispatch) {
    const url = CaseApi.getCounterURL(headerClicked, caseId);
    return CaseApi.getCounters(token, url).then(data => {
        if (data.success === true) {
            return dispatch(setCounters(data, headerClicked));
        } else {
            return dispatch(onErrorUnderCase(data));
        }
    }).catch(error => {
        return dispatch(onErrorUnderCase(error));
    });
}

export function getLogsAndDocsCount(caseId, headerClicked) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return getCountersApi(newData.idToken.jwtToken, caseId, headerClicked, dispatch);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            return getCountersApi(getApiToken(), caseId, headerClicked, dispatch);
        }
    }
}

export const SET_COUNTERS = "SET_COUNTERS";

export function setCounters(data, headerClicked) {
    return {type: SET_COUNTERS, data, headerClicked};
}

export function saveOrderRelative(data) {
    return {type: 'SAVE_ORDER_RELATIVE', data};
}

export function saveBillingContactIdForOrders(data) {
    return {type: 'SAVE_BILLING_CONTACT_ID_FOR_ORDERS', data};
}

export function saveAssignedUserIdForOrders(data) {
    return {type: 'SAVE_ASSIGNED_USER_ID_FOR_ORDERS', data};
}

export function saveAssignedUserIdForOrdersInCases(data) {
    return {type: 'SAVE_ASSIGNED_USER_ID_FOR_ORDERS_IN_CASES', data};
}

export function saveBillingContactIdForOrdersInCases(data, orderId) {
    return {type: 'SAVE_BILLING_CONTACT_ID_FOR_ORDERS_IN_CASES', data, orderId};
}

export const UPDATE_WORK_LOG_PDF_DETAILS = 'UPDATE_WORK_LOG_PDF_DETAILS';
export const updateWorklogPdfDetails = (property, payload, matchId) => {
    return {type: UPDATE_WORK_LOG_PDF_DETAILS, property, payload, matchId};
};

export function saveDeliveryMethodForOrders(data) {
    return {type: 'SAVE_DELIVERY_METHOD_FOR_ORDERS', data};
}

export function saveBillingContactOrderDrag(data) {
    return {type: 'SAVE_BILLING_CONTACT_ORDER_DRAG', data};
}

export function saveDeliveryMethodOrderDrag(data) {
    return {type: 'SAVE_DELIVERY_METHOD_ORDER_DRAG', data};
}

function emailSummaryApi(token, caseId, bodyData, dispatch) {
    setTimeout(function () {
        CaseApi.emailSummary(token, caseId, bodyData).then(reponseData => {
            console.log("UpdatedDataCase", reponseData);
            if (reponseData.success === true) {
                return dispatch(emailCaseSummary(reponseData));
            } else {
                return dispatch(onErrorUnderCase(reponseData));
            }

        }).catch(error => {
            return dispatch(onErrorUnderCase(error));
        });
    }, 0);
}

export function sendEmailSummary(caseId, bodyData) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));

                emailSummaryApi(responseData.idToken.jwtToken, caseId, bodyData, dispatch);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            console.log("caseId", caseId);
            emailSummaryApi(getApiToken(), caseId, bodyData, dispatch);
        }
    }
}

export function emailCaseSummary(data) {
    return {type: 'EMAIL_CASE_SUMMARY', data};
}

export function sendEmailOrderSummary(relativeId, orderId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.orderEmailSummary(newData.idToken.jwtToken, relativeId, orderId).then(data => {
                        console.log("UpdatedDataCase", data);
                        if (data.success === true) {
                            return dispatch(emailOrderSummary(data));
                        } else {
                            return dispatch(onErrorUnderCase(data));
                        }

                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.orderEmailSummary(getApiToken(), relativeId, orderId).then(data => {
                    console.log("UpdatedDataCase", data);
                    if (data.success === true) {
                        return dispatch(emailOrderSummary(data));
                    } else {
                        return dispatch(onErrorUnderCase(data));
                    }

                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function emailOrderSummary(data) {
    return {type: 'EMAIL_CASE_SUMMARY', data};
}

export const ON_ERROR_UNDER_CASE = "ON_ERROR_UNDER_CASE";

export function onErrorUnderCase(data) {
    return {type: ON_ERROR_UNDER_CASE, data};
}

export function changeDeceasedName(name, value) {
    return {type: 'CHANGE_DECEASED_NAME', name, value};
}

export function changePersonNumberInfo(data) {
    return {type: 'CHANGE_PERSON_NUMBER_INFO', data};
}

export function changeAddressInfo(data) {
    return {type: 'CHANGE_ADDRESS_INFO', data};
}

export function changeAddress2Info(data) {
    return {type: 'CHANGE_ADDRESS2_INFO', data};
}

export function changePostalCodeInfo(data) {
    return {type: 'CHANGE_POSTAL_CODE_INFO', data};
}

export function changeStatusInfo(data) {
    return {type: 'CHANGE_STATUS_INFO', data};
}

export function changeAddressOfPassing(data) {
    return {type: 'CHANGE_ADDRESS_OF_PASSING_INFO', data};
}

export function changeAddress2OfPassing(data) {
    return {type: 'CHANGE_ADDRESS2_OF_PASSING_INFO', data};
}

export function changePostalCodeOfPassing(data) {
    return {type: 'CHANGE_POSTALCODE_OF_PASSING_INFO', data};
}

export function changeCityOfPassing(data) {
    return {type: 'CHANGE_CITY_OF_PASSING_INFO', data};
}

export function onChangeCaseisMemberSwedishChurchStatus(data) {
    return {type: 'ON_CHANGE_CASE_ISMEMBER_SWEDISH_CHURCH_STATUS', data};
}

export function onChangeCaseDeceasedDropdownOptionStatus(data, fieldName) {
    return {type: 'ON_CHANGE_CASE_DECEASED_DROPDOWN_OPTION_STATUS', data, fieldName};
}

export function addLabelToState(data) {
    return {type: 'ADD_LABEL_TO_STATE', data};
}

export function addUserToState(data) {
    return {type: 'ADD_USER_TO_STATE', data};
}

export const ADD_COLLABORATOR_USER_TO_STATE = 'ADD_COLLABORATOR_USER_TO_STATE'

export function addCollaboratorUserToState(data) {
    return {type: ADD_COLLABORATOR_USER_TO_STATE, data};
}

export function addUserToStateInOrder(data) {
    return {type: 'ADD_USER_TO_STATE_ORDER', data};
}

export function onChangeCityInfo(data) {
    return {type: 'ON_CHANGE_CITY_INFO', data};
}

export function onChangeOtherDetailInfo(data) {
    return {type: 'ON_CHANGE_OTHER_DETAILS_INFO', data};
}

export function onChangeCountryInfo(data) {
    return {type: 'ON_CHANGE_COUNTRY_INFO', data};
}

export function onChangeCountyInfo(data) {
    return {type: 'ON_CHANGE_COUNTY_INFO', data};
}

export function setLocationOfRemain(data) {
    return {type: 'SET_LOCATION_OF_REMAIN', data};
}

export function setLocationOfBodyPickup(data) {
    return {type: 'SET_LOCATION_OF_BODY_PICKUP', data};
}

export function setBodyDestination(data) {
    return {type: 'SET_BODY_DESTINATION', data};
}

export function onChangeCountryOfPassing(data) {
    return {type: 'ON_CHANGE_COUNTRY_INFO_OF_PASSING', data};
}

export function onChangeCountyOfPassing(data) {
    return {type: 'ON_CHANGE_COUNTY_INFO_OF_PASSING', data};
}

export function onChangeOfPhoneOfPassing(data) {
    return {type: 'ON_CHANGE_PHONE_OF_PASSING', data};
}

export function setLocationOfPasing(data) {
    return {type: 'CHANGE_LOCATION_OF_PASSING', data}
}

export function setParishData(data) {
    return {type: 'SET_PARISH_DATA', data};
}

export function removeLabelFromView(data, index) {
    return {type: 'REMOVE_LABEL_FROM_VIEW', data, index};
}

export function newFormForRelative(data) {
    return {type: 'NEW_FORM_FOR_RELATIVE', data};
}

export const UPDATE_VALIDATION_ERRORS_INFO = "UPDATE_VALIDATION_ERRORS_INFO";

export function updateValidationErrorsInfo(payload) {
    return {type: UPDATE_VALIDATION_ERRORS_INFO, payload};
}

export function changeRelName(data, index) {
    return {type: 'CHANGE_REL_NAME', data, index};
}

export function changeRelNameByOrganisationNum(data, id, index, organisationNumber) {
    return {type: 'CHANGE_REL_NAME', data, id, index, organisationNumber};
}

export function changeRelPhoneDetails(data, index) {
    return {type: 'CHANGE_REL_PHONE_DETAILS', data, index};
}

export function changeRelMobileDetails(data, index) {
    return {type: 'CHANGE_REL_MOBILE_DETAILS', data, index};
}

export function changeRelEmailDetails(data, index) {
    return {type: 'CHANGE_REL_EMAIL_DETAILS', data, index};
}

export function setRelationshipId(data, index) {
    return {type: 'SET_RELATIONSHIP_ID', data, index};
}

export function setCountryId(data, index) {
    return {type: 'SET_COUNTRY_ID', data, index};
}

export function setCountyId(data, index) {
    return {type: 'SET_COUNTY_ID', data, index};
}

export function setRelationshipIdRelative(data, relativeId) {
    return {type: 'SET_RELATIONSHIP_ID_RELATIVE', data, relativeId};
}

export function setRelComment(data, index) {
    return {type: 'SET_REL_COMMENT', data, index};
}

export function setRelPin(data, index) {
    return {type: 'SET_REL_PIN', data, index};
}

export function setOrganisationNumber(data, index) {
    return {type: 'SET_ORGANISATION_NUMBER', data, index}
}

export function setRelAddress(data, index, field) {
    return {type: 'SET_REL_ADDRESS', data, index, field};
}

export function setRelpostalCode(data, index) {
    return {type: 'SET_REL_POSTAL_CODE', data, index};
}

export function setRelDateOfBirth(data, index) {
    return {type: 'SET_REL_DATE_OF_BIRTH', data, index};
}

export function setRelplaceName(data, index) {
    return {type: 'SET_REL_PLACE_NAME', data, index};
}

export function setRelBillingContactStatus(data, index) {
    return {type: 'SET_REL_BILLING_CONTACT_STATUS', data, index};
}

export function setRelIsPrimaryContactStatus(data, index) {
    return {type: 'SET_REL_IS_PRIMARY_CONTACT_STATUS', data, index};
}

export function setUkContactProperties(data, index) {
    return {type: 'SET_UK_CONTACT_PROPERTIES', data, index};
}

export function setUKPaymentMethod(data, index) {
    return {type: 'SET_UKPAYMENT_METHOD', data, index};
}

export function setRelNationalInsuranceNumber(data, index) {
    return {type: 'SET_REL_NATIONAL_INSURANCE_NUMBER', data, index};
}

export function setRelFinancialAssistanceDetails(data, index) {
    return {type: 'SET_REL_FINANCIAL_ASSISTANCE_DETAILS', data, index};
}

export const SET_REL_COMPANY_NAME = 'SET_REL_COMPANY_NAME';

export function setRelCompanyName(data, index) {
    return {type: SET_REL_COMPANY_NAME, data, index};
}

export const CLEAR_UK_PAYMENT_DETAILS = 'CLEAR_UK_PAYMENT_DETAILS';

export function clearUKPaymentDetails(idToMatch) {
    return {type: CLEAR_UK_PAYMENT_DETAILS, idToMatch};
}

export function setRelIsGuestStatus(data, index) {
    return {type: 'SET_REL_IS_GUEST_STATUS', data, index};
}

export function assignSelectedDateOfBirth(data) {
    return {type: 'ASSIGN_SELECTED_DATE_OF_BIRTH', data};
}

export const REMOVE_FILTER_APPLIED = 'REMOVE_FILTER_APPLIED';

export function removeFilterApplied(data) {
    return {type: REMOVE_FILTER_APPLIED, data};
}

export function loadLoadingView(data) {
    return {type: 'LOAD_LOADING_VIEW', data};
}

export function removeRelativeInfo(data, indexInfo) {
    return {type: 'REMOVE_RELATIVE_INFO', data, indexInfo};
}

export function showPopupForRemoveRelative(data) {
    return {type: 'SHOW_POPUP_FOR_REMOVE_RELATIVE', data};
}

export const FILTER_APPLIED_TO_COLUMN = "FILTER_APPLIED_TO_COLUMN";

export function filterAppliedToColumn(data) {
    return {type: FILTER_APPLIED_TO_COLUMN, data};
}

export const CREATE_NOTES_CONTENT = "CREATE_NOTES_CONTENT";

export function createNotesContent(data) {
    return {type: CREATE_NOTES_CONTENT, data};
}

export function saveTempNote(data) {
    return {type: 'SAVE_TEMP_NOTE', data};
}

export function saveCaseTempNote(data) {
    return {type: 'SAVE_CASE_TEMP_NOTE', data};
}

export function removeNotesItem(data, index) {
    return {type: 'REMOVE_NOTES_ITEM', data, index};
}

export function setCallDetails(data) {
    return {type: 'SET_CALL_DETAILS', data};
}

export function removeCallFromCase(data, index) {
    return {type: 'REMOVE_CALL_FROM_CASE', data, index};
}

export function mergeCaseCall(currentCaseId, targetCaseId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.mergeCaseApiCall(newData.idToken.jwtToken, currentCaseId, targetCaseId).then(data => {
                        return dispatch(mergedCaseData(data, currentCaseId));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.mergeCaseApiCall(getApiToken(), currentCaseId, targetCaseId).then(data => {
                    return dispatch(mergedCaseData(data, currentCaseId));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

/***
 * @description: FENIX-4350: Todo template api call will be deprecated
 */
export function getAllCaseTodoTemplates(todoTemplateType) {
    const url = ApiCollection.properties.fetchCaseTodoTemplates.replace('{todoTemplateType}', todoTemplateType);
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.fetchCaseTodoTemplates(newData.idToken.jwtToken, url)
                        .then(data => {
                            return dispatch(fetchAllTodoTemplates(data));
                        })
                        .catch(error => {
                            return dispatch(onErrorUnderCase(error));
                        });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.fetchCaseTodoTemplates(getApiToken(), url)
                    .then(data => {
                        return dispatch(fetchAllTodoTemplates(data));
                    })
                    .catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
            }, 0);
        }
    }
}

export function getTodoTemplatesItems(caseId, templateId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CaseApi.fetchTodoTemplatesItems(newData.idToken.jwtToken, ApiCollection.properties.fetchCaseTodoTemplateItems, caseId, templateId)
                        .then(data => {
                            return dispatch(fetchAllTodoTemplateItems(data));
                        })
                        .catch(error => {
                            return dispatch(onErrorUnderCase(error));
                        });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CaseApi.fetchTodoTemplatesItems(getApiToken(), ApiCollection.properties.fetchCaseTodoTemplateItems, caseId, templateId)
                    .then(data => {
                        return dispatch(fetchAllTodoTemplateItems(data));
                    })
                    .catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
            }, 0);
        }
    }
}

export const MERGED_CASE_DATA = "MERGED_CASE_DATA";

export function mergedCaseData(data, currentCaseId) {
    return {type: MERGED_CASE_DATA, data, currentCaseId};
}

export function setCallerInfoForCase(data, callId, index) {
    return {type: 'SET_CALLER_INFO_FOR_CASE', data, callId, index};
}

export function setCallerLocationForCase(data, callId) {
    return {type: 'SET_CALLER_LOCATION_FOR_CASE', data, callId};
}

export function setCallerContactForCase(data, callId) {
    return {type: 'SET_CALLER_CONTACT_FOR_CASE', data, callId};
}

export function handleSalesOrder(data) {
    return {type: 'HANDLE_SALES_ORDER', data};
}

export function changeGuestDetails(data, index, field) {
    return {type: 'CHANGE_GUEST_DETAILS', data, index, field};
}

export function changeGuestFoodRestrictionDetails(index, data) {
    return {type: 'CHANGE_GUEST_FOOD_RESTRICTION_DETAILS', index, data};
}

export function addNewGuest(data) {
    return {type: 'ADD_NEW_GUEST', data};
}

export function saveTodo(data) {
    return {type: 'SAVE_TODO', data};
}

export function deleteTodo(indexPosition, todoType) {
    return {type: 'DELETE_TODO', indexPosition, todoType};
}

export function fetchAllTodoTemplates(data) {
    return {type: 'TODO_TEMPLATES', data};
}

export function fetchAllTodoTemplateItems(data) {
    return {type: 'TODO_TEMPLATE_ITEMS', data};
}

export function updateSelectedTemplates(data) {
    return {type: 'UPDATE_CASE_SELECTED_TEMPLATE', data};
}

/***
 * @description: Memorial Fund types api
 */
export function updateGuestMail(data) {
    return {type: 'UPDATE_GUEST_MAIL', data};
}

function sendGuestMailApi(token, caseId, guestId, dispatch) {
    let url = ApiCollection.properties.sendGuestMail;
    url = url.replace("{caseId}", caseId).replace("{guestId}", guestId);
    return CommonApiCall.genericApiCall(url, token, "POST").then(data => {
        dispatch(updateGuestMail(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 500));
    }).catch(error => {
        dispatch(onErrorUnderCaseOrder(error));
        return Promise.reject(error);
    });
}

export function sendGuestMail(caseId, guestId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return sendGuestMailApi(responseData.idToken.jwtToken, caseId, guestId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return sendGuestMailApi(getApiToken(), caseId, guestId, dispatch);
        }
    }
}

export function setpaymentDetailsForCremationPapers(data) {
    return {type: 'SET_PAYMENT_DETIALS_FOR_CREMATION_PAPERS', data}
}

export function setGreenFormOption(data) {
    return {type: 'SET_GREEN_FORM_OPTIONS', data}
}

export function setCauseOfDeath(data) {
    return {type: 'SET_CAUSE_OF_DEATH', data};
}

export function setCremationPaidBy(data) {
    return {type: 'SET_CREMATION_PAID_BY', data};
}

export const UPDATE_DECEASED_PROPERTY = "UPDATE_DECEASED_PROPERTY";

export function updateDeceasedProperty(property, payload) {
    return {type: UPDATE_DECEASED_PROPERTY, property, payload}
}

export const UPDATE_DECEASED_DATE_OF_PASSING = "UPDATE_DECEASED_DATE_OF_PASSING";

export function updateDateOfPassing(data, dateUnit) {
    return {type: UPDATE_DECEASED_DATE_OF_PASSING, data, dateUnit};
}

export const UPDATE_DECEASED_DATE_OF_BODY_PICKUP = "UPDATE_DECEASED_DATE_OF_BODY_PICKUP";

export function updateDateOfBodyPickup(data, dateUnit) {
    return {type: UPDATE_DECEASED_DATE_OF_BODY_PICKUP, data, dateUnit};
}

export const SET_TRANSPORTER_PICKUP = "SET_TRANSPORTER_PICKUP";

export function setTransporterPickupInfo(data) {
    return {type: SET_TRANSPORTER_PICKUP, data};
}

/***
 * @description: Deadline related Actions
 */
export function updateDeadlineDate(data, isManual) {
    return updateDeadlineDetails(data, 'date', isManual);
}

export function updateDeadlineTime(data, isManual) {
    return updateDeadlineDetails(data, 'time', isManual);
}

export function updateDeadlineDuringCancel(data, isManual) {
    return updateDeadlineDetails(data, 'cancel', isManual);
}

export function updateDeadlineDuringReset(data, isManual) {
    return updateDeadlineDetails(data, 'reset', isManual);
}

export const UPDATE_DEADLINE_DETAILS = "UPDATE_DEADLINE_DETAILS";

function updateDeadlineDetails(payload, dateUnit, isManual) {
    return {type: UPDATE_DEADLINE_DETAILS, payload, dateUnit, isManual};
}

export function updateInitialDeadlineDate(data) {
    return {type: 'UPDATE_INITIAL_DEADLINE_DATE', data};
}

export const UPDATE_DEADLINE_EDIT_BUTTON = "UPDATE_DEADLINE_EDIT_BUTTON";

export function updateDeadlineEditButton(data) {
    return {type: UPDATE_DEADLINE_EDIT_BUTTON, data};
}

export const UPDATE_DEADLINE_RESET_BUTTON = "UPDATE_DEADLINE_RESET_BUTTON";

export function updateDeadlineResetButton(data) {
    return {type: UPDATE_DEADLINE_RESET_BUTTON, data};
}

export const DISABLE_CASE_SAVE_BUTTON = "DISABLE_CASE_SAVE_BUTTON";

export function disableCaseSaveButton(status) {
    return {type: DISABLE_CASE_SAVE_BUTTON, payload: status};
}

export const CLEAR_CASE_RELATIVES_WITH_EMAIL_ID = "CLEAR_CASE_RELATIVES_WITH_EMAIL_ID";

export function clearCaseRelativesWithEmailId() {
    return {type: CLEAR_CASE_RELATIVES_WITH_EMAIL_ID, payload: []};
}

export const CHANGE_DECEASED_PROPERTY = "CHANGE_DECEASED_PROPERTY";

export function changeDeceasedProperty(name, value) {
    return {type: CHANGE_DECEASED_PROPERTY, name, value};
}

export const REVOKE_ACCESS_LEVEL = "REVOKE_ACCESS_LEVEL";

export function changeAccessLevel(id, caseRelativeId, orderId) {
    return {type: REVOKE_ACCESS_LEVEL, id, caseRelativeId, orderId};
}

export const SET_MANUALLY_SELECTED_CHILD_COMPONENT = "SET_MANUALLY_SELECTED_CHILD_COMPONENT";

export function setManuallySelectedChildComponent(data) {
    return {type: SET_MANUALLY_SELECTED_CHILD_COMPONENT, data};
}

export function updateCaseConnectInfo(property, payload) {
    return {type: UPDATE_CASE_CONNECTION_INFO, property, payload}
}

export function updateCaseProbateProperty(property, payload) {
    return {type: UPDATE_CASE_PROBATE_PROPERTY, property, payload}
}

export const SET_DOCUMENT = "SET_DOCUMENT";

export const ADD_CASE_COUNTY_TO_STATE = 'ADD_CASE_COUNTY_TO_STATE'

export function addCaseCountyToState(data) {
    return {type: ADD_CASE_COUNTY_TO_STATE, data};
}

export const UPDATE_CASE_VERSION = "UPDATE_CASE_VERSION";

export function updateCaseVersion(payload, caller) {
    return {type: UPDATE_CASE_VERSION, payload, caller};
}

export const UPDATE_CASE_GENERIC_PROPERTY = "UPDATE_CASE_GENERIC_PROPERTY";
export const updateCaseGenericProperty = (payload, property) => {
    return {type: UPDATE_CASE_GENERIC_PROPERTY, payload, property};
}

export const UPDATE_CASE_ORDER_PROPERTY = "UPDATE_CASE_ORDER_PROPERTY";
export const updateCaseOrderProperty = (payload, property, orderId) => {
    return {type: UPDATE_CASE_ORDER_PROPERTY, payload, property, orderId};
}
