import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {reduxForm} from "redux-form";
import FormValidator from "../common/formValidator";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import $ from "jquery";
import CaseHeaderSection from "./caseHeaderSection";
import Utility from "../../api/utilLanguage";
import DisplayNothing from "../common/nothingToDisplay";
import PopupForEmailSend from "../common/popups/popupForEmailSend";
import RefreshToken from "../../api/validateToken";
import ErrorUtil from "../common/errorUtil";
import {getApiToken} from "../common/localStorageUtil";
import AppUtil from "../common/appUtil";
import ValidationUtil, {allowNumberAndDash, ValidationErrors} from "../common/validationUtil";
import DateUtilExt from "../common/dateUtil/dateUtilExt";
import ValidationAndVisibilityRule, {isFieldEnabled} from "../common/ValidationAndVisibilityRule";
import CaseUtil from "../common/caseUtil";
import LockCaseUtil from "./lockCase/util/lockCaseUtil";
import Deceased from "./caseDeceased/ui/deceased"
import {DeceasedUtil} from "./caseDeceased/deceasedUtil";
import RelativeUtil from "./relative/relativeUtil";
import Select from "react-select";
import ApiCollection from "../../api/apiCollections";
import {LocationTypes} from "../../api/searchService";

const UK_DECEASED_GREENFORM = "greenFormWith";
const UK_DECEASED_CAUSEOFDEATH = "causeOfDeath";
const UK_DECEASED_CREMATIONPAIDBY = "cremationPaperPaidBy";
const UK_DECEASED_LOCATION_OF_PASSING = "locationOfPassing";

class CaseDeceasedComponent extends React.Component {

    constructor(props) {
        super(props);
        this.selectedDate = '';
        this.selectedDateOfBodyPickup = '';
        this.dateOfBirth = '';
        this.locationOfPassingOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getLocationOfPassingOptions());
        this.causeOfDeathOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getCauseOfDeathOptions());
        this.cremationPaidByOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getCremationPaperPaidByOptions());
        this.greenFormWithOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getGreenFormWithOptions()).sort((a, b) => {
            return a.label > b.label ? 1 : -1;
        });
        this.backOrDeleteKeyPress = false;
        this.ordersData = null;
        this.state = {
            inputValue: '',
            passingLocationValue: [],
            causeOfDeath: [],
            cremationPaidByValue: [],
            greenFormValue: [],
            resultData: [],
            initialData: [],
            locationOfBodyPickupInitialData: [],
            bodyDestinationInitialData: [],
            resultParishData: [],
            disablePersonButton: true,
            disableParishButton: true,
            personNumber: null,
            phoneOfPassing: null,
            transporters: [],
            bodyPickupTransporter: null
        }
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.selectedCaseData.deceased === undefined || this.props.selectedCaseData.deceased.dateOfPassing === undefined || this.props.selectedCaseData.deceased.dateOfPassing === null) {
            this.selectedDate = "";
        } else {
            this.selectedDate = this.props.selectedCaseData.deceased.dateOfPassing;
        }

        if (this.props.selectedCaseData.deceased === undefined || this.props.selectedCaseData.deceased.dateOfBodyPickup === undefined || this.props.selectedCaseData.deceased.dateOfBodyPickup === null) {
            this.selectedDateOfBodyPickup = "";
        } else {
            this.selectedDateOfBodyPickup = this.props.selectedCaseData.deceased.dateOfBodyPickup;
        }

        if (this.props.selectedCaseData.deceased === undefined || this.props.selectedCaseData.deceased.dateOfBirth === undefined || this.props.selectedCaseData.deceased.dateOfBirth === null) {
            this.dateOfBirth = "";
        } else {
            this.dateOfBirth = this.props.selectedCaseData.deceased.dateOfBirth;
        }
    };

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.selectedCaseData) !== JSON.stringify(this.props.selectedCaseData)) {
            const errorsInfo = ValidationErrors.info(this.props.selectedCaseData);
            if (ValidationErrors.isAvailable(errorsInfo)) {
                this.props.caseAction.updateValidationErrorsInfo(errorsInfo);
            } else {
                this.props.caseAction.updateValidationErrorsInfo({});
            }
        }
    };

    componentDidMount = () => {
        this.props.caseAction.getAllUserDetails();

        if ((this.props.counties.length === 0) && isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.county)) {
            const countyParentId = RelativeUtil.getCountyParentId();
            this.props.caseAction.getAllCounties(countyParentId);
        }
        if (this.props.selectedCaseData !== undefined) {
            console.log("this.props.selected case =", this.props.selectedCaseData);
            if (this.state.resultData.length === 0) {
                let temp = [];
                if (this.props.selectedCaseData.deceased !== undefined && (this.props.selectedCaseData.deceased.hospitalId !== null || this.props.selectedCaseData.deceased.hospitalName !== null)) {
                    temp.push({
                        id: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalId !== null ? this.props.selectedCaseData.deceased.hospitalId : "",
                        value: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalId !== null ? this.props.selectedCaseData.deceased.hospitalId.toString() : "",
                        name: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalName !== null ? this.props.selectedCaseData.deceased.hospitalName : "",
                        label: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalName !== null ? this.props.selectedCaseData.deceased.hospitalName : "",
                    });
                }
                this.setState({initialData: temp})

                let locationOfBodyPickupTemp = [];
                if (this.props.selectedCaseData.deceased !== undefined && (this.props.selectedCaseData.deceased.locationBodyPickupId !== null || this.props.selectedCaseData.deceased.locationBodyPickupName !== null)) {
                    locationOfBodyPickupTemp.push({
                        id: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.locationBodyPickupId !== null ? this.props.selectedCaseData.deceased.locationBodyPickupId : "",
                        value: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.locationBodyPickupId !== null ? this.props.selectedCaseData.deceased.locationBodyPickupId.toString() : "",
                        name: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.locationBodyPickupName !== null ? this.props.selectedCaseData.deceased.locationBodyPickupName : "",
                        label: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.locationBodyPickupName !== null ? this.props.selectedCaseData.deceased.locationBodyPickupName : "",
                    });
                }
                this.setState({locationOfBodyPickupInitialData: locationOfBodyPickupTemp})

                let bodyDestinationTemp = [];
                if (this.props.selectedCaseData.deceased !== undefined && (this.props.selectedCaseData.deceased.bodyDestinationId !== null || this.props.selectedCaseData.deceased.bodyDestinationName !== null)) {
                    bodyDestinationTemp.push({
                        id: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.bodyDestinationId !== null ? this.props.selectedCaseData.deceased.bodyDestinationId : "",
                        value: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.bodyDestinationId !== null ? this.props.selectedCaseData.deceased.bodyDestinationId.toString() : "",
                        name: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.bodyDestinationName !== null ? this.props.selectedCaseData.deceased.bodyDestinationName : "",
                        label: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.bodyDestinationName !== null ? this.props.selectedCaseData.deceased.bodyDestinationName : "",
                    });
                }
                this.setState({bodyDestinationInitialData: bodyDestinationTemp})
            }

            if (AppUtil.isAvailable(this.props.selectedCaseData.deceased) && AppUtil.isAvailable(this.props.selectedCaseData.deceased.personNumber)) {
                let checkDeceasedPersonNumber = ValidationUtil.isValidPersonNumber(this.props.selectedCaseData.deceased.personNumber);
                if (checkDeceasedPersonNumber === true) {
                    this.setState({disablePersonButton: false})
                }

                this.setState({personNumber: this.props.selectedCaseData.deceased.personNumber});
            }

            if (AppUtil.isAvailable(this.props.selectedCaseData.deceased)
                && this.props.selectedCaseData.deceased.address.address !== null && this.props.selectedCaseData.deceased.address.address !== ""
                && this.props.selectedCaseData.deceased.address.postalCode !== null && this.props.selectedCaseData.deceased.address.postalCode !== ""
                && this.props.selectedCaseData.deceased.address.city !== null && this.props.selectedCaseData.deceased.address.city !== ""
            ) {
                this.setState({disableParishButton: false})
            }
            this.setInitialUkProperties();
            this.getCaseTransportersApiCall();

            if (AppUtil.isAvailable(this.props.selectedCaseData.deceased.bodyPickupTransporterId) && AppUtil.isAvailable(this.props.selectedCaseData.deceased.bodyPickupTransporterName)) {
                this.state.bodyPickupTransporter = {
                    id: this.props.selectedCaseData.deceased.bodyPickupTransporterId,
                    name: this.props.selectedCaseData.deceased.bodyPickupTransporterName
                }
            }
        }
    };

    setInitialUkProperties() {
        if (this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.locationOfPassing !== null) {
            let tempLocation = AppUtil.transformArrayIntoCreatableSelectOptions(this.locationOfPassingOptions, this.props.selectedCaseData.deceased.locationOfPassing);
            this.setState({
                passingLocationValue: tempLocation
            });
        }

        if (this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.causeOfDeath !== null) {
            let tempCauseOfDeath = AppUtil.transformArrayIntoCreatableSelectOptions(this.causeOfDeathOptions, this.props.selectedCaseData.deceased.causeOfDeath)
            this.setState({
                causeOfDeath: tempCauseOfDeath
            });
        }

        if (this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.cremationPaperPaidBy !== null) {
            let tempCremationPaidOption = AppUtil.transformArrayIntoCreatableSelectOptions(this.cremationPaidByOptions, this.props.selectedCaseData.deceased.cremationPaperPaidBy)
            this.setState({
                cremationPaidByValue: tempCremationPaidOption
            });
        }

        if (this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.greenFormWith !== null) {
            let greenFormOption = AppUtil.transformArrayIntoCreatableSelectOptions(this.greenFormWithOptions, this.props.selectedCaseData.deceased.greenFormWith)
            this.setState({
                greenFormValue: greenFormOption
            });
        }

        if (this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.phoneOfPassing !== null) {
            this.setState({phoneOfPassing: this.props.selectedCaseData.deceased.phoneOfPassing});
        }
    }

    getCaseTransportersApiCall = () => {
        this.props.caseAction.getCaseTransporters().then((respData) => {
            this.setState({transporters: respData.object});
        }).catch(error => {
            console.log("Failure: get case transporters api Call = ", error);
        })
    }

    changeDeceasedProperty = (e) => {
        this.props.caseAction.changeDeceasedProperty(e.target.name, e.target.value);
    };

    onChangePersonNumber = (e) => {
        let value = e.target.value;
        if (e.target.value.length === 8 && !this.backOrDeleteKeyPress) {
            value = value + "-"
        } else {
            this.backOrDeleteKeyPress = false;
        }
        this.setState({personNumber: value});
    };

    changePersonNumber = (e) => {
        this.props.caseAction.changePersonNumberInfo(this.state.personNumber);
        let validatePersonNumber = ValidationUtil.isValidPersonNumber(this.state.personNumber);
        if (validatePersonNumber === true) {
            this.setState({disablePersonButton: false})
        } else {
            this.setState({disablePersonButton: true})
        }
    };

    checkKeys = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
            this.backOrDeleteKeyPress = true;
        }
    };

    changeAddress = (e) => {
        this.props.caseAction.changeAddressInfo(e.target.value);
    };

    changeAddress2 = (e) => {
        this.props.caseAction.changeAddress2Info(e.target.value);
    };

    changePostalCode = (e) => {
        this.props.caseAction.changePostalCodeInfo(e.target.value.toUpperCase());
    };

    changeCaseisMemberSwedishChurchStatus = (e) => {
        this.props.caseAction.onChangeCaseisMemberSwedishChurchStatus(e.target.checked);
    };

    changeDropdownOptionForDeceased = (e, fieldName) => {
        this.props.caseAction.onChangeCaseDeceasedDropdownOptionStatus(e, fieldName);
    };

    onChangeCity = (e) => {
        this.props.caseAction.onChangeCityInfo(e.target.value);
    };

    onChangeOtherDetails = (e) => {
        this.props.caseAction.onChangeOtherDetailInfo(e.target.value);
    };

    onChangeCountry = (e) => {
        this.props.caseAction.onChangeCountryInfo(e);
    };

    onChangeCounty = (e) => {
        this.props.caseAction.onChangeCountyInfo(e);
    };

    dateChange = (date) => {
        const dateStr = DateUtilExt.dateToUTCString(date);
        console.log(`[DebugTimeZone]:: Case deceased :: selected date in utc = ${dateStr}`);
        this.selectedDate = dateStr;
        this.props.caseAction.updateDateOfPassing(dateStr, 'date');
    };

    timeChange = (date) => {
        if (this.selectedDate !== '') {
            const dateStr = DateUtilExt.dateToUTCString(date);
            this.selectedDate = dateStr;
            this.props.caseAction.updateDateOfPassing(dateStr, 'time');
        }
    };

    dateOfBodyPickupDateChange = (date) => {
        const dateStr = DateUtilExt.dateToUTCString(date);
        console.log(`[DebugTimeZone]:: Case deceased :: selected DateOfBodyPickup in utc = ${dateStr}`);
        this.selectedDateOfBodyPickup = dateStr;
        this.props.caseAction.updateDateOfBodyPickup(dateStr, 'date');
    };

    dateOfBodyPickupTimeChange = (date) => {
        if (this.selectedDateOfBodyPickup !== '') {
            const dateStr = DateUtilExt.dateToUTCString(date);
            this.selectedDateOfBodyPickup = dateStr;
            this.props.caseAction.updateDateOfBodyPickup(dateStr, 'time');
        }
    };

    setTransporterPickup = (selectedOption) => {
        this.props.caseAction.setTransporterPickupInfo(selectedOption);
        this.setState({bodyPickupTransporter: selectedOption});
    };

    dateOfBirthChange = (date) => {
        const dateStr = DateUtilExt.dateToUTCString(date);
        console.log(`[DebugTimeZone]:: Case deceased :: selected date in utc = ${dateStr}`);
        this.dateOfBirth = dateStr;
        this.props.caseAction.assignSelectedDateOfBirth(dateStr);
    };

    loadLocations = (searchText, locationTypes) =>
        new Promise(resolve => {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    this.props.actionMaster.afterRefreshToken(newData)
                    setTimeout(() => {
                        resolve(this.loadLocationsData(newData.idToken.jwtToken, searchText, locationTypes));
                    }, 1000);
                })
            } else {
                setTimeout(() => {
                    resolve(this.loadLocationsData(getApiToken(), searchText, locationTypes));
                }, 1000);
            }
        });

    loadLocationsData = async (token, searchText, locationTypes) => {
        if (searchText) {
            await this.loadLocationsDataAPICall(token, searchText, locationTypes).then((data) => {
                let locationData = data.object.locations;
                for (let i = 0; i < locationData.length; i++) {
                    locationData[i].label = locationData[i].name;
                    locationData[i].value = locationData[i].id.toString();
                }
                this.setState({
                    resultData: locationData
                })
            });
            //Google Location commented out
            /* await searchApi.loadPlacesData(inputValue).then((locationData) => {
                let newData = CaseUtil.combineMapResult(locationData, this.state.resultData);
                 this.setState({
                     resultData: newData
                 })
             });*/
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadLocationsDataAPICall = (token, searchText, locationTypes) => {
        let url = `${ApiCollection.properties.simpleLocationSearchUrl}&f_tx=${searchText}${locationTypes.map(locationType => `&f_lid=${locationType}`).join('')}`;

        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    getDetailsByPersonNumber = (event) => {
        console.log("person Number", this.props.selectedCaseData.deceased.personNumber);
        event.preventDefault();
        this.props.caseAction.getDetailsByPersonNumber(this.props.selectedCaseData.deceased.personNumber);
        this.forceUpdate();
    };

    getParishNameOfDeceased = (event) => {
        event.preventDefault();
        this.props.caseAction.getParishNameOfDeceased(this.props.selectedCaseData.deceased);
    };

    handleItemSelectChange = (data) => {
        this.setState({
            initialData: data
        });
        // uncomment when google location is enabled
        /*if (data.latitude !== undefined && data.longitude !== undefined && data.id === undefined) {
            data.name = data.name + "__" + data.longitude + "__" + data.latitude;
        }*/
        this.props.caseAction.setLocationOfRemain(data);
    };

    handleLocationOfBodyPickupSelectChange = (data) => {
        this.setState({
            locationOfBodyPickupInitialData: data
        });
        this.props.caseAction.setLocationOfBodyPickup(data);
    };

    handleBodyDestinationSelectChange = (data) => {
        this.setState({
            bodyDestinationInitialData: data
        });
        this.props.caseAction.setBodyDestination(data);
    };

    handleParishSelectChange = (e) => {
        this.props.caseAction.setParishData(e.target.value);
    };

    sendEmailSummary = (relativeId, bodyData, emailTemplateType) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    setpaymentDetailsForCremationPapers = (e) => {
        this.props.caseAction.setpaymentDetailsForCremationPapers(e.target.value);
    }

    onChangeUpdateDeceasedProperty(property, e) {
        switch (property) {
            case DeceasedUtil.deceasedUKFields.DETAILS_OF_FURTHER_EXAMINATION:
            case DeceasedUtil.deceasedUKFields.LAST_OCCUPATION:
            case DeceasedUtil.deceasedUKFields.NOT_INFORMED_DETAILS:
            case DeceasedUtil.deceasedUKFields.OBJECTIONS_DETAILS:
            case DeceasedUtil.deceasedUKFields.ASHES_INSTRUCTIONS_FOR_CREM1:
                this.props.caseAction.updateDeceasedProperty(property, e.target.value.trim())
                break;
            case DeceasedUtil.deceasedUKFields.IS_LOCATION_OF_BODY_AS_RESIDENTIAL_ADDRESS:
                this.props.caseAction.updateDeceasedProperty(property, e.target.checked)
                break;
            default:
                break;
        }
    }

    handleDropdownOptions = (data, fieldName) => {
        if (AppUtil.isAvailable(data)) {
            if (data.label === data.value) {
                data.value = "0";
            }
        }
        switch (fieldName) {
            case UK_DECEASED_GREENFORM:
                this.setState({
                    greenFormValue: data
                });
                this.props.caseAction.setGreenFormOption(data);
                break;
            case UK_DECEASED_CAUSEOFDEATH:
                this.setState({
                    causeOfDeath: data
                });
                this.props.caseAction.setCauseOfDeath(data);
                break;
            case UK_DECEASED_LOCATION_OF_PASSING:
                this.setState({
                    passingLocationValue: data
                });
                this.props.caseAction.setLocationOfPasing(data);
                break;
            case UK_DECEASED_CREMATIONPAIDBY:
                this.setState({
                    cremationPaidByValue: data
                });
                this.props.caseAction.setCremationPaidBy(data);
                break;
            default:
                break;
        }
    }


    changeAddressOfPassing = (e) => {
        this.props.caseAction.changeAddressOfPassing(e.target.value);
    }

    changeAddress2OfPassing = (e) => {
        this.props.caseAction.changeAddress2OfPassing(e.target.value);
    }

    changePostalCodeOfPassing = (e) => {
        this.props.caseAction.changePostalCodeOfPassing(e.target.value.toUpperCase());
    }

    onChangeCityOfPassing = (e) => {
        this.props.caseAction.changeCityOfPassing(e.target.value);
    }

    onChangeCountryOfPassing = (e) => {
        this.props.caseAction.onChangeCountryOfPassing(e);
    };

    onChangeCountyOfPassing = (e) => {
        this.props.caseAction.onChangeCountyOfPassing(e);
    };

    onChangeOfPhoneOfPassing = (e) => {
        this.setState({
            phoneOfPassing: e.target.value
        });
        this.props.caseAction.onChangeOfPhoneOfPassing(e.target.value);
    }
    onValueChangeOfPhone = (e) => {
        this.setState({
            phoneOfPassing: e.target.value
        });
    }
    onInputItemChange = (inputValue, actionMeta) => {
        console.log("onInputItemChange", inputValue);
    };

    render = () => {
        const {selectedCaseData, countries} = this.props;
        return (
            <React.Fragment>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                               selectedCaseData={this.props.selectedCaseData}/>
                            <div className="section__content section__content--case_details"
                                 disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                                <div className="inner">
                                    <form>
                                        {
                                            AppUtil.isAvailable(selectedCaseData.deceased)
                                                ? <React.Fragment>
                                                    {this.getSection1Fields(selectedCaseData, countries)}
                                                    {this.getSection2Fields(selectedCaseData)}
                                                    {this.getSection3Fields(selectedCaseData, countries)}
                                                </React.Fragment>
                                                : null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div> : <DisplayNothing/>
                }
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={'case'}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                />
            </React.Fragment>
        )
    }

    getSection1Fields(selectedCaseData, countries) {
        return <div className="section__columns">
            <div className="section__column">
                <div className="form-group form-group--columns">
                    <div className={`form-group__column `}>
                        <Deceased.FirstName deceased={selectedCaseData.deceased}
                                            onBlur={(e) => this.changeDeceasedProperty(e)}/>
                    </div>
                    <div className={`form-group__column`}>
                        <Deceased.LastName deceased={selectedCaseData.deceased}
                                           onBlur={(e) => this.changeDeceasedProperty(e)}/>
                    </div>
                </div>
                {isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.addressOfPassing) ?
                    <h4 className="section__title">
                        {Utility.getLang().cases.deceasedTabContent.residentialAddress}
                    </h4>
                    : null}
                <div className={`form-group`}>
                    <Deceased.Address deceased={selectedCaseData.deceased}
                                      onBlur={(e) => this.changeAddress(e)}/>
                    <Deceased.Address2 deceased={selectedCaseData.deceased}
                                       onBlur={(e) => this.changeAddress2(e)}/>
                    {/*<div className="message is-warning">Only numbers 0-9 allowed</div>*/}
                </div>
                <div className="form-group form-group--columns">
                    <div className={`form-group__column`}>
                        <Deceased.PostalCode selectedCaseData={selectedCaseData}
                                             onBlur={(e) => this.changePostalCode(e)}/>
                    </div>
                    <div className={`form-group__column`}>
                        <Deceased.City selectedCaseData={selectedCaseData}
                                       onBlur={(e) => this.onChangeCity(e)}/>
                    </div>
                </div>
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.passingCountyId)
                        ? <div className={`form-group`}>
                            <Deceased.County selectedCaseData={selectedCaseData}
                                             counties={this.props.counties}
                                             onChange={(e) => this.onChangeCounty(e)}
                            />
                        </div>
                        : null
                }
                <div className={`form-group`}>
                    <Deceased.Country deceased={selectedCaseData.deceased}
                                      countries={countries}
                                      onChange={(e) => this.onChangeCountry(e)}
                    />
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.memberOfSwedishChurch) ?
                            <div className={`form-group`}>
                                <Deceased.MemberOfSwedishChurch checked={this.props.isMemberSwedishChurch}
                                                                onChange={this.changeCaseisMemberSwedishChurchStatus}/>
                            </div> : null
                    }
                </div>
                <div className={`form-group`}>
                    <Deceased.BodySize onChange={this.changeDropdownOptionForDeceased}
                                       selectedCaseData={this.props.selectedCaseData}/>
                </div>
                <div className={`form-group`}>
                    <Deceased.BodySizeNotes selectedCaseData={this.props.selectedCaseData}
                                            onChange={(e) => this.changeDeceasedProperty(e)}/>
                </div>
            </div>
            <div className="section__column">
                <div className={`form-group form-group--columns`}>
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.personNumber) ?
                            <div className={`form-group__column `}>
                                <Deceased.PersonNumber selectedCaseData={selectedCaseData}
                                                       onBlur={(e) => this.changePersonNumber(e)}
                                                       onKeyPress={(event) => allowNumberAndDash(event)}
                                                       onKeyDown={(e) => this.checkKeys(e)}
                                                       onChange={(e) => this.onChangePersonNumber(e)}
                                                       value={this.state.personNumber}
                                                       onClick={(e) => this.getDetailsByPersonNumber(e)}
                                                       disabled={this.state.disablePersonButton}/>
                            </div> : null
                    }
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.dateOfBirth) ?
                            <div className={`form-group__column cal-rem-btn`}>
                                <Deceased.DateOfBirth dateString={this.dateOfBirth} onChange={this.dateOfBirthChange}/>
                            </div> : null
                    }
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.sex) ?
                            <div className={`form-group__column`}>
                                <Deceased.Gender onChange={this.changeDropdownOptionForDeceased}
                                                 selectedCaseData={selectedCaseData}/>
                            </div> : null
                    }
                </div>
                <div className={`form-group form-group--columns`}>
                    {
                        <div className={`form-group__column cal-rem-btn`}>
                            <Deceased.DateOfPassing dateString={this.selectedDate} onChange={this.dateChange}/>
                        </div>
                    }
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.timeOfPassing) ?
                            <div className={`form-group__column cal-rem-btn`}>
                                <Deceased.TimeOfPassing dateString={this.selectedDate} onChange={this.timeChange}/>
                            </div> : null
                    }
                </div>
                <div className={`form-group`}>
                    <Deceased.LocationOfBody locations={this.loadLocations}
                                             locationTypes={[LocationTypes.hospital]}
                                             onChange={(option) => this.handleItemSelectChange(option)}
                                             value={this.state.initialData} onInputChange={this.handleInputChange}
                                             noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                             loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                             residentialCheckBoxValue={AppUtil.isAvailable(selectedCaseData) && AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased.isLocationOfBodyAsResidentialAddress : false}
                                             onResidentialCheckBoxChange={(e) => this.onChangeUpdateDeceasedProperty(DeceasedUtil.deceasedUKFields.IS_LOCATION_OF_BODY_AS_RESIDENTIAL_ADDRESS, e)}/>
                </div>
                {
                    /* The fields below should logically appear only for SE, and we know that parishName is only used for SE */
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.parishName) ?
                        <div>
                            <div className={`form-group`}>
                                <Deceased.LocationOfBodyPickup locations={this.loadLocations}
                                                               locationTypes={[LocationTypes.hospital]}
                                                               onChange={(option) => this.handleLocationOfBodyPickupSelectChange(option)}
                                                               value={this.state.locationOfBodyPickupInitialData}
                                                               onInputChange={this.handleInputChange}
                                                               noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                               loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}/>
                            </div>
                            <div className={`form-group`}>
                                <Deceased.LocationBodyPickupNotes selectedCaseData={this.props.selectedCaseData}
                                                        onChange={(e) => this.changeDeceasedProperty(e)}/>
                            </div>
                            <div className={`form-group form-group--columns`}>
                                {
                                    <div className={`form-group__column cal-rem-btn`}>
                                        <Deceased.DateOfBodyPickup dateString={this.selectedDateOfBodyPickup}
                                                                   onChange={this.dateOfBodyPickupDateChange}/>
                                    </div>
                                }
                                {
                                    <div className={`form-group__column cal-rem-btn`}>
                                        <Deceased.TimeOfBodyPickup dateString={this.selectedDateOfBodyPickup}
                                                                   onChange={this.dateOfBodyPickupTimeChange}/>
                                    </div>
                                }
                            </div>
                            <div className={`form-group`}>
                                <label htmlFor="caseDeceasedTransporterPickup">
                                    {Utility.getLang().cases.deceasedTabContent.transporterPickup}
                                </label>
                                <Select
                                    inputId="caseDeceasedTransporterPickup"
                                    isClearable
                                    value={this.state.bodyPickupTransporter}
                                    getOptionLabel={({name}) => name}
                                    getOptionValue={({id}) => id}
                                    placeholder={""}
                                    className={"multiselect"}
                                    classNamePrefix={"multiselect"}
                                    options={this.state.transporters}
                                    onChange={this.setTransporterPickup}
                                    noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                />
                            </div>
                            <div className={`form-group`}>
                                <Deceased.BodyDestination locations={this.loadLocations}
                                                          locationTypes={[LocationTypes.hospital, LocationTypes.coldRoom]}
                                                          onChange={(option) => this.handleBodyDestinationSelectChange(option)}
                                                          value={this.state.bodyDestinationInitialData}
                                                          onInputChange={this.handleInputChange}
                                                          noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                          loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}/>
                            </div>
                        </div>
                        : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.infectionStatus) ?
                        <div className={`form-group`}>
                            <Deceased.InfectionStatus onChange={this.changeDropdownOptionForDeceased}
                                                      selectedCaseData={selectedCaseData}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.parishName) ?
                        <div className={`form-group`}>
                            <Deceased.ParishName selectedCaseData={selectedCaseData}
                                                 onBlur={(e) => this.handleParishSelectChange(e)}
                                                 onClick={(e) => this.getParishNameOfDeceased(e)}
                                                 disabled={this.state.disableParishButton}/>
                        </div> : null
                }
                <div className="form-group form-group--columns">
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.referredToCoroner) ?
                            <div className={`form-group__column`}>
                                <Deceased.ReferredToCoroner onChange={this.changeDropdownOptionForDeceased}
                                                            selectedCaseData={selectedCaseData}/>
                            </div> : null
                    }
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.deathRegistered) ?
                            <div className={`form-group__column`}>
                                <Deceased.DeathRegistered onChange={this.changeDropdownOptionForDeceased}
                                                          selectedCaseData={selectedCaseData}/>
                            </div> : null
                    }
                </div>
                <div className="form-group form-group--columns">
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.greenFormWith) ?
                            <div className={`form-group__column`}>
                                <Deceased.GreenFormStatus
                                    onChange={e => this.handleDropdownOptions(e, UK_DECEASED_GREENFORM)}
                                    value={this.state.greenFormValue}
                                    formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                    options={this.greenFormWithOptions}
                                    onInputItemChange={this.onInputItemChange}/>
                            </div> : null
                    }
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.readyForCollection) ?
                            <div className={`form-group__column`}>
                                <Deceased.ReadyForCollection onChange={this.changeDropdownOptionForDeceased}
                                                             selectedCaseData={selectedCaseData}/>
                            </div> : null
                    }
                </div>
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.otherDetails) ?
                        <div className="form-group">
                            <Deceased.OtherDetails onChange={(e) => this.onChangeOtherDetails(e)}
                                                   selectedCaseData={selectedCaseData}/>
                        </div> : null
                }
            </div>
        </div>;
    }

    getSection2Fields(selectedCaseData) {
        return <div className="section__columns">
            <div className="section__column">
                {isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.releaseForm) ?
                    <h4 className="section__title">
                        {Utility.getLang().cases.deceasedTabContent.arrangementDetails}
                    </h4> : null}
                <div className="form-group form-group--columns">
                    {isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.releaseForm) ?
                        <div className={`form-group__column`}>
                            <Deceased.ReleaseForm onChange={(e) => this.changeDropdownOptionForDeceased(e)}
                                                  selectedCaseData={selectedCaseData}/>
                        </div> : null
                    }
                    {
                        <div className={`form-group__column`}>
                            <Deceased.Pacemaker
                                onChange={this.changeDropdownOptionForDeceased}
                                deceased={AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased : null}/>
                        </div>
                    }
                </div>
                <div className="form-group form-group--columns">
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.cremationPapers) ?
                            <div className={`form-group__column`}>
                                <Deceased.CremationPapers onChange={this.changeDropdownOptionForDeceased}
                                                          selectedCaseData={selectedCaseData}/>

                            </div> : null
                    }
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.cremationPaperPaidBy) ?
                            <div
                                className={`form-group__column`}>
                                <Deceased.CremationPaperPaidBy
                                    onChange={e => this.handleDropdownOptions(e, UK_DECEASED_CREMATIONPAIDBY)}
                                    value={this.state.cremationPaidByValue}
                                    formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                    options={this.cremationPaidByOptions}
                                    onInputItemChange={this.onInputItemChange}/>
                            </div> : null
                    }
                </div>
                <div className="form-group form-group--columns">
                    {
                        isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.paymentDetailsForCremationPapers) ?
                            <div className={`form-group__column`}>
                                <Deceased.PaymentDetailsForCremationPapers
                                    selectedCaseData={selectedCaseData}
                                    onBlur={(e) => this.setpaymentDetailsForCremationPapers(e)}/>
                            </div> : null
                    }
                </div>
            </div>
            <div className="section__column">
            </div>
        </div>;
    }

    getSection3Fields(selectedCaseData, countries) {
        return <div className="section__columns">
            <div className="section__column">
                {isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.maritalStatus) ?
                    <h4 className="section__title">
                        {Utility.getLang().cases.deceasedTabContent.cremation1Details}
                    </h4> : null}
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.maritalStatus) ?
                        <div className={`form-group__column`}>
                            <Deceased.MaritalStatus onChange={this.changeDropdownOptionForDeceased}
                                                    selectedCaseData={this.props.selectedCaseData}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.lastOccupation) ?
                        <div className={`form-group`}>
                            <Deceased.LastOccupation selectedCaseData={this.props.selectedCaseData}
                                                     onBlur={(e) => this.onChangeUpdateDeceasedProperty(DeceasedUtil.deceasedUKFields.LAST_OCCUPATION, e)}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.addressOfPassing) ?
                        <React.Fragment>
                            <h4 className="section__title">
                                {Utility.getLang().cases.deceasedTabContent.addressOfPassing}
                            </h4>
                            <div className={`form-group__column`}>

                                <Deceased.Address1OfPassing selectedCaseData={this.props.selectedCaseData}
                                                            onBlur={(e) => this.changeAddressOfPassing(e)}/>
                                <Deceased.Address2OfPassing selectedCaseData={this.props.selectedCaseData}
                                                            onBlur={(e) => this.changeAddress2OfPassing(e)}/>
                                {/*<div className="message is-warning">Only numbers 0-9 allowed</div>*/}
                            </div>
                            <div className="form-group form-group--columns">

                                <div className={`form-group__column`}>
                                    <Deceased.PostalCodeOfPassing selectedCaseData={this.props.selectedCaseData}
                                                                  onBlur={(e) => this.changePostalCodeOfPassing(e)}/>
                                </div>

                                <div className={`form-group__column`}>
                                    <Deceased.CityOfPassing selectedCaseData={this.props.selectedCaseData}
                                                            onBlur={(e) => this.onChangeCityOfPassing(e)}/>
                                </div>
                            </div>
                            {
                                isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.county) ?
                                    <div className={`form-group`}>
                                        <Deceased.CountyOfPassing selectedCaseData={this.props.selectedCaseData}
                                                                  counties={this.props.counties}
                                                                  onChange={(e) => this.onChangeCountyOfPassing(e)}
                                        />
                                    </div> : null
                            }
                            <div className={`form-group`}>
                                <Deceased.CountryOfPassing selectedCaseData={selectedCaseData}
                                                           countries={countries}
                                                           onChange={(e) => this.onChangeCountryOfPassing(e)}
                                />
                            </div>
                            <div className={`form-group`}>
                                <Deceased.PhoneOfPassing selectedCaseData={this.props.selectedCaseData}
                                                         onKeyPress={(event) => ValidationUtil.allowNumberPlusAndDash(event)}
                                                         onChange={(e) => this.onValueChangeOfPhone(e)}
                                                         onBlur={(e) => this.onChangeOfPhoneOfPassing(e)}
                                                         value={this.state.phoneOfPassing}/>
                            </div>

                        </React.Fragment>
                        : null
                }
            </div>
            <div className="section__column">
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.locationOfPassing) ?
                        <div className={`form-group_column`}>
                            <Deceased.LocationOfPassing
                                onChange={e => this.handleDropdownOptions(e, UK_DECEASED_LOCATION_OF_PASSING)}
                                value={this.state.passingLocationValue}
                                formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                options={this.locationOfPassingOptions}
                                onInputItemChange={this.onInputItemChange}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.furtherExaminationRequired) ?
                        <div className={`form-group`}>
                            <Deceased.FurtherExaminationRequired onChange={this.changeDropdownOptionForDeceased}
                                                                 selectedCaseData={this.props.selectedCaseData}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.detailsOfFurtherExamination) ?
                        <div className={`form-group`}>
                            <Deceased.DetailsOfFurtherExamination selectedCaseData={this.props.selectedCaseData}
                                                                  onBlur={(e) => this.onChangeUpdateDeceasedProperty(DeceasedUtil.deceasedUKFields.DETAILS_OF_FURTHER_EXAMINATION, e)}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.causeOfDeath) ?
                        <div className={`form-group__column`}>
                            <Deceased.CauseOfDeath
                                onChange={e => this.handleDropdownOptions(e, UK_DECEASED_CAUSEOFDEATH)}
                                value={this.state.causeOfDeath}
                                formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                options={this.causeOfDeathOptions}
                                onInputItemChange={this.onInputItemChange}/>
                        </div> : null
                }

                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.relativesAndExecutorsInformed) ?
                        <div className={`form-group`}>
                            <Deceased.RelativesAndExecutorsInformed
                                onChange={this.changeDropdownOptionForDeceased}
                                deceased={AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased : null}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.notInformedDetails) ?
                        <div className={`form-group`}>
                            <Deceased.NotInformedDetails
                                deceased={AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased : null}
                                onBlur={(e) => this.onChangeUpdateDeceasedProperty(DeceasedUtil.deceasedUKFields.NOT_INFORMED_DETAILS, e)}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.cremationObjections) ?
                        <div className={`form-group`}>
                            <Deceased.CremationObjections
                                onChange={this.changeDropdownOptionForDeceased}
                                deceased={AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased : null}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.objectionsDetails) ?
                        <div className={`form-group`}>
                            <Deceased.ObjectionsDetails
                                deceased={AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased : null}
                                onBlur={(e) => this.onChangeUpdateDeceasedProperty(DeceasedUtil.deceasedUKFields.OBJECTIONS_DETAILS, e)}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.ashesDisposalTypeForCrem1) ?
                        <div className={`form-group`}>
                            <Deceased.AshesDisposalTypeForCrem1
                                onChange={this.changeDropdownOptionForDeceased}
                                deceased={AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased : null}/>
                        </div> : null
                }
                {
                    isFieldEnabled(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.ashesInstructionsForCrem1) ?
                        <div className={`form-group`}>
                            <Deceased.AshesInstructionsForCrem1
                                deceased={AppUtil.isAvailable(selectedCaseData.deceased) ? selectedCaseData.deceased : null}
                                onBlur={(e) => this.onChangeUpdateDeceasedProperty(DeceasedUtil.deceasedUKFields.ASHES_INSTRUCTIONS_FOR_CREM1, e)}/>
                        </div> : null
                }

            </div>
        </div>;
    }
}

CaseDeceasedComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        open: state.application.open,

        openOnSend: state.application.openOnSend,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        allCityInfo: state.application.allCityInfo,
        isMemberSwedishChurch: state.application.isMemberSwedishChurch,
        paceMaker: state.application.paceMaker,
        remainLocation: state.application.remainLocation,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseUpdated: state.application.caseUpdated,
        tempNote: state.application.tempNote,
        openOnSaveCase: state.application.openOnSaveCase,

        countries: state.application.countries,
        counties: state.application.counties,

        wsLockCaseData: state.application.wsLockCaseData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseDeceasedComponent",
    enableReinitialize: true,
    validate: FormValidator({
        name: {presence: true},
    })
})(CaseDeceasedComponent));
